import { CancelToken } from 'axios';

class BaseService {
    constructor(instance) {
        this.instance = instance;
    }

    getCommonHeaders() {
        return this.instance.defaults.headers.common;
    }

    defaultCache = (withQueryParams, maxAge = 86400) => {
        const ret = {
            maxAge: maxAge * 1000
        };

        if (withQueryParams) {
            ret.exclude = { query: false };
        }

        return ret;
    };

    get = (url, headers, params, cache) => {
        let cancel;
        const promise = this.instance.get(url, {
            headers: Object.assign({}, this.getCommonHeaders(), headers),
            params: params,
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            }),
            cache: cache || { maxAge: 0 }
        });

        return [promise, cancel];
    };

    put = (url, data, headers, params) => {
        let cancel;
        const promise = this.instance.put(url, data, {
            headers: Object.assign({}, this.getCommonHeaders(), headers),
            params: params,
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        return [promise, cancel];
    };

    post = (url, data, headers, params, extraConfig) => {
        let cancel;
        const config = Object.assign({}, {
            headers: Object.assign({}, this.getCommonHeaders(), headers),
            params: params,
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        }, extraConfig);
        const promise = this.instance.post(url, data, config);

        return [promise, cancel];
    };

    delete = (url, headers, params) => {
        let cancel;
        const promise = this.instance.delete(url, {
            headers: Object.assign({}, this.getCommonHeaders(), headers),
            params: params,
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        return [promise, cancel];
    };
}

export default BaseService;
