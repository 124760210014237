import BackendService from '../backend-service/BackendService';
// Using conditional require statement because otherwise the `mocli dev` command will fail
// when generating the html.js because FingerprintJS uses window object which is not available during build
const FingerprintJS = typeof window !== 'undefined' ? require('@fingerprintjs/fingerprintjs') : null;
import Cookies from 'js-cookie';
import { COOKIE_OPSWAT_REDIRECT } from '@mdc/constants';
import { i18nT } from '@mdc/services';

class AuthHelper {
    /**
     * @return {string|null} CSRF token
     */
    getCsrfToken() {
        /* global METADEFENDER_TOKEN */
        if (typeof (METADEFENDER_TOKEN) !== 'undefined') {
            return METADEFENDER_TOKEN;
        }
        return null;
    }

    /**
     * @param {string} input input string
     * @return {string} base64 decoded string
     */
    b64DecodeUnicode(input) {
        return decodeURIComponent(Array.prototype.map.call(atob(input), (c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    /**
     * @return {object|null} sso object from window.SSO
     */
    checkSSO() {
        if (window.SSO) {
            const sso = JSON.parse(this.b64DecodeUnicode(window.SSO));
            return (sso) ? sso : null;
        }

        return null;
    }

    /**
     * @param {string} apikey input apikey
     * @return {Promise<string>} apikey from backend
     */
    async createApiKey(apikey) {
        if (this.getCsrfToken()) {
            try {
                const [addApiKeyPromise] = BackendService.addApiKey(apikey, {}, {});
                return (await addApiKeyPromise).data.key;
            }
            catch (error) {
                if (error.response?.status === 429) {
                    throw new Error(i18nT('Too many attempts received from your IP address, please try again after an hour'));
                }

                return; // suppres error for now because we are making two createaApikey requests
                // if ([400002, 400025].indexOf(error.code) !== -1) {
                //     throw new Error(i18nT('Failed to create your API key'));
                // }
            }

        } else {
            throw new Error(i18nT('Csrf-token not found'));
        }
    }

    /**
     * @return {Promise<string>} apikey generated by fingerprintjs
     */
    async getFingerPrint() {
        if (!FingerprintJS) {
            return;
        }

        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const apikey = result.visitorId;

        if (!apikey) {
            throw new Error(i18nT('Failed to generate a Fingerprint for you'));
        }

        return apikey;

    }

    /**
     * @return {Promise<Object>} apikey object from db
     */
    async checkApiKey(apikey, isLoggedIn) {
        try {
            const [getInfoPromise] = BackendService.getApikeyInfo({ apikey }, {});
            return (await getInfoPromise).data;
        } catch (error) {
            if (error?.response) {
                if ([401, 404].includes(error.response?.status) || error.response?.data?.code === 401000) {
                    if (!isLoggedIn) {
                        return this.createApiKey(await this.getFingerPrint());
                    }
                    throw new Error(i18nT('An error occured while creating your account. Please try again later.'));
                }
                if (error.response?.data?.code === 403004) {
                    return { ipRestriction: true };
                }

                throw new Error(i18nT('A server error occurred, and your request could not be completed. Please refresh the page'));
            }
            throw new Error(i18nT('Network error occurred, and your request could not be completed. Please refresh the page'));
        }
    }

    /**
     * @return {string} csrf-token
     */
    initCsrfToken() {
        const token = this.getCsrfToken();
        if (token) {
            BackendService.addCommonHeader({ 'X-Csrf': token });
            return token;
        }
        throw new Error(i18nT('Csrf-token not found'));
    }

    /**
     * @param {number} redirectTimeout ms to wait before redirect
     */
    async logout(redirectTimeout) {
        redirectTimeout = redirectTimeout || 0;

        setTimeout(() => {
            window.location.href = '/logout';
        }, redirectTimeout);
    }

    redirect(url) {
        Cookies.set(COOKIE_OPSWAT_REDIRECT, window.location.href, { secure: true });
        window.location.href = url;
    }
}

export const authHelper = new AuthHelper();
