import { useState, useEffect } from 'react';
import './ScrollTopButton.scss';
import { useTranslation } from 'react-i18next';

const ScrollTopButton = () => {
    const { t, ready } = useTranslation();
    let scrollLimit = 0;
    const [isVisible, setIsVisible] = useState(false);
    const [isClient, setIsClient] = useState(false);

    const checkScrollTop = () => {
        if (!isVisible && window.scrollY > scrollLimit){
            setIsVisible(true);
        } else if (isVisible && window.scrollY <= scrollLimit){
            setIsVisible(false);
        }
    };


    useEffect (( ) => {
        setIsClient(true);
        scrollLimit = screen.height + screen.height/2;
        window.addEventListener('scroll', checkScrollTop, { passive: true });

        return () => {
            window.removeEventListener('scroll', checkScrollTop, { passive: true });
        };

    }, [checkScrollTop]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const button = <button className='scroll' onClick={scrollToTop}>
        <span className='icon-up'></span>
        <span className="text">{t('TOP')}</span>
    </button>;

    if (!isClient || !isVisible || !ready) {
        return null;
    }

    return (
        <>
            { isVisible && button }
        </>
    );
};

export default ScrollTopButton;
