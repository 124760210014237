import PropTypes from 'prop-types';
import './Loading.scss';

const duration = '1.5s';
const ariaLabel = 'loading';

const antiFlashWhite = '#F3F3F5';
const stainedGlass = '#586687';

const getThemeColors = (useTheme) => {
    const white = '#FFFFFF';
    const direWolf = '#282828';

    switch (useTheme) {
        case 'freshest':
            return {
                strokeColor: antiFlashWhite,
                fillColor: stainedGlass
            };

        case 'city':
            return {
                strokeColor: direWolf,
                fillColor: white
            };
    }
};

const Loading = ({ useTheme, size }) => {
    const THEME_COLORS = useTheme && getThemeColors(useTheme) || {
        strokeColor: antiFlashWhite,
        fillColor: stainedGlass
    };

    const { strokeColor, fillColor } = THEME_COLORS;

    const linearGradientDefs = <defs>
        <linearGradient id="loadingSpinnerGradient" x1="120%" y1="-100%" x2="-20%" y2="70%">
            <stop offset="0%" stopColor={strokeColor} stopOpacity={0} />
            <stop offset="75%" stopColor={strokeColor} stopOpacity={0} />
            <stop offset="100%" stopColor={fillColor} stopOpacity={1} />
        </linearGradient>
    </defs>;

    const animation = <animateTransform
        attributeName="transform"
        dur={duration}
        type="rotate"
        from="0 50 48"
        to="360 50 52"
        repeatCount="indefinite"
    />;

    return (
        <svg
            version="1.1"
            width={size || '100px'}
            xmlns="http://www.w3.org/2000/svg"
            viewBox='0 0 100 100'
            x="0px"
            y="0px"
            aria-label={ariaLabel}
        >
            {linearGradientDefs}

            <circle
                fill="none"
                stroke={strokeColor}
                strokeWidth="5"
                cx="50"
                cy="50"
                r="44"
            />

            <circle
                fill="none"
                stroke="url(#loadingSpinnerGradient)"
                strokeWidth="5"
                strokeDashoffset="200"
                strokeDasharray="400"
                cx="50"
                cy="50"
                r="44"
            >
                { animation }
            </circle>

            <circle fill={fillColor} stroke={fillColor} strokeWidth="1" cx="6" cy="54" r="4">
                { animation }
            </circle>
        </svg>
    );
};

export default Loading;

Loading.propTypes = {
    useTheme: PropTypes.string,
    size: PropTypes.string,
};
