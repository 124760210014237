/* eslint-disable no-useless-escape */
import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './Component404.scss';

const Component404 = () => {
    const { t, ready } = useTranslation();
    const [text404, setText404] = useState();

    useEffect(() => {
        setText404(<span className='text404' dangerouslySetInnerHTML={{ __html: t('Please check the URL or head back to the homepage. If you can’t find what you are looking for, feel free to <a href=\"https://www.opswat.com/contact\"> get in contact </a> with us') }}/>);
    }, [t]);

    if (!ready) {
        return null;
    }

    return (
        <Container className='container404'>
            <Row className='firstRow'>
                <Col>
                    <i className='icon-404 icon404'/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{t('The page you are looking for doesn’t exist')}</h1>
                </Col>

            </Row>
            <Row>
                <Col>
                    {text404}
                </Col>
            </Row>
            <Row>
                <Col>
                    <a href='/' className='button404 btn btn-primary' type='button'>{t('BACK HOME')}</a>
                </Col>
            </Row>

        </Container>
    );
};

export default Component404;
