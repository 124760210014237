import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { getRandomID, Authenticator } from '@mdc/services';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

import './VoteButton.scss';

const VoteButton = ({ type, isSignedIn, handleVote, alreadyVoted, disabled, buttonText }) => {
    const [buttonTooltipId] = useState(`VoteButtonNotSignedIn${getRandomID(5)}`);
    const [voteInfoTooltipId] = useState(`VotingInfo${getRandomID(5)}`);
    const { t, ready } = useTranslation();

    const TooltipContent = useMemo(() => {
        return <span>
            {t('You must')}{' '}
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    Authenticator.authRedirect('https://id.opswat.com/register');
                }}>
                {t('Sign Up')}
            </a>{' '}
            {t('or')}{' '}
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    Authenticator.authRedirect('/login');
                }}>
                {t('Login')}
            </a>{' '}
            {t('to vote')}
        </span>;
    }, [t]);

    const buttonClassName = classNames({
        alreadyVoted: alreadyVoted && alreadyVoted === type,
        voteButtonDisabled: !isSignedIn,
        upVoteButton: isSignedIn && type === 'up',
        downVoteButton: isSignedIn && type === 'down'
    }, 'voteButton');

    const voteButton = useMemo(() => {
        const button = <i className={`icon icon-thumbs-${type}`} />;
        if (isSignedIn) {
            return (<>
                <Button variant='link' className={buttonClassName} onClick={handleVote} disabled={disabled}>
                    {button}
                    {buttonText}
                </Button>
            </>);
        }
        return (<>
            <Button variant='link' data-tip data-tooltip-id={buttonTooltipId} className={buttonClassName} onClick={handleVote} disabled={disabled}>
                {button}
                {buttonText}
            </Button>
        </>);

    }, [isSignedIn, disabled, alreadyVoted, type, t]);

    const VoteInfoTooltipContent = t('<strong>Clean</strong>: legitimate file with no malicious behavior. <br/> <strong>Malicious</strong>: dangerous file which might harm your computer, leak data or has unintended behavior. <hr/> The voting opinion is independent of scan results displayed');

    if (!ready) {
        return null;
    }

    return (
        <div className="VoteButtonWrapper">
            <div className='buttonsWrapper'>
                {voteButton}
                <Tooltip id={buttonTooltipId} className='buttonTooltip' variant='dark' delayHide={300} place={'top'} content={TooltipContent}>
                    {TooltipContent}
                </Tooltip>
                <Tooltip id={voteInfoTooltipId} className='voteInfoTooltip' variant='dark' delayHide={300} place={'bottom'}
                    content={<span dangerouslySetInnerHTML={{ __html: VoteInfoTooltipContent }}></span>}
                >
                </Tooltip>
            </div>
        </div>
    );
};

VoteButton.propTypes = {
    type: PropTypes.string.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    handleVote: PropTypes.func.isRequired,
    alreadyVoted: PropTypes.string,
    disabled: PropTypes.bool,
    buttonText: PropTypes.string
};

export default VoteButton;
