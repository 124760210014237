import { useState, useMemo, isValidElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './FaqV2.scss';

const FaqV2 = ({ faqs, defaultActiveKey, faqTitle, faqSubTitle }) => {
    const { t, ready } = useTranslation();

    const [activeKey, setActiveKey] = useState(-1);
    const accordionExpandSymbols = {
        expand: 'icon-down-dir plusIcon',
        narrow: 'icon-up-dir minusIcon'
    };

    const handleSelect = (key) => {
        setActiveKey(parseInt(key));
    };

    const faqTitleComp = faqTitle ? <h2>{faqTitle}</h2> : null;

    const [faqsIndexing, setFaqsIndexing] = useState([]); useEffect(() => setFaqsIndexing(faqs.map((faq, index) => ({ ...faq, index }))), [faqs]);

    const colProcess = (faQuestions, key, defaultKey) => faQuestions.map((faq) => {
        const answer = isValidElement(faq.answer) ? <span>{faq.answer}</span> : <span dangerouslySetInnerHTML={{ __html: faq.answer }}></span>;
        return <div key={faq.index} className="accordionWrapper">
            <Row>
                <Col>
                    <Accordion.Toggle
                        eventKey={faq.index.toString()}
                        className="d-flex justify-content-between"
                    >
                        <span>{faq.question}</span>
                        <span className="expand">
                            {key === faq.index || (defaultKey === faq.index && key === -1)
                                ? <i className={accordionExpandSymbols.narrow}/>
                                : <i className={accordionExpandSymbols.expand}/>}
                        </span>
                    </Accordion.Toggle>
                </Col>
            </Row>
            <Accordion.Collapse eventKey={faq.index.toString()} className="row">
                <Row>
                    <Col>
                        {answer}
                    </Col>
                </Row>
            </Accordion.Collapse>
        </div>;
    });

    const faqsCol = useMemo(() => colProcess(faqsIndexing, activeKey, defaultActiveKey),
        [faqsIndexing, activeKey, defaultActiveKey]);

    const [faqFooter, setFaqFooter] = useState();
    useEffect(() => setFaqFooter(<>
        <p>{t('Still have questions?')}</p>
        <a
            className="btn btn-primary"
            href={t('https://www.opswat.com/contact')}
            rel="noopener noreferrer"
            target="_blank"
        >
            {t('Contact Sales')}
        </a>
    </>), [t]);

    if (!ready) {
        return null;
    }

    return (
        <section className="faqV2">
            <Row className='text-center'>
                <Col>
                    {faqTitleComp}
                    <p className='subHeading'>{faqSubTitle}</p>
                </Col>
            </Row>
            <Accordion defaultActiveKey={defaultActiveKey} onSelect={handleSelect}>
                <Row>
                    <Col xs={12} sm={12} md={7} className='ml-auto mr-auto accordionColumn'>
                        {faqsCol}
                    </Col>
                </Row>
            </Accordion>
            <Row className="faqFooter">
                <Col>
                    {faqFooter}
                </Col>
            </Row>
        </section>
    );
};

FaqV2.propTypes = {
    faqs: PropTypes.array,
    defaultActiveKey: PropTypes.number,
    faqTitle: PropTypes.string,
    faqSubTitle: PropTypes.string
};

FaqV2.defaultProps = {
    faqs: [],
    defaultActiveKey: null,
    faqSubTitle: ''
};

export default FaqV2;
