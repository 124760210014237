import { useMemo } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaqV2 } from '@mdc/ui';
import { useTranslation } from 'react-i18next';

import Plan from './Plan';

import './Licensing.scss';

const Licensing = ({ plans, faqs, domEnginesList, planButtons, licensingIcon }) => {
    const { t, ready } = useTranslation();

    const domPlans = useMemo(() => {
        return plans.map((plan, index) => (
            <Col key={plan} className="plan" lg={index === 0 ? 4 : null}>
                <Plan {...plan} planButtons={planButtons} />
            </Col>
        ));
    }, [plans, planButtons]);

    const domPreventionApi = (
        <Col className="apiDescription">
            <h2>{t('MetaDefender Prevention API')}</h2>
            <p>{t('The MetaDefender Cloud Prevention API enables flexibility in processing and use of different technologies including:')}</p>
            <ul>
                <li>{t('Scanning a file by file upload')}</li>
                <li>{t('Data sanitization (Deep CDR) requests')}</li>
                <li>{t('Unarchiving and scanning individual files')}</li>
            </ul>
        </Col>
    );

    const domAnalysisApi = (
        <Col className="apiDescription">
            <h2>{t('Metadefender Cloud Sandbox API')}</h2>
            <p>{t('The Metadefender Cloud Sandbox API includes access to our Sandbox dynamic analysis technology:')}</p>
            <ul>
                <li>{t('Execute files on multiple operating systems')}</li>
                <li>{t('Automated interpretation of the behavior')}</li>
                <li>{t('Configurable analysis settings')}</li>
            </ul>
        </Col>
    );

    const domReputationApi = (
        <Col className="apiDescription">
            <h2>{t('Metadefender Reputation API')}</h2>
            <p>{t('The MetaDefender Cloud Reputation API includes multiple methods for information recall (single and bulk) including:')}</p>
            <ul>
                <li>{t('Retrieving scan reports using a file hash')}</li>
                <li>{t('Scanning IP addresses, URLs and domains')}</li>
                <li>{t('File metadata lookup')}</li>
            </ul>
        </Col>
    );

    const domFeedApi = (
        <Col className="apiDescription">
            <h2>{t('Metadefender Threat Intelligence API')}</h2>
            <p>{t('The MetaDefender Cloud Threat Intelligence API grants instant access to hashes:')}</p>
            <ul>
                <li>{t('Infected and no threat found files')}</li>
                <li>{t('Updated immediately after scanning')}</li>
                <li>{t('Flexible access to historical data')}</li>
                <li>{t('Multiscanning results for each hash')}</li>
                <li>{t('Threat name and file extension')}</li>
            </ul>
        </Col>
    );

    if (!ready) {
        return null;
    }

    return (
        <Container className="licensing">
            <section>
                <Row className='d-flex align-items-center introLicensing'>
                    <Col sm={{ span: 9, order: 0 }} xs={{ span: 12, order: 1 }} className="description pl-0 ml-0">
                        <h1>{t('Product Licensing')}</h1>
                        <p>
                            {t('MetaDefender Cloud comes in three different flavors: Standard, Professional, and Enterprise. The Standard tier is geared towards personal users and very small startups that do not have a high volume of files to scan. The Professional tier offers a general-purpose license tailored for most small to mid-sized organizations. The Enterprise tier is designed for large organizations in search of a robust cybersecurity solution and the highest scan time and availability SLAs')}
                        </p>
                    </Col>

                    <Col sm={{ span: 3, order: 1 }} xs={{ span: 12, order: 0 }} className='text-right'>
                        <img src={licensingIcon} className='licensingIcon' alt='MetaDefender Licensing' title='MetaDefender Licensing' />
                    </Col>
                </Row>
                <Row className="planWrapper">
                    <div className="plans">{domPlans}</div>
                </Row>
            </section>

            <section className="aboutAPIs">
                <Row>
                    <Col xs={12} md={{ span: 8, offset: 2 }} className='text-center'>
                        <h2>{t('Understanding our APIs')}</h2>
                        <p className='subHeading'>
                            {t('Licensing comes in different tiers dedicated to precise needs. The tiers come with flexible limits and can be combined to offer flexibility and competitive prices')}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {domPreventionApi}
                    {domAnalysisApi}
                    {domReputationApi}
                    {domFeedApi}
                </Row>
            </section>

            <section className="antimalwareEngines">
                <Row>
                    <Col xs={12} md={{ span: 8, offset: 2 }} className='text-center'>
                        <h2>{t('Antimalware Engines')}</h2>
                        <p className='subHeading'>
                            {t('We enable a wide range of Antimalware Engines for commercial use')}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="englinesListWrapper">
                        {domEnginesList}
                    </Col>
                </Row>
            </section>

            <FaqV2 faqs={faqs(t)} faqTitle={t('Questions about our licensing?')} faqSubTitle={t('Here are the most frequently asked questions')} />
        </Container>
    );
};

Licensing.propTypes = {
    plans: PropTypes.array,
    faqs: PropTypes.func,
    domEnginesList: PropTypes.any,
    planButtons: PropTypes.object,
    licensingIcon: PropTypes.string
};

export default Licensing;
