import { useState, useMemo, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Accordion } from 'react-bootstrap';
import classNames from 'classnames';

import './Faq.scss';

const Faq = ({ faqs, defaultActiveKey, faqFooter, faqTitle, keepPaddingAndMargins }) => {
    const noPaddingClasses = classNames({
        'pl-0 ml-0': !keepPaddingAndMargins
    });
    const [activeKey, setActiveKey] = useState(-1);
    const accordionExpandSymbols = {
        expand: 'icon-plus plusIcon',
        narrow: 'icon-minus minusIcon'
    };

    const handleSelect = (key) => {
        setActiveKey(parseInt(key));
    };

    const faqTitleComp = faqTitle ? <h2>{faqTitle}</h2> : null;

    const domFaqs = useMemo(() => {
        return faqs.map((faq, index) => {
            const answer = isValidElement(faq.answer) ? <span>{faq.answer}</span> : <span dangerouslySetInnerHTML={{ __html: faq.answer }}></span>;
            return <div key={faq?.id} className="accordionWrapper">
                <Row>
                    <Accordion.Toggle
                        eventKey={index.toString()}
                        className="d-flex justify-content-between"
                    >
                        <span>{faq.question}</span>
                        <span className="expand">
                            {activeKey === index || (defaultActiveKey === index && activeKey === -1)
                                ? <i className={accordionExpandSymbols.narrow} />
                                : <i className={accordionExpandSymbols.expand} />}
                        </span>
                    </Accordion.Toggle>
                </Row>
                <Accordion.Collapse eventKey={index.toString()} className="row">
                    <Row>
                        <Col lg={9}>
                            {answer}
                        </Col>
                    </Row>
                </Accordion.Collapse>
            </div>;
        });
    }, [faqs, activeKey, defaultActiveKey]);

    return (
        <section className="faq">
            <Row>
                <Col className={noPaddingClasses}>
                    {faqTitleComp}
                </Col>
            </Row>
            <Accordion defaultActiveKey={defaultActiveKey} onSelect={handleSelect}>
                {domFaqs}
            </Accordion>
            <Row className="faqFooter">
                <Col>
                    {faqFooter}
                </Col>
            </Row>
        </section>
    );
};

Faq.propTypes = {
    faqs: PropTypes.array,
    defaultActiveKey: PropTypes.number,
    faqFooter: PropTypes.node,
    faqTitle: PropTypes.string,
    keepPaddingAndMargins: PropTypes.bool
};

Faq.defaultProps = {
    faqs: [],
    defaultActiveKey: null,
    faqFooter: <></>,
    keepPaddingAndMargins: false
};

export default Faq;
