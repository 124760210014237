import Backend from 'i18next-xhr-backend';
import i18n from 'i18next';
import { i18nConfig } from '@mdc/constants';
import lngDetector, { replaceHistory, getLangPath } from '../language-detector/LanguageDetectorService';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(lngDetector);

i18n.use(Backend)
    .use(initReactI18next)
    .use(languageDetector)
    .init(i18nConfig);

function getLocalisedOpswatLink(link) {
    const lang = i18nUtility.lookupLang();
    if (lang === 'ja-JP') {
        return link.replace('opswat.com', 'opswat.jp');
    }
    if (lang === 'en-US') {
        return link.replace('opswat.jp', 'opswat.com');
    }
    return link;
}

function i18nT(key) {
    return i18n.getFixedT(i18nUtility.lookupLang())(key);
}

const pathList = ['', '/jp', '/en'];
const lookupLang = () => lngDetector.lookup(i18nConfig.detection);

const i18nUtility = {
    pathList,
    lookupLang,
    getLangPath() {
        return getLangPath(lookupLang());
    },
    replaceHistory() {
        replaceHistory(lookupLang());
    },
    rebuildUrlWithLangPrefix(internalUrl) {
        let url = (internalUrl.startsWith('/') ? '' : '/') + internalUrl;
        const pathListFiltered = pathList.filter((path) => !!path);

        if (pathListFiltered.some((path) => url.startsWith(path))) {
            url = url.slice(pathListFiltered.find((path) => url.startsWith(path)).length);
        }

        const langPath = getLangPath(lookupLang());
        return langPath + url;
    }
};

export {
    i18n,
    i18nT,
    i18nUtility,
    getLocalisedOpswatLink
};
