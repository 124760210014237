import BaseService from '../BaseService';

class StatusService {
    constructor(instance) {
        this.instance = instance;
        this.base = new BaseService(this.instance);
    }

    getPackages = (headers) => {
        return this.base.get('/status/packages', headers);
    }

    getEngineList = (headers) => {
        return this.base.get('/status/enginelist', headers);
    }
}

export default StatusService;
