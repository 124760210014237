export default {
    whitelist: ['en-US', 'ja-JP', 'en', 'ja', 'jp'],
    fallbackLng: 'en-US',
    load: 'currentOnly',
    cleanCode: true,
    saveMissing: true,
    nsSeparator: false,
    keySeparator: false,
    debug: false,
    interpolation: {
        escapeValue: false,
    },
    react: {
        wait: true,
        useSuspense: false
    },
    backend: {
        loadPath: '/locale/{{lng}}/{{ns}}.json',
        addPath: '/locale/{{lng}}/{{ns}}.json'
    },
    detection: {
        order: ['lngDetector'],
        lookupQuerystring: 'lang',
        lookupLocalStorage: 'locale',
        caches: ['lngDetector'],
        checkWhitelist: true
    }
};
