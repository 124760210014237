class UtilsFunctions {
    getTimeFormat(resolutionHours, durationHours) {
        if (resolutionHours <= 1) {
            // Hourly resolution
            if (durationHours <= 24) {
                // duration less then 24 hours
                return 'HH:mm';
            }
            return 'MMM DD, HH:mm';

        } else if (resolutionHours <= 28 * 24) {
            // Daily resolution
            if (durationHours <= 365 * 24) {
                // duration less then 1 year
                return 'MMM DD';
            }
            return 'MMM DD, YYYY';

        }
        // Monthly resolution
        if (durationHours <= 365 * 24) {
            // duration less then 1 year
            return 'MMM';
        }
        return 'MMM YYYY';
    }


    sortScanHistoryDates(firstDate, secondDate) {
        if (firstDate.start_time && !secondDate.start_time) {
            return 1;
        } else if (!firstDate.start_time && secondDate.start_time) {
            return -1;
        } else if (!firstDate.start_time && !secondDate.start_time) {
            return 0;
        }

        return firstDate.start_time.localeCompare(secondDate.start_time);
    }

    sortHelper(first, second) {
        if (first > second) {
            return 1;
        } else if (first < second) {
            return -1;
        }
        return 0;
    }

    getRandomInt = (min, max) => {
        if (typeof window !== 'undefined' && window.crypto) {
            const randArray = new Uint8Array(1);
            window && window?.crypto.getRandomValues(randArray);
            const randByte = randArray[0];
            return Math.floor((randByte / 256) * (max - min + 1) + min);
        } else {
            return Math.floor(Math.random() * (max - min + 1) + min);
        }
    };

    randomInt = (maxValue) => {
        if (typeof window !== 'undefined' && window.crypto) {
            const randomBuffer = new Uint32Array(1);
            window && window?.crypto.getRandomValues(randomBuffer);
            const randomNumber = randomBuffer[0] / (0xFFFFFFFF + 1);
            return Math.floor(randomNumber * maxValue);
        } else {
            return Math.floor(Math.random() * maxValue);
        }
    };

}

export default new UtilsFunctions();
