// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-conditional-page-conditional-page-js": () => import("./../../../src/components/conditionalPage/ConditionalPage.js" /* webpackChunkName: "component---src-components-conditional-page-conditional-page-js" */),
  "component---src-templates-404-template-404-js": () => import("./../../../src/templates/404/Template404.js" /* webpackChunkName: "component---src-templates-404-template-404-js" */),
  "component---src-templates-account-account-page-js": () => import("./../../../src/templates/account/AccountPage.js" /* webpackChunkName: "component---src-templates-account-account-page-js" */),
  "component---src-templates-appstore-app-store-template-js": () => import("./../../../src/templates/appstore/AppStoreTemplate.js" /* webpackChunkName: "component---src-templates-appstore-app-store-template-js" */),
  "component---src-templates-extension-extension-js": () => import("./../../../src/templates/extension/Extension.js" /* webpackChunkName: "component---src-templates-extension-extension-js" */),
  "component---src-templates-leaderboard-leaderboard-js": () => import("./../../../src/templates/leaderboard/Leaderboard.js" /* webpackChunkName: "component---src-templates-leaderboard-leaderboard-js" */),
  "component---src-templates-licensing-licensing-js": () => import("./../../../src/templates/licensing/Licensing.js" /* webpackChunkName: "component---src-templates-licensing-licensing-js" */),
  "component---src-templates-results-results-js": () => import("./../../../src/templates/results/Results.js" /* webpackChunkName: "component---src-templates-results-results-js" */),
  "component---src-templates-store-store-template-js": () => import("./../../../src/templates/store/StoreTemplate.js" /* webpackChunkName: "component---src-templates-store-store-template-js" */),
  "component---src-templates-vulnerabilities-vulnerabilities-js": () => import("./../../../src/templates/vulnerabilities/Vulnerabilities.js" /* webpackChunkName: "component---src-templates-vulnerabilities-vulnerabilities-js" */)
}

