import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SocialShare from './social-share/SocialShare';
import Copyright from './copyright';

import './Footer.scss';

const Footer = ({ navigationItems }) => {
    const { ready } = useTranslation();

    if (!ready) {
        return null;
    }

    return (
        <footer>
            <Container>
                <hr />
                <Row className='groups'>
                    <Col
                        className='branding'
                        lg={{ span: 6, order: 'first' }}
                        md={9}
                        sm={9}
                        xs={{ span: 10, order: 'last' }}
                    >
                        <SocialShare />
                        <Copyright />
                    </Col>

                    {navigationItems}
                </Row>
            </Container>
        </footer>
    );
};

Footer.propTypes = {
    navigationItems: PropTypes.element.isRequired
};

export default Footer;
