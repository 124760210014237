import { SANITIZATION_FILE_TYPES, DLP_FILE_TYPES } from '@mdc/constants';
import Md5FileService from 'browser-md5-file';

const DEFAULT_MAX_FILE_SIZE = +'750' * 1048576; //transform to bytes

class FileInfoService {
    constructor(file, maxFileSizeUpload) {
        this.file = file;
        this.name = file.name;
        this.extension = this._getExtension();
        this.sizeLimit = (maxFileSizeUpload * 1048576) || DEFAULT_MAX_FILE_SIZE;
    }

    isValid() {
        const sizeLimit = this.sizeLimit;
        const fileSize = this.file?.size;

        return fileSize < sizeLimit;
    }

    isEmpty() {
        return this.file.size === 0;
    }

    _getExtension() {
        const fileName = this.name;

        const fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1);

        return fileExtension && fileExtension.toLowerCase();
    }

    canBeSanitized() {
        const extension = this.extension;

        if (!extension) {
            return false;
        }

        return SANITIZATION_FILE_TYPES.indexOf(extension) > -1;
    }

    isDLP() {
        const extension = this.extension;

        if (!extension) {
            return false;
        }

        return DLP_FILE_TYPES.indexOf(extension) > -1;
    }

    _getMd5Async() {
        const file = this.file;
        const md5FileService = new Md5FileService();
        return new Promise((resolve, reject) => {
            md5FileService.md5(file, (err, md5) => {
                if (err) {
                    return reject(err);
                }

                resolve(md5);
            });
        });
    }

    async getMd5() {
        return this._getMd5Async();
    }
}

export default FileInfoService;
