export default [
    'bmp',
    'doc',
    'docm',
    'docx',
    'dot',
    'dotm',
    'dotx',
    'dwg',
    'gif',
    'htm',
    'html',
    'hwp',
    'jpg',
    'jpeg',
    'jtd',
    'pdf',
    'png',
    'ppsx',
    'ppt',
    'pptm',
    'pptx',
    'rtf',
    'tif',
    'tiff',
    'xls',
    'xlsb',
    'xlsm',
    'xlsx',
    'xml',
    'zip'
];
