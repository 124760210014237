import { localStorage, dayjs, QueryParams, objectToURLParamsStr } from '@mdc/services';
import { i18nConfig } from '@mdc/constants';

const addCountryCodeToLanguage = (lng) => {
    switch (lng) {
        case 'en':
            return `${lng}-US`;
        case 'ja':
            return `${lng}-JP`;
        default:
            return lng;
    }
};

export const getLangPath = (lng) => {
    return lng?.toLowerCase()?.includes('j') ? '/jp' : '';
};

export const replaceHistory = (lng) => {
    dayjs.locale(lng.split('-')[0]);

    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window !== 'undefined' && window.location.hash) {
        return;
    }

    const pathLang = getLangPath(lng).replace('/', '');

    // Delete ?lang query if any
    let query = QueryParams();
    if (query[i18nConfig.detection.lookupQuerystring]) {
        delete query[i18nConfig.detection.lookupQuerystring];
    }

    query = objectToURLParamsStr(query);
    query = (query ? '?' : '') + query;

    let path = window.location.pathname.split('/');
    path.shift();

    let replaceUrl;
    if (pathLang && path[0] !== pathLang) {
        path = [pathLang].concat(path);
    } else if (!pathLang && (path[0] === 'jp' || path[0] === 'en')) {
        path.shift();
    }

    replaceUrl = (path.join('/') || '/') + query;
    replaceUrl = (replaceUrl.startsWith('/') ? '' : '/') + replaceUrl;

    if (window.location.href !== (window.location.origin + replaceUrl)) {
        window.history.replaceState(window.history.state, '', replaceUrl);
    }
};

export default {
    name: 'lngDetector',

    lookup(options) {
        if (typeof window !== 'undefined') {
            // Check Language from path name
            const path = window.location.pathname.split('/');
            path.shift();
            if (path.length && path[0] === 'jp') {
                return 'ja-JP';
            } else if (path.length && path[0] === 'en') {
                return 'en-US';
            }

            // Check Language from query
            let langFound;
            let query = QueryParams();
            langFound = query[options.lookupQuerystring];
            if (langFound === 'jp' || langFound === 'ja') {
                return 'ja-JP';
            } else if (langFound === 'en' || langFound === 'us') {
                return 'en-US';
            }

            // Check Language from LocalStorage
            const storedLang = localStorage.getItem(options.lookupLocalStorage);
            if (storedLang === 'ja-JP') {
                return 'ja-JP';
            }
        }

        return 'en-US';
    },

    cacheUserLanguage(lng, options) {
        localStorage.setItem(options.lookupLocalStorage, addCountryCodeToLanguage(lng));
        replaceHistory(lng);
    }
};
