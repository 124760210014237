/* eslint-disable no-useless-escape */
export default (t) => ({
    resultStatus: {
        // eslint-disable-next-line camelcase
        unknown_threat: t('Unknown Threat'),
        i2: t('Suspicious'),
        i3: t('Failed to scan'),
        i4: t('Cleaned'),
        i5: t('Unknown'),
        i6: t('Quarantined'),
        i7: t('Skipped Clean'),
        i8: t('Skipped Dirty'),
        i9: t('Exceeded Archive Depth'),
        i10: t('Not Scanned / No scan results'),
        i11: t('Aborted'),
        i12: t('Encrypted Archive'),
        i13: t('Exceeded Archive Size'),
        i14: t('Exceeded Archive File Number'),
        i15: t('Password Protected Document'),
        i16: t('Exceeded Archive Timeout'),
        i17: t('Mismatch'),
        i18: t('Potentially Vulnerable File'),
        i19: t('Canceled'),
        i20: t('Sensitive Data Found'),
        i21: t('Yara Rule Matched'),
        i22: t('Potentially unwanted program'),
        i23: t('Filetype not supported')

    },
    scanAlertInfo: {
        i2: t('The file was classified as having a possible threat but it wasn\'t identified as a specific threat'),
        i4: t('A threat was found and the file is cleaned'),
        i5: t('Unknown signature'),
        i6: t('The file you uploaded is quarantined'),
        i7: t('The scan process is skipped because this file type is allow-listed'),
        i8: t('The scan process is skipped because this file type is denylisted'),
        i9: t('Exceeded maximum archive depth. Please upload archives that have up to five nestings'),
        i10: t('Scan is skipped by the engine either due to update or other engine specific reason. If the scan is disabled, this will be the final result'),
        i11: t('The scan was aborted by the server'),
        i12: t('The file seems to be encrypted. If this is an encrypted archive, please check <a href=\"https://docs.opswat.com/mdcloud/metadefender-cloud-api-v4/ref#file-upload\" target=\"_blank\">Scanning a file by file upload</a>, from our <a href=\"https://docs.opswat.com/mdcloud\" target=\"_blank\">Online Help</a> documentation'),
        i13: t('The extracted archive is too large to scan'),
        i14: t('The scan was not completed because the number of files in the archive exceeds the supported limit'),
        i15: t('This is a password protected document. In order to scan it, please check <a href=\"https://docs.opswat.com/mdcloud/metadefender-cloud-api-v4/ref#file-upload\" target=\"_blank\">Scanning a file by file upload</a>, from our <a href=\"https://docs.opswat.com/mdcloud\" target=\"_blank\">Online Help</a> documentation'),
        i16: t('The archive process reached the given timeout value'),
        i17: t('The file\'s extension does not match the detected file type. Only applicable when using workflows'),
        i18: t('Possible vulnerability detected for the applied file because the extension is not supported by one or more engines'),
        i19: t('The file scan was canceled because it failed to scan too many times'),
        i20: t('Data loss prevention engine found sensitive data'),
        i21: t('The analysis data matches a yara rule'),
        i22: t('Returned by engines with PUA detection capabilities'),
        i23: t('The engine does not support scanning this file type. Certain engines only scan specific file types such as executable files or documents'),

    }
});
