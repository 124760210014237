import BaseService from '../BaseService';

class FileService {
    constructor(instance) {
        this.instance = instance;
        this.base = new BaseService(this.instance);
    }

    upload = (data, headers, onProgressCallback) => {
        const config = {
            onUploadProgress: onProgressCallback,
            timeout: 0,
        };

        const extraHeaders = Object.keys(headers).includes('hash-lookup') ? { 'skip_partial_results': 1 } : {};

        return this.base.post('/file', data, Object.assign({}, extraHeaders, headers), null, config);
    }

    getResults = (params, headers) => {
        return this.base.get(`/file/${params.dataId}`, Object.assign({}, { 'x-file-metadata': 1 }, headers));
    }

    getAdditional = (params, headers) => {
        return this.base.get(`/file/${params.dataId}/${params.info}`, headers);
    }

    getRescan = (params, headers) => {
        return this.base.get(`/file/${params.fileId}/rescan`, headers);
    }
}

export default FileService;
