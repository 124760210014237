import { useEffect, useRef, useState, useMemo } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import Checkbox from '../checkbox/Checkbox';

import './FeedbackPopup.scss';

const FeedbackPopup = ({ sendFeedBackResponse, triggerRef }) => {
    const [feedBackResponse, setFeedBackResponse] = useState(null);
    const [selectedScore, setSelectedScore] = useState(null);
    const [submitDisable, setSubmitDisable] = useState(true);
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [textInput, setTextInput] = useState('');
    const [optOut, setOptOut] = useState(false);
    const [show, setShow] = useState(false);
    const wrapperRef = useRef(null);

    const { t, ready } = useTranslation();

    const MAXSCORE = 10;
    const MINSCORE = 1;
    const PLACEHOLDER = t('Write your message here...');

    const handleClick = ({ target }) => {
        const wrapperElem = wrapperRef?.current;
        const triggerElem = triggerRef?.current;

        if (triggerElem?.contains(target)) {
            setShow(true);
        } else if (show && wrapperElem && !wrapperElem?.contains(target)) {
            setShow(false);
        }
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleTexAreaChange = (evt) => {
        setTextInput(evt.target.value);
    };

    const onSubmit = () => {
        setTextInput('');
        textInput ?
            setFeedBackResponse({
                score: selectedScore,
                message: textInput,
                isAnonymous: isAnonymous,
                optOut: optOut
            }) : setFeedBackResponse({
                score: selectedScore,
                isAnonymous: isAnonymous,
                optOut: optOut
            });

    };

    const handleOptOut = () => {
        setOptOut(!optOut);
    };

    const handleOnChange = () => {
        setIsAnonymous(!isAnonymous);
    };

    useEffect(() => {
        selectedScore || optOut ? setSubmitDisable(false) : setSubmitDisable(true);

        feedBackResponse && sendFeedBackResponse(feedBackResponse);

        document.addEventListener('mousedown', handleClick);
        return function cleanup() {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [selectedScore, optOut, triggerRef, wrapperRef, feedBackResponse]);

    let keyCounter = 0;
    const recommandationButtons = Array(MAXSCORE - MINSCORE + 1).fill('').map((_, index) => <Button
        className={selectedScore === index + MINSCORE ? 'selected' : ''}
        key={keyCounter++}
        onClick={() => setSelectedScore(index + MINSCORE)}>
        {MINSCORE + index}
    </Button>);

    const recommandationDom = useMemo(() => {
        return <Row className='mt-4'>
            <Col className='scoreButtonWrapper'>
                <p className="recomandationText mt-2">{t('On a scale of 1 - 10 how likely you are to recommend this tool to someone you know?')}</p>
                {recommandationButtons}
                <div className='d-flex flex-row align-items-center justify-content-between scoreLabels'>
                    <p>{t('1 - Not likely at all')}</p>
                    <p className='text-right'>{t('10 - Extremely likely')}</p>
                </div>
            </Col>
        </Row>;
    }, [recommandationButtons]);

    const textInputDom = useMemo(() => {
        return <Row>
            <Col xs={12}>
                <p className='mb-2'>{t('Tell us how we can improve')}</p>
                <textarea
                    aria-label="textInput"
                    height="100"
                    onChange={handleTexAreaChange}
                    placeholder={PLACEHOLDER}
                    type="text"
                    disabled={false}
                    value={textInput}
                    autoFocus={true}
                    name='textarea'
                />
            </Col>
            <Col xs={12} className={`anonymitySwitch d-flex flex-row align-items-center ${isAnonymous ? 'checked' : 'notChecked'}`}>
                <Switch
                    onChange={handleOnChange}
                    checked={isAnonymous}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor='#B7C3DC'
                    onColor='#2672FB'
                    height={20}
                    width={35}
                    id="switch"
                    aria-checked="true"
                    aria-labelledby="type_switch"
                />
                <label className='optOutLabel pt-1' onClick={handleOnChange}>
                    {isAnonymous ? t('Anonymity on') : t('Anonymity off')}
                </label>
            </Col>
            <Col xs={12} className='d-flex align-items-center justify-content-between flex-row'>
                <Checkbox
                    isSelected={optOut}
                    onChange={handleOptOut}
                    useManualCheck
                >
                    {t('Don’t show this again')}
                </Checkbox>
                <Button
                    className="buttonSubmit"
                    onClick={onSubmit}
                    variant="btn-primary"
                    disabled={submitDisable}
                >
                    {t('Submit')}
                </Button>
            </Col>
        </Row>;
    }, [textInput, isAnonymous, optOut, submitDisable]);

    if (!ready) {
        return;
    }

    return (
        <>
            {show && <div className='backgroundOverlay'>
                <div className="feedbackPopUp">
                    <Collapse in={show}>
                        <div className='feedbackPopupWrapper' ref={wrapperRef}>
                            <Row>
                                <Col xs={8}>
                                    <p className='h2'>{t('Give Feedback')}</p>
                                </Col>
                                <Col xs={4} className="pr-0 text-right">
                                    <button className='closeButton' onClick={handleClose}>
                                        <i className='icon-close' />
                                    </button>
                                </Col>
                            </Row>
                            {recommandationDom}
                            {textInputDom}
                        </div>
                    </Collapse>
                </div>
            </div>}
        </>
    );
};

FeedbackPopup.propTypes = {
    sendFeedBackResponse: PropTypes.func,
    triggerRef: PropTypes.object.isRequired,
};
export default FeedbackPopup;
