import { NotificationBarProvider, DropZoneProvider, UserProvider, StatusNotification, ErrorBoundary, IpRestriction, InAppNotificationProvider } from '@mdc/ui';
import { UploadPreloaderProvider } from '@mdc/services';
import { I18nextProvider } from 'react-i18next';

import PropTypes from 'prop-types';

export const WrapRootElement = ({ element }, i18nObject) => {

    return (
        <I18nextProvider i18n={i18nObject}>
            <ErrorBoundary>
                <NotificationBarProvider>
                    <UserProvider>
                        <IpRestriction>
                            <UploadPreloaderProvider>
                                <DropZoneProvider>
                                    <StatusNotification>
                                        <InAppNotificationProvider>
                                            {element}
                                        </InAppNotificationProvider>
                                    </StatusNotification>
                                </DropZoneProvider>
                            </UploadPreloaderProvider>
                        </IpRestriction>
                    </UserProvider>
                </NotificationBarProvider>
            </ErrorBoundary>
        </I18nextProvider>
    );
};

WrapRootElement.propTypes = {
    element: PropTypes.node.isRequired,
};
