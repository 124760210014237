import ContentLoader from 'react-content-loader';

const UserInfoPlaceholder = () => {
    return (
        <ContentLoader
            height={14}
            width={320}
            speed={1}
            primaryColor="#EDEEF3"
            secondaryColor="#f3f3f3"
        >
            <circle cx="6" cy="6" r="6" />
            <rect x="15" y="2" rx="3" ry="3" width="60" height="8" />
            <rect x="100" y="2" rx="3" ry="3" width="30" height="8" />
            <rect x="150" y="2" rx="3" ry="3" width="60" height="8" />
            <rect x="240" y="2" rx="3" ry="3" width="70" height="8"/>
        </ContentLoader>
    );
};

export default UserInfoPlaceholder;
