import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-bootstrap/Collapse';
import classnames from 'classnames';

import './Popup.scss';

const Popup = ({ children, className, triggerRef, isDisabled, closeOnSelect }) => {
    const [show, setShow] = useState(false);
    const wrapperRef = useRef(null);
    const popupWrapperClassnames = classnames('popupWrapper', className);

    const handleClick = ({ target }) => {
        const triggerElem = triggerRef.current;
        const wrapperElem = wrapperRef.current;

        if (isDisabled) {
            return;
        }

        if (!show && triggerElem.contains(target)) {
            setShow(true);
        } else if ((show && wrapperElem && !wrapperElem.contains(target)) || (closeOnSelect && wrapperElem.contains(target)) ) {
            setShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return function cleanup() {
            document.removeEventListener('mousedown', handleClick);
        };
    });

    return (
        <Collapse in={show}>
            <div className={popupWrapperClassnames} ref={wrapperRef}>
                {children}
            </div>
        </Collapse>
    );
};

Popup.defaults = {
    show: false,
    isDisabled: false,
    closeOnSelect: false
};

Popup.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    triggerRef: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool,
    closeOnSelect: PropTypes.bool
};

export default Popup;
