/* eslint-disable security/detect-object-injection */
import { createElement, isValidElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TableRow.scss';

const TableRow = ({ rowData, columnsMeta, isOdd }) => {

    const TableRowData = rowData;
    const cells = useMemo(() => {
        return columnsMeta.map(({ accessor, classes, component }) => {
            // eslint-disable-next-line no-prototype-builtins
            if (TableRowData.hasOwnProperty(accessor)) {
                let rowCellClassnames = classnames('tableDataCell', classes);
                const cellData = TableRowData[accessor];
                let cellLabel;
                let colspan = null;
                let keyCounter = 0;

                if (typeof cellData === 'object' && cellData !== null && cellData.value) {
                    cellLabel = cellData.value;
                    colspan = cellData.colspan || null;
                } else {
                    cellLabel = cellData;
                }
                const initializedComponent = component ? createElement(component, { value: cellLabel }) : null;

                return (
                    <td key={keyCounter++} className={rowCellClassnames} colSpan={colspan}>
                        {isValidElement(initializedComponent) ? initializedComponent : cellLabel}
                    </td>
                );
            }
        });

    }, [rowData, columnsMeta]);

    const mobileCellHeader = useMemo(() => {
        if (!cells.length) {
            return null;
        }
        const originalCell = cells[0];

        if (!originalCell) {
            return;
        }

        return (
            <td scope="colgroup" {...originalCell.props} colSpan={cells.length - 1} key={`mobile_header_cell_${originalCell.key}`}>
                {originalCell.props.children}
            </td>
        );
    }, [cells]);

    const rowClassnames = classnames({
        isOdd
    }, 'tableRow');
    return (
        <>
            <tr className={classnames(rowClassnames, 'mobileHeader')}>
                {mobileCellHeader}
            </tr>
            <tr scope="row" className={rowClassnames}>
                {cells}
            </tr>
        </>
    );
};

TableRow.defaults = {
    isOdd: false
};

TableRow.propTypes = {
    rowData: PropTypes.object.isRequired,
    columnsMeta: PropTypes.array.isRequired,
    isOdd: PropTypes.bool
};

export default TableRow;
