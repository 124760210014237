import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { scanInputChecker, AdvancedOptions, Authenticator } from '@mdc/services';
import { ADVANCED_OPTIONS_DEFAULTS, LOOKUP_TYPES, ERRORS } from '@mdc/constants';

import FileInput from '../common/file-input/FileInput';
import TextInput from '../common/text-input/TextInput';
import AdvancedOptionsPopup from './advanced-options/AdvancedOptionsPopup';
import AdvancedOptionsFullSize from './advanced-options/AdvancedOptionsFullSize';

import './ScanInput.scss';

const SHAKE_CLASS = 'shake';
const RULE_PARAM = 'rule';

const ScanInput = ({
    advancedOptionsList,
    onFileUpload: onFileUploadCallback,
    onProcess: onProcessCallback,
    onProcessError,
    placeholder,
    isFocused
}) => {
    const [advancedOptionsOpened, setAdvancedOptionsOpened] = useState(false);
    const [inputText, setInputText] = useState('');
    const [shouldShake, setShouldShake] = useState(false);
    const [archivePassword, setArchivePassword] = useState(' ');

    const { t, ready } = useTranslation();

    const getSelectedOptionsFromHeaders = (headers) => {
        let options = {};
        const predefinedOptions = {};

        advancedOptionsList && advancedOptionsList.forEach((optionObj) => {
            if (optionObj.checked) {
                if (Array.isArray(optionObj.value)) {
                    predefinedOptions[optionObj.param] = optionObj.value[0].key;
                } else {
                    predefinedOptions[optionObj.param] = optionObj.value;
                }
            }
        });

        if (Object.keys(predefinedOptions).length) {
            // eslint-disable-next-line no-prototype-builtins
            if (predefinedOptions.hasOwnProperty(RULE_PARAM) && headers.hasOwnProperty(RULE_PARAM)) {
                const optionsRules = headers[RULE_PARAM].split(',');
                const predefinedOptionsRules = predefinedOptions[RULE_PARAM].split(',');
                options = Object.assign({}, predefinedOptions, headers);
                options[RULE_PARAM] = [...predefinedOptionsRules, ...optionsRules].join(',');
            } else {
                options = Object.assign({}, predefinedOptions, headers);
            }
        } else {
            options = headers;
        }

        return options;
    };

    const storageOptions =  AdvancedOptions.getSelectedOptions() || ADVANCED_OPTIONS_DEFAULTS;

    const [selectedOptions, setSelectedOptions] = useState(() => {
        return getSelectedOptionsFromHeaders(storageOptions);
    });

    const toggleAdvancedOptions = () => {
        setAdvancedOptionsOpened(!advancedOptionsOpened);
    };

    const toggleSelectedOption = ({ param, value, isPassword = null }) => {
        AdvancedOptions.updateSelectedOptions(param, value, isPassword);
        setSelectedOptions(AdvancedOptions.getSelectedOptions());
    };

    useEffect(() => {
        // remove `filepassword` header on Page refresh and Scan submit
        if (archivePassword.trim()) {
            return;
        }

        if (selectedOptions.includes('filepassword')) {
            toggleSelectedOption({ param: 'filepassword', value: '' });
        }
    }, []);

    const onUserAuthStateChange = () => {
        setSelectedOptions(AdvancedOptions.getSelectedOptions());
    };

    useEffect(() => {
        Authenticator.events.on(Authenticator.events.topics.STATE_CHANGE, onUserAuthStateChange);
        return () => {
            Authenticator.events.off(Authenticator.events.topics.STATE_CHANGE, onUserAuthStateChange);
        };
    }, []);

    const onOptionClick = (param, value) => {
        if (param === 'passwordTextInput') {
            const passwordValue = value || ' ';
            setArchivePassword(passwordValue);
            AdvancedOptions.updatefilepassword(value);
            return;
        }
        if (param === 'filepassword') {
            toggleSelectedOption({ param: param, value: archivePassword });
            return;
        }
        toggleSelectedOption({ param, value });
    };

    const onProcess = () => {
        if (!inputText.length) {
            setShouldShake(true);
            return;
        }
        let input = inputText;
        let lookupType;

        try {
            if (scanInputChecker.isDomain(input)) {
                lookupType = LOOKUP_TYPES.DOMAIN;
            } else if (scanInputChecker.isIP(input)) {
                lookupType = LOOKUP_TYPES.IP;
            } else if (scanInputChecker.isUrl(input)) {
                lookupType = LOOKUP_TYPES.URL;
            } else if (scanInputChecker.isHash(input)) {
                lookupType = LOOKUP_TYPES.HASH;
            } else if (scanInputChecker.isCVE(input)) {
                lookupType = LOOKUP_TYPES.CVE;
            }
        } catch (err) {
            if (err && err === ERRORS.INVALID_IP) {
                onProcessError(ERRORS.INVALID_IP);
                return;
            }
        }

        if (!lookupType) {
            onProcessError(ERRORS.INVALID_SEARCH_TERM);
            return;
        }
        if (lookupType === LOOKUP_TYPES.URL || lookupType === LOOKUP_TYPES.DOMAIN) {
            input = scanInputChecker.cleanURL(input);
        }

        onProcessCallback(lookupType, input);
    };

    if (!ready) {
        return null;
    }

    const inputWrapperClasss = classnames({
        'inputWrapper': true,
        'advancedOptionOpen': advancedOptionsOpened
    });

    const advancedBtnClass = classnames({
        'advanced': true,
        'advancedOptionOpen': advancedOptionsOpened
    });

    const advancedOptionsIsDisabled = inputText !== '';

    return (
        <>
            <CSSTransition
                classNames={SHAKE_CLASS}
                in={shouldShake}
                onEntered={() => {
                    setShouldShake(false);
                }}
                timeout={500}
            >
                <div className={inputWrapperClasss}>
                    <div className="advancedOptionsInput">
                        <div className="expandableInput">
                            <TextInput
                                onChange={setInputText}
                                onSubmit={onProcess}
                                placeholder={placeholder}
                                isFocused={isFocused}
                            />
                        </div>
                    </div>
                    <FileInput onFileUpload={onFileUploadCallback}/>
                </div>
            </CSSTransition>

            <div className="buttonWrapper">
                <ButtonGroup>
                    <Button
                        className={advancedBtnClass}
                        disabled={advancedOptionsIsDisabled}
                        onClick={toggleAdvancedOptions}
                        variant="outline-light"
                    >
                        {t('advanced options')}
                    </Button>
                </ButtonGroup>

                <ButtonGroup>
                    <Button
                        className="process"
                        onClick={onProcess}
                    >
                        {t('Process')}
                    </Button>
                    <Button
                        variant="link"
                        className="analyzeOptions"
                        aria-label='Select the analyze options'
                    >
                        <AdvancedOptionsPopup
                            isDisabled={advancedOptionsIsDisabled}
                            onOptionClick={onOptionClick}
                            options={advancedOptionsList}
                        />
                    </Button>
                </ButtonGroup>
            </div>
            <AdvancedOptionsFullSize
                show={advancedOptionsOpened}
                onClose={() => setAdvancedOptionsOpened(false)}
                onAccept={() => {
                    setAdvancedOptionsOpened(false);
                    onProcess();
                }}
                onOptionClick={onOptionClick}
                options={advancedOptionsList}/>
        </>
    );
};

export default ScanInput;

ScanInput.propTypes = {
    advancedOptionsList: PropTypes.array,
    onFileUpload: PropTypes.func.isRequired,
    onProcess: PropTypes.func.isRequired,
    onProcessError: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    isFocused: PropTypes.bool
};

