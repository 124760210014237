import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { getRandomID, Authenticator } from '@mdc/services';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

import './VoteButtons.scss';

const VoteButtons = ({ isSignedIn, upVotes, downVotes, handleUpVote, handleDownVote, alreadyVoted, disabled }) => {
    const [upVoteButtonTooltipId] = useState(`VoteButtonNotSignedIn${getRandomID(5)}`);
    const [downVoteButtonTooltipId] = useState(`VoteButtonNotSignedIn${getRandomID(5)}`);
    const [voteInfoTooltipId] = useState(`VotingInfo${getRandomID(5)}`);
    const { t, ready } = useTranslation();

    const TooltipContent = useMemo(() => {
        return <span>
            {t('You must')}{' '}
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    Authenticator.authRedirect('https://id.opswat.com/register');
                }}>
                {t('Sign Up')}
            </a>{' '}
            {t('or')}{' '}
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    Authenticator.authRedirect('/login');
                }}>
                {t('Login')}
            </a>{' '}
            {t('to vote')}
        </span>;
    }, [t]);

    const upVoteButtonClassName = classNames({
        alreadyVoted: alreadyVoted && alreadyVoted === 'up',
        voteButtonDisabled: !isSignedIn,
        upVoteButton: isSignedIn
    }, 'voteButton');

    const downVoteButtonClassName = classNames({
        alreadyVoted: alreadyVoted && alreadyVoted === 'down',
        voteButtonDisabled: !isSignedIn,
        downVoteButton: isSignedIn
    }, 'voteButton');

    const voteUpButton = useMemo(() => {
        const button = <i className='icon icon-thumbs-up' />;
        if (isSignedIn) {
            return (<>
                <Button variant='link' className={upVoteButtonClassName} onClick={handleUpVote} disabled={disabled}>
                    {button}
                    {upVotes}
                </Button>
            </>);
        }
        return (<>
            <Button variant='link' data-tip data-tooltip-id={upVoteButtonTooltipId} className={upVoteButtonClassName} onClick={handleUpVote} disabled={disabled}>
                {button}
                {upVotes}
            </Button>
        </>);

    }, [isSignedIn, upVotes, disabled]);

    const voteDownButton = useMemo(() => {
        const button = <i className='icon icon-thumbs-down' />;
        if (isSignedIn) {
            return (<>
                <Button variant='link' className={downVoteButtonClassName} onClick={handleDownVote} disabled={disabled}>
                    {button}
                    {downVotes}
                </Button>
            </>);
        }
        return (<>
            <Button variant='link' data-tip data-tooltip-id={downVoteButtonTooltipId} className={downVoteButtonClassName} disabled={disabled}>
                {button}
                {downVotes}
            </Button>
        </>);
    }, [isSignedIn, downVotes, disabled]);

    const VoteInfoTooltipContent = useMemo(() => {
        return t('<strong>Clean</strong>: legitimate file with no malicious behavior. <br/> <strong>Malicious</strong>: dangerous file which might harm your computer, leak data or has unintended behavior. <hr/> The voting opinion is independent of scan results displayed');

    }, [t]);

    const voteInfo = <i className='icon icon-info-circled' data-tip data-tooltip-id={voteInfoTooltipId} />;
    const sep = <span className='horizontalSep'></span>;

    if (!ready) {
        return null;
    }

    return (
        <div className="VoteButtonsWrapper">
            <p className='voteText'>
                {t('Cast your vote on this file:')}
                {voteInfo}
            </p>
            <div className='buttonsWrapper'>
                {voteUpButton} {sep} {voteDownButton}
                <Tooltip id={upVoteButtonTooltipId} className='buttonTooltip' variant='dark' delayHide={150} place={'top'}>
                    {TooltipContent}
                </Tooltip>
                <Tooltip id={downVoteButtonTooltipId} className='buttonTooltip' variant='dark' delayHide={150} place={'top'}>
                    {TooltipContent}
                </Tooltip>
                <Tooltip id={voteInfoTooltipId} className='voteInfoTooltip' variant='dark' delayHide={300} place={'bottom'}>
                    <span dangerouslySetInnerHTML={{ __html: VoteInfoTooltipContent }}></span>
                </Tooltip>
            </div>
        </div>
    );
};

VoteButtons.propTypes = {
    isSignedIn: PropTypes.bool.isRequired,
    handleUpVote: PropTypes.func.isRequired,
    handleDownVote: PropTypes.func.isRequired,
    upVotes: PropTypes.number,
    downVotes: PropTypes.number,
    alreadyVoted: PropTypes.string,
    disabled: PropTypes.bool
};

VoteButtons.defaultProps = {
    upVotes: 0,
    downVotes: 0
};

export default VoteButtons;
