import { Fragment, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SortButton, { SORT_STATES } from '../table/sort-button/SortButton';

import './PlainTableRow.scss';

const PlainTableRow = (props) => {
    const { columnsData, trClassName, data, columnsMeta, shouldCollapse, onExpandCellClick, hasSorting, shouldExpandAll, isEntireRowExpandable, dontCollapse, showExpandSymbol } = props;
    const [isCellExpanded, setIsCellExpanded] = useState(shouldCollapse);
    const [sort, setSort] = useState({ accessor: null, direction: null });

    const expandableData = useMemo(() => data?.meta?.data, [data]);

    const lessColClassNames = classNames({
        lessCol: columnsData?.filter((col) => !!col.Header).length <= 2 || dontCollapse
    });

    useEffect(() => {
        setIsCellExpanded(false);
    }, [shouldCollapse]);

    const className = classNames({
        isEntireRowExpandable: isEntireRowExpandable
    }, trClassName);

    useEffect(() => {
        if (shouldExpandAll) {
            setIsCellExpanded(true);
            onExpandCellClick(null, true);
        }
    }, [shouldExpandAll, data]);

    const arrowClassname = classNames({
        'icon-angle-down': !isCellExpanded,
        'icon-angle-up': isCellExpanded
    }, 'icon', lessColClassNames);

    const handleExpand = () => {
        setIsCellExpanded(!isCellExpanded);
        onExpandCellClick(!isCellExpanded);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleExpand();
        }
    };

    const onSort = (accessor, state) => {
        if (sort.accessor === accessor && sort.state === state) {
            state = SORT_STATES.INACTIVE;
        }
        props.onSort && props.onSort({ accessor, state });
        setSort({ accessor, state });
    };

    const getSortDirection = (state) => {
        let sortDirection;
        switch (state) {
            case SORT_STATES.UP:
                sortDirection = SORT_STATES.UP;
                break;
            case SORT_STATES.DOWN:
                sortDirection = SORT_STATES.DOWN;
                break;
            default:
                sortDirection = SORT_STATES.INACTIVE;
        }
        return sortDirection;
    };

    const getHeaderValue = (cellName) => {
        return columnsData && columnsData.filter((info) => {
            return info.accessor === cellName;
        })[0].Header;
    };

    const expandableAreaDom = useMemo(() => {
        if (isCellExpanded) {
            if (expandableData?.tableData) {
                return <tr className='expandedCellWrapper'>
                    <td colSpan={Math.floor(columnsData.length / 2)} className='expandedCell'>
                        <table>
                            <tbody>
                                {expandableData?.tableData?.map((info) => {
                                    return (
                                        <tr key={info?.id} className='noBorderRow'>
                                            {info.header
                                                ? <td className={`expandedHeader header-${info.header}`}>{info.header}</td>
                                                : <></>
                                            }
                                            <td>{info.value}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </td>
                    {
                        expandableData?.description &&
                        <td colSpan={Math.ceil(columnsData.length / 2)} className='expandedCell description'>
                            {expandableData?.description}
                        </td>
                    }
                </tr>;
            }
            return expandableData?.custom;
        }

        return <tr className='expandedCellWrapper'></tr>;
    }, [isCellExpanded, expandableData, columnsData]);

    const expandableDataDom = <button
        className='cell-expandable'
        onClick={handleExpand}
        onKeyDown={handleKeyDown}
    >
        <i className={arrowClassname} />
    </button>;

    const row = useMemo(() => {
        return columnsMeta?.map((cellName) => {
            const isSorted = sort.accessor === cellName;
            let sortDirection = getSortDirection(sort.state);
            const sortButtonState = isSorted ? sortDirection : SORT_STATES.INACTIVE;
            const SortButtonComponent = (hasSorting ?
                <SortButton
                    state={sortButtonState}
                    onSort={onSort.bind(null, cellName)} /> : null);

            return (
                <Fragment key={getHeaderValue(cellName)}>
                    <td key={getHeaderValue(cellName)} className={classNames(lessColClassNames, 'tdCell cell-header')}>{getHeaderValue(cellName)} {cellName !== 'meta' && SortButtonComponent} </td>
                    <td key={cellName} className={classNames(lessColClassNames, `tdCell cell-${cellName}`)} data-header={getHeaderValue(cellName)}>
                        {
                            data[cellName]?.expandable ?
                                expandableDataDom :
                                data[cellName] || '-'
                        }
                    </td>
                </Fragment>
            );
        });
    }, [columnsMeta, isCellExpanded, data, expandableAreaDom, shouldCollapse, sort]);

    const handleExpandAction = () => {
        if (showExpandSymbol) {
            if ((data.object_details || data.object_sha256)) {
                handleExpand();
            }
        } else {
            if (isEntireRowExpandable) {
                handleExpand();
            }
        }
    };

    return (
        <>
            <tr className={className} onClick={handleExpandAction}>
                {row}
                <td className={`${isCellExpanded ? 'icon-angle-up' : 'icon-angle-down'} ${showExpandSymbol && (data.object_details || data.object_sha256) ? '' : 'invisible-td'}`}></td>
            </tr>
            {expandableAreaDom}
        </>
    );
};

PlainTableRow.propTypes = {
    trClassName: PropTypes.string,
    data: PropTypes.object,
    columnsMeta: PropTypes.array,
    columnsData: PropTypes.array,
    shouldCollapse: PropTypes.bool,
    shouldExpandAll: PropTypes.bool,
    onExpandCellClick: PropTypes.func,
    hasSorting: PropTypes.bool,
    onSort: PropTypes.func,
    isEntireRowExpandable: PropTypes.bool,
    dontCollapse: PropTypes.bool,
    showExpandSymbol: PropTypes.bool
};

export default PlainTableRow;
