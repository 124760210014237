import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import HistoryChart from './HistoryChart';
import HistorySelection from './HistorySelection';
import * as d3 from 'd3';
import HistoryPlaceholder from './HistoryPlaceholder';

const HistoryGraph = ({ scanHistory, height }) => {

    const mainRef = useRef(null);
    const [width, setWidth] = useState(700);

    const data = useMemo(() => scanHistory?.filter((item) => item.start_time).map((item) => {
        item.date = new Date(item.start_time);
        return item;
    }), [scanHistory]);

    useEffect(() => {
        function resize() {
            if (mainRef.current) {
                setWidth(parseFloat(d3.select(mainRef.current).style('width')));
            }
        }

        resize();
        window.addEventListener('resize', resize);

        return (() => {
            window.removeEventListener('resize', resize);
        });
    }, [mainRef.current, data]);

    const [range, setRange] = useState([]);

    const chartDOM = useMemo(() => {
        if (data) {
            return (<HistoryChart scanHistory={data} dateRange={range} height={height} width={width}/>);
        }

        return <HistoryPlaceholder width={width}/>;
    }, [data, range, width, height]);

    const selectUpdate = (radius) => {
        setRange(radius);
    };

    const selectorDOM = useMemo(() => {
        if (data) {
            return (<HistorySelection scanHistory={data} selectUpdate={selectUpdate} width={width}/>);
        }

        return undefined;
    }, [data, width]);

    return (
        <div className='historyGraph' ref={mainRef}>
            {chartDOM}
            {selectorDOM}
        </div>
    );
};


HistoryGraph.defaultProps = {
    height: 170
};

HistoryGraph.propTypes = {
    scanHistory: PropTypes.array,
    height: PropTypes.number
};

export default HistoryGraph;
