import PropTypes from 'prop-types';
import IconButton from '../../icon-button/IconButton';

import './PaginationNavigation.scss';

export const NAVIGATION_DIRECTIONS = {
    FIRST_PAGE: 'first page',
    PREVIOUS_PAGE: 'previous page',
    NEXT_PAGE: 'next page',
    LAST_PAGE: 'last page'
};

const PaginationNavigation = ({ totalPages, currentPage, onNavigation }) => {

    if (!totalPages || totalPages < 2) {
        return null;
    }

    return (
        <div className="paginationNavigation">
            <IconButton
                isDisabled={currentPage === 1}
                iconClass="icon-angle-double-left"
                onClick={onNavigation.bind(null, { direction: NAVIGATION_DIRECTIONS.FIRST_PAGE, totalPages })}/>
            <IconButton
                isDisabled={currentPage === 1}
                iconClass="icon-angle-left"
                onClick={onNavigation.bind(null, { direction: NAVIGATION_DIRECTIONS.PREVIOUS_PAGE, totalPages })}/>
            <div className="currentPage"> {currentPage}/{totalPages} </div>
            <IconButton
                isDisabled={currentPage >= totalPages}
                iconClass="icon-angle-right"
                onClick={onNavigation.bind(null, { direction: NAVIGATION_DIRECTIONS.NEXT_PAGE, totalPages })}/>
            <IconButton
                isDisabled={currentPage >= totalPages}
                iconClass="icon-angle-double-right"
                onClick={onNavigation.bind(null, { direction: NAVIGATION_DIRECTIONS.LAST_PAGE, totalPages })}/>
        </div>
    );
};

PaginationNavigation.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onNavigation: PropTypes.func.isRequired
};

export default PaginationNavigation;
