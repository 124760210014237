import React, { useMemo } from 'react';
import { Dropdown, ButtonGroup as BootstrapButtonGroup, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ButtonGroup.scss';

const ButtonGroup = ({ buttonIcon, buttonText, selectedItem, onUpdateRefreshRate, items, onButtonClick, dropdownTitle, isActionPerformed }) => {
    const buttonItems = useMemo(() => items.map((item) => {
        return <React.Fragment key={item?.id}>
            { item?.separated && <Dropdown.Divider/> }
            <Dropdown.Item className={selectedItem.key === item.key ? 'dropdownItem selectedItem' : 'dropdownItem'} key={item?.id} onClick={() => onUpdateRefreshRate(items.find((refreshRate) => refreshRate.key === item.key))}>
                {item.label}
            </Dropdown.Item>
        </React.Fragment>;
    }), [selectedItem]);

    const buttonClassName = classNames({
        'actionPerforming': isActionPerformed
    }, 'customButtonGroup');

    return <Dropdown as={BootstrapButtonGroup} drop={'down'} className={buttonClassName}>
        <Button variant="primary" onClick={() => onButtonClick(true)}>
            {buttonIcon ? <i className={`icon ${buttonIcon}`}></i> : ''}
            {buttonText}
        </Button>

        <Dropdown.Toggle split variant="primary" id="dropdownSplit"/>

        <Dropdown.Menu alignRight>
            {dropdownTitle && <Dropdown.Item className='dropdownItem disabledItem' disabled>
                {dropdownTitle}
            </Dropdown.Item>}
            {buttonItems}
        </Dropdown.Menu>
    </Dropdown>;
};

ButtonGroup.propTypes = {
    buttonIcon: PropTypes.string,
    buttonText: PropTypes.string,
    selectedItem: PropTypes.object,
    onUpdateRefreshRate: PropTypes.func,
    items: PropTypes.array,
    onButtonClick: PropTypes.func,
    dropdownTitle: PropTypes.string,
    isActionPerformed: PropTypes.bool
};

export default ButtonGroup;
