import { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ScoreReportView from '../common/score-report-view/ScoreReportView';
import ScoreMoreDetail from '../common/score-more-detail/ScoreMoreDetail';
import SanitizationResultsSection from './SanitizationResultsSection';
import { useTranslation } from 'react-i18next';
import FileScanState from '../common/file-scan-state/FileScanState';
import './DataSanitization.scss';

const DataSanitization = (props) => {

    const { onViewClick, results, sanitizedResult, downloadSanitizedVersion, detailLink, isFileTypeSupported, invalidFileImg, availableFileImg, sanitizedFileLink } = props;
    const { t, ready } = useTranslation();

    const dataSanitizationSection = useMemo(() => {
        if (sanitizedResult?.reason !==  'Password Protected Document' && sanitizedResult?.result !=='Error' && results && results.length === 2) {
            return (
                <>
                    <Row>
                        <Col md={6} sm={12}>
                            <SanitizationResultsSection
                                sanitized={results[0]}
                                removed={results[1]}
                            />
                        </Col>
                        <Col md={6} sm={12}>
                            <ScoreReportView
                                reportText={t('Deep CDR is an advanced threat prevention technology that does not rely on detection. Instead, it assumes all files are malicious and sanitizes and rebuilds each file ensuring full usability with safe content')}
                                buttonContent={t('View Deep CDR Report')}
                                onViewClick={onViewClick}
                                sanitizedResult={sanitizedResult}
                                downloadSanitizedVersion={downloadSanitizedVersion}
                                sanitizedFileLink={sanitizedFileLink}
                            />
                        </Col>
                    </Row>
                </>
            );
        }

        return (
            <>
                <Row>
                    <Col sm={6} xs={12}>
                        <p className='h6Mask'>{t('Deep CDR')}</p>
                        <FileScanState isFileTypeSupported={isFileTypeSupported} sanitizedResult={sanitizedResult} scanOption={t('Deep CDR')} invalidFileImg={invalidFileImg} availableFileImg={availableFileImg}/>
                    </Col>
                    <Col sm={6} xs={12}>
                        <ScoreMoreDetail
                            detailText={t('Deep CDR is an advanced threat prevention technology that does not rely on detection. Instead, it assumes all files are malicious and sanitizes and rebuilds each file ensuring full usability with safe content')}
                            detailLink={detailLink}
                        />
                    </Col>
                </Row>
            </>
        );

    }, [results, onViewClick, downloadSanitizedVersion, isFileTypeSupported, invalidFileImg, availableFileImg, detailLink, sanitizedResult, t]);

    if (!ready) {
        return null;
    }

    return (
        <div className="dataSanitization">
            {dataSanitizationSection}
        </div>
    );

};

DataSanitization.propTypes = {
    onViewClick: PropTypes.func,
    results: PropTypes.any,
    sanitizedResult: PropTypes.object,
    downloadSanitizedVersion: PropTypes.func,
    detailLink: PropTypes.any,
    isFileTypeSupported: PropTypes.bool,
    invalidFileImg: PropTypes.object,
    availableFileImg: PropTypes.object,
    sanitizedFileLink: PropTypes.object,
};


export default DataSanitization;
