import axios, { CancelToken } from 'axios';
import { BASE_URL, API } from '@mdc/constants';
class BackendService {
    constructor() {
        this.baseUrl = BASE_URL + API.backend.baseUrl;
        this.instance = axios.create({
            baseURL: this.baseUrl,
            timeout: API.backend.defaultTimeout
        });
    }

    addCommonHeader(headerObject) {
        Object.entries(headerObject).forEach(([key, value]) => {
            this.instance.defaults.headers.common[key] = value;
        });
    }

    getCommonHeaders() {
        return this.instance.defaults.headers.common;
    }

    /**
     * @example
     *
     * const [aak, cancelAak] = BackendService.addApiKey();
     * await aak;
     * // OR
     * aak.then(...)
     *
     * // cancel the request
     * cancelAak();
     *
     */
    addApiKey = (apikey, data, headers) => {
        let cancel;
        const promise = this.instance.post(`/token/${apikey}`, data, {
            headers: Object.assign({}, this.getCommonHeaders(), headers),
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        return [promise, cancel];
    };

    getApikeyInfo = (data, headers) => {
        let cancel;
        const promise = this.instance.post('/token/apikey', data, {
            headers: Object.assign({}, this.getCommonHeaders(), headers),
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        return [promise, cancel];
    };

    updateApiKey = (data, headers) => {
        let cancel;
        const promise = this.instance.put('/token', data, {
            headers: Object.assign({}, this.getCommonHeaders(), headers),
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        return [promise, cancel];
    };

    getGuestSession = (data, headers) => {
        let cancel;
        const promise = this.instance.post('/token/get-guest-session', data, {
            headers: Object.assign({}, this.getCommonHeaders(), headers),
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        return [promise, cancel];
    };

    transferReputation = (data, headers) => {
        let cancel;
        const promise = this.instance.post('/token/transfer-reputation', data, {
            headers: Object.assign({}, this.getCommonHeaders(), headers),
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        return [promise, cancel];
    };

    /**
     * Create a subscription payment on stripe
     *
     * @param {[string]} paymentPlans the Stripe payment plans IDs
     * @returns {Promise<[data, cancel]>} [data, cancel]
     * @this axios error
     */
    createSubscription = async (paymentPlans, currency, selectedPlansPrices) => {
        const url = '/store/create-subscription';

        const headers = Object.assign({
            paymentPlans: JSON.stringify(paymentPlans),
            currency,
            selectedPlansPrices: JSON.stringify(selectedPlansPrices)
        }, this.getCommonHeaders());

        const response = await this.instance.get(url, { headers });
        const data = { ...response?.data };

        return data;
    };

    /**
     * Validate email for store eligibility
     *
     * @this axios error
     */
    emailValidator = async () => {
        let data = {};
        try {
            const url = '/store/email-validator';
            const headers = Object.assign({}, this.getCommonHeaders());
            data = await this.instance.get(url, { headers });
        } catch (error) {
            if (error?.response?.data?.code === 400032) {
                data.status = 'Not eligible';
            }
        }
        return data;
    };

    /**
     *
     * @param {string} currency
     */
    createBillingPortalSession = async (currency) => {
        let data = {};
        try {
            const url = '/store/create-portal-session';
            const headers = Object.assign({ currency }, this.getCommonHeaders());
            data = await this.instance.get(url, { headers });
        } catch (error) {
            data.error = error;
        }

        return data;
    };

    /**
     * Get ip based geo-location info
     *
     * @returns {Promise<*>} `{countryName, city}`
     * @throws axios error
     */
    getGeoLocation = async () => {
        const url = '/utils/location';
        const headers = Object.assign({}, this.getCommonHeaders());
        const { data } = await this.instance.get(url, { headers });
        return data;
    };

    /**
       * Get status page incidents
       *
       * @returns {Promise<*>} `{incidents: [], page: {}}`
       * @throws axios error
       */
    getStatusPageIncident = async () => {
        const url = 'utils/incidents';
        const headers = Object.assign({}, this.getCommonHeaders());
        const { data } = await this.instance.get(url, { headers });
        return data;
    };


    /**
     * OAuth Cognito
     * @param {*} data post body
     * @param {*} headers
     */
    createOauthClient = (data, headers) => {
        let cancel;
        const headersObj = Object.assign(headers, this.getCommonHeaders());
        const promise = this.instance.post('/oauth2/client', data, {
            headers: headersObj,
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        return [promise, cancel];
    };

    listAllClients = async (headers) => {
        let data = {};
        // eslint-disable-next-line no-useless-catch

        const url = '/oauth2/client';
        data = await this.instance.get(url, {
            headers: Object.assign(headers, this.getCommonHeaders())
        });

        return data;
    };

    listClient = async (clientId, headers) => {
        let data = {};
        // eslint-disable-next-line no-useless-catch
        const url = `/oauth2/client/${clientId}`;
        data = await this.instance.get(url, {
            headers: Object.assign(headers, this.getCommonHeaders())
        });

        return data;
    };

    removeClient = (data, headers) => {
        let cancel;
        const headersObj = Object.assign(headers, this.getCommonHeaders());
        const promise = this.instance.delete(`/oauth2/client/${data.clientId}`, {
            headers: headersObj,
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        return [promise, cancel];
    };

    /**
     * @param {object} data post body
     * @param {string} route
     */
    postRequest = (data, route) => {
        let cancel;
        const promise = this.instance.post(route, data, {
            headers: Object.assign({}, this.getCommonHeaders()),
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        return [promise, cancel];
    };

    getRequest = (route) => {
        let cancel;
        const promise = this.instance.get(route, {
            headers: Object.assign({}, this.getCommonHeaders()),
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        return [promise, cancel];
    };

    /**
     * Get [dashboard, statistics] reports
     *
     * @param {object} data post body with user info and hours range
     * @param {string} data.customerId - 'sso_user_id' from GET apikey API
     * @param {string} [data.organizationId] - (optional) 'organization.organization_id' from GET apikey API if available
     * @param {number} data.hours - hours range
     * @param {string} reportName - ['dashboard', 'statistics']
     */
    postReports = (data, reportName) => this.postRequest(data, '/reports/' + reportName);

    /**
     * POST feedback
     *
     * @param {object} data post body with user, email, page name and feedback response
     * @param {object} pageName 'dashboard' or 'statistics'
     */
    postFeedback = (data, pageName) => this.postRequest(data, `/feedback-form/${pageName}`);

    /**
    * GET feedback
    *
    * @param {object} userId 'sso_user_id' from GET apikey API
    * @param {object} pageName 'dashboard' or 'statistics'
    */
    getFeedback = (userId, pageName) => this.getRequest(`/feedback-form/${pageName}?userId=${userId}`);

    /**
     * Check if user is admin of organization or not
     *
     * @param {object} data post body with user info and hours range
     * @param {string} data.userId - 'sso_user_id' from GET apikey API
     * @param {string} data.organizationId - 'organization.organization_id' from GET apikey API if available
     */
    postIsOrganizationAdmin = (data) => this.postRequest(data, '/organization/is-admin');

    /**
     * Get ip whitelist on organization
     *
     * @param {object} data post body with user info and hours range
     * @param {string} data.userId - 'sso_user_id' from GET apikey API
     * @param {string} data.organizationId - 'organization.organization_id' from GET apikey API if available
     */
    postGetOrganizationIpWhitelist = (data) => this.postRequest(data, '/organization/get-ips');

    /**
     * Update ip whitelist on organization
     *
     * @param {object} data post body with user info and hours range
     * @param {string} data.userId - 'sso_user_id' from GET apikey API
     * @param {string} data.organizationId - 'organization.organization_id' from GET apikey API if available
     * @param {string[]} data.ipWhitelist - IP whitelist for organization
     */
    postUpdateOrganizationIpWhitelist = (data) => this.postRequest(data, '/organization/update-ips');

    /**
     * Update notifications tags on organization
     *
     * @param {object} data post body with tags and delivery methods
     * @param {string} data.userId - 'sso_user_id' from GET apikey API
     * @param {string} data.organizationId - 'organization.organization_id' from GET apikey API if available
     */
    postNotificationSubscribedTags = (data) => this.postRequest(data, '/organization/set-subscribed-tags');

    /**
     * Update notifications tags on organization
     *
     * @param {object} data post body with tags and delivery methods
     * @param {string} data.userId - 'sso_user_id' from GET apikey API
     * @param {string} data.organizationId - 'organization.organization_id' from GET apikey API if available
     */
    getNotificationSubscribedTags = (data) => this.postRequest(data, '/organization/get-subscribed-tags');
}

export default new BackendService();
