import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import classnames from 'classnames';

import './ScoreContainer.scss';

const LEVEL_VALUES = ['low', 'medium', 'high', 'critical'];

const ScoreContainer = ({ score, infoText, label, levelText, level, top }) => {

    const scoreLevel = level && LEVEL_VALUES.includes(level.toLowerCase()) ? level.toLowerCase() : LEVEL_VALUES[0];
    const levelClass = classnames(`level-${scoreLevel}`);
    const scoreTextClasses = classnames({ zeroLead: score < 10, zeroScore: score === 0 }, 'scoreText', levelClass);

    if (top === 'info') {
        return (
            <div className='scoreContainer'>
                <div className={classnames(levelClass, 'infoText')}>
                    <span>{infoText}</span>
                </div>

                <div className='scoreDetail'>
                    <span className={scoreTextClasses}>{score}</span>
                    <div className='scoreInfo'>
                        <div className='scoreLevel'>
                            <span>{label}</span><Badge className={levelClass} variant='dark'>{levelText}</Badge>
                        </div>
                    </div>
                </div>

            </div>);
    }

    if (top === 'score'){
        return (
            <div className='scoreContainer'>
                <div className='scoreLevel'>
                    <span>{label}</span><Badge className={levelClass} variant='dark'>{levelText}</Badge>
                </div>

                <div className='scoreDetail'>
                    <span className={scoreTextClasses}>{score}</span>
                    <div className='scoreInfo textSmall'>
                        <div className='infoText'>
                            <span>{infoText}</span>
                        </div>
                    </div>
                </div>
            </div>);
    }

    return (
        <div className='scoreContainer'>
            <div className='scoreDetail'>
                <span className={scoreTextClasses}>{score}</span>
                <div className='scoreInfo'>
                    <div className={classnames(levelClass, 'infoText')}>
                        <span>{infoText}</span>
                    </div>
                    <div className='scoreLevel'>
                        <span>{label}</span><Badge className={levelClass} variant='dark'>{levelText}</Badge>
                    </div>
                </div>
            </div>
        </div>);
};

ScoreContainer.propTypes = {
    score: PropTypes.number.isRequired,
    infoText: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    levelText: PropTypes.string.isRequired,
    level: PropTypes.string,
    top: PropTypes.any
};

export default ScoreContainer;
