import BaseService from '../BaseService';
import { objectToURLParamsStr } from '../../query-params/QueryParams';

class ApikeyService {
    constructor(instance) {
        this.instance = instance;
        this.base = new BaseService(this.instance);
    }

    getInfo = (headers) => {
        return this.base.get('/apikey/', headers);
    }

    getReputation = (headers) => {
        return this.base.get('/apikey/reputation', headers);
    }

    getScanHistory = (params, headers) => {
        const paramsStr = objectToURLParamsStr(params);
        const requestParams = paramsStr ? `?${paramsStr}` : null;

        return this.base.get(`/apikey/scan-history${requestParams}`, headers);
    }

    getUsage = (params, headers) => {
        return this.base.get('/apikey/daily-usage/', headers);
    }

    getVulnerability = (headers) => {
        return this.base.get('/apikey/vulnerability/', headers, null, this.base.defaultCache());
    }

    saveVulnerability = (data, headers) => {
        return this.base.post('/apikey/vulnerability', data, headers);
    }

    submitNetPromoterScore = (data, headers) => {
        return this.base.post('/apikey/nps', data, headers);
    }

    getLimitsStatus = (headers) => {
        return this.base.get('apikey/limits/status', headers);
    }
}

export default ApikeyService;
