import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import '../common/icon-button/IconButton.scss';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import CopyButton from '../common/copy-button/CopyButton';

import './FileOverview.scss';

const FileOverview = ({ title, headlineUnderlined, firstColumnData, lastColumnData }) => {
    const { t, ready } = useTranslation();

    const generateFileOverviewData = (fileData) => {
        return fileData?.map((item) => {
            return (
                <Row key={item?.id}>
                    <Col sm={4} xs={12} className="text lighterText">
                        {t(item.column)}
                    </Col>
                    <Col sm={8} xs={12}>
                        <span className="text">
                            {item.iconBefore && <i className={`icon-${item.iconBefore} leftIcon`}/>}
                            {item.data}
                            {item.iconAfter &&
                            <CopyButton icon={item.iconAfter} data={item.data}/>}
                        </span>

                    </Col>
                    <Col xl={12}>
                        <div className="line"></div>
                    </Col>
                </Row>
            );
        });
    };


    const infoDOM = useMemo(() => {
        if (firstColumnData && lastColumnData) {
            return <Row>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} className='column'>
                    {generateFileOverviewData(firstColumnData)}
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} className='column'>
                    {generateFileOverviewData(lastColumnData)}
                </Col>
            </Row>;
        }

        return <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ContentLoader
                    height={170}
                    width={400}
                    speed={1}
                    primaryColor="#EDEEF3"
                    secondaryColor="#f3f3f3"
                >
                    <rect x="0" y="10" rx="4" ry="4" width="50" height="11"/>
                    <rect x="0" y="40" rx="4" ry="4" width="180" height="11"/>
                    <rect x="0" y="70" rx="4" ry="4" width="150" height="11"/>
                    <rect x="0" y="100" rx="4" ry="4" width="250" height="11"/>
                    <rect x="0" y="130" rx="4" ry="4" width="120" height="11"/>
                    <rect x="0" y="160" rx="4" ry="4" width="350" height="11"/>
                </ContentLoader>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <ContentLoader
                    height={170}
                    width={400}
                    speed={1}
                    primaryColor="#EDEEF3"
                    secondaryColor="#f3f3f3"
                >
                    <rect x="0" y="10" rx="4" ry="4" width="100" height="11"/>
                    <rect x="0" y="40" rx="4" ry="4" width="300" height="11"/>
                    <rect x="0" y="70" rx="4" ry="4" width="50" height="11"/>
                    <rect x="0" y="100" rx="4" ry="4" width="250" height="11"/>
                    <rect x="0" y="130" rx="4" ry="4" width="120" height="11"/>
                    <rect x="0" y="160" rx="4" ry="4" width="120" height="11"/>
                </ContentLoader>
            </Col>
        </Row>;
    }, [firstColumnData, lastColumnData]);

    if (!ready) {
        return null;
    }
    return (
        <div className="fileOverview">
            <p className='h6Mask'>{t(title)}</p>
            {headlineUnderlined && <div className='line'/>}
            {infoDOM}
        </div>
    );
};
FileOverview.propTypes = {
    lastColumnData: PropTypes.array,
    firstColumnData: PropTypes.array,
    title: PropTypes.string,
    headlineUnderlined: PropTypes.bool
};
export default FileOverview;
