import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ShortNumber } from '@mdc/services';

import './StatisticsCard.scss';

const StatisticsCard = ({ title, scoreText, score, content }) => {

    const simplifyScore = useMemo(() => {
        if (scoreText && score) {
            return ShortNumber.short(score);
        }

        return score;
    }, [score, scoreText]);

    return (<div className='statisticsCard'>
        <Row className='cardHeader ml-0 mr-0 d-flex flex-row align-items-center justify-content-between'>
            <div className='title' title={title}>{title}</div>
            {!!scoreText && <span className='scoreText'>{scoreText}<div className='score' title={score}>{simplifyScore}</div></span>}
        </Row>

        <Col className='contentNavy'>
            {content}
        </Col>
    </div>);
};

StatisticsCard.propTypes = {
    title: PropTypes.string.isRequired,
    scoreText: PropTypes.string,
    score: PropTypes.number,
    content: PropTypes.object.isRequired
};

export default StatisticsCard;
