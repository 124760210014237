import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './FileInput.scss';
const FileInput = (props) => {
    const [fileSelector, setfileSelector] = useState();
    const multipleUploads = props.multiple;
    const fileUploadCallback = (evt) => {
        const files = evt.target.files;
        props.onFileUpload(files);
    };
    const createFileSelector = () => {
        const fileChooser = document.createElement('input');

        fileChooser.setAttribute('type', 'file');

        if (multipleUploads) {
            fileChooser.setAttribute('multiple', 'multiple');
        }
        fileChooser.addEventListener('change', (evt) => {
            fileUploadCallback(evt);
        });

        return fileChooser;
    };

    useEffect(() => {
        setfileSelector(createFileSelector());
    }, [props.onFileUpload]);

    const handleClick = () => {
        if (fileSelector) {
            fileSelector.value = '';
            fileSelector.click();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClick();
        }
    };

    return (
        <div className="fileInputWrapper">
            <button
                className="fileInputIcon icon-attach-1"
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                tabIndex="0"
            />
        </div>
    );
};

export default FileInput;

FileInput.defaultProps = {
    multiple: false
};

FileInput.propTypes = {
    multiple: PropTypes.bool,
    onFileUpload: PropTypes.func.isRequired
};
