import { useCallback, useMemo } from 'react';
import { GtmService, i18nUtility } from '@mdc/services';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const InternalLink = (props) => {
    const { to, children, onClick } = props;

    const toUrl = useMemo(() => i18nUtility.rebuildUrlWithLangPrefix(to || ''), [to]);
    const onClickCallback = useCallback((evt) => {
        if (onClick) {
            onClick(evt);
        }

        GtmService.handleNavigation(toUrl);
    }, []);

    return (
        <Link
            {...props}
            to={toUrl}
            onClick={onClickCallback}
        >
            {children}
        </Link>
    );
};

InternalLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.any,
    onClick: PropTypes.func
};

export default InternalLink;
