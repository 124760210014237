/* eslint-disable no-useless-escape */
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import LightButton from '../common/light-button/LightButton';
import ScoreContainer from '../common/score-container/ScoreContainer';
import ScoreMoreDetail from '../common/score-more-detail/ScoreMoreDetail';
import { useTranslation } from 'react-i18next';
import ScoreReportView from '../common/score-report-view/ScoreReportView';
import { Authenticator, getRandomID } from '@mdc/services';
import { Tooltip } from 'react-tooltip';

import './FileBasedVulnerability.scss';

/**
 * @severityIndex from 0 to 100
 * @severity value should be one of ['CRITICAL', 'IMPORTANT', 'MODERATE', 'LOW']
 * refer to https://onlinehelp.opswat.com/mdcloud/Vulnerability_Severity.html
 */
const FileBasedVulnerability = ({ severityIndex, severity, viewReportClick, reportVulnerabilityClick, reportVulnerabilityResult, isSignIn, isFileTypeSupport, isColumnLayout, isDisplayedOnOverview }) => {

    const { t, ready } = useTranslation();
    const [buttonTooltipId] = useState(`ReportButtonNotSignedIn${getRandomID(5)}`);
    const TooltipContent = useMemo(() => {
        if (!isFileTypeSupport) {
            return <span>{t('We do not accept vulnerability submissions for this file type')}</span>;
        }

        if (!isSignIn) {
            return <span>
                {t('You must')}{' '}
                <a
                    href='#'
                    onClick={(e) => {
                        e.preventDefault();
                        Authenticator.authRedirect('https://id.opswat.com/register');
                    }}>
                    {t('Sign Up')}
                </a>{' '}
                {t('or')}{' '}
                <a
                    href='#'
                    onClick={(e) => {
                        e.preventDefault();
                        Authenticator.authRedirect('/login');
                    }}>
                    {t('Login')}
                </a>{' '}
                {t('to report a vulnerability')}
            </span>;
        }

        return null;
    }, [isSignIn, isFileTypeSupport, t]);

    const scoreDOM = useMemo(() => {
        if (severityIndex > 0) {
            let severityLowerCase = severity && severity.toLowerCase();
            let severityLevel;
            switch (severityLowerCase) {
                case 'critical':
                    severityLevel = 'critical';
                    severityLowerCase = t('critical');
                    break;
                case 'important':
                    severityLevel = 'high';
                    severityLowerCase = t('important');
                    break;
                case 'moderate':
                    severityLevel = 'medium';
                    severityLowerCase = t('moderate');
                    break;
                case 'low':
                default:
                    severityLevel = 'low';
                    severityLowerCase = t('low');
                    break;
            }

            return (<ScoreContainer score={severityIndex} infoText={t('Vulnerabilities detected')} label={t('Score:')} levelText={severityLowerCase} level={severityLevel} top={isColumnLayout ? 'info' : undefined} />);
        }

        if (reportVulnerabilityResult) {
            return (
                <div className='reportVulnerabilityResult'>
                    <div className='iconVulnerabilityResult'>
                        <i className='icon icon-ok-circled'></i>
                    </div>
                    <span className='textVulnerabilityResult'>
                        <p dangerouslySetInnerHTML={{ __html: t('Hash has been successfully submitted with the key <b>{{key}}</b>', { key: reportVulnerabilityResult }) }}>
                        </p>
                        <p dangerouslySetInnerHTML={{ __html: t('You can view your submissions <a href=\"/account/vulnerability-submissions\">here</a>') }} />
                    </span>
                </div>);
        }

        if (isSignIn && isFileTypeSupport) {
            return (
                <div className='reportContainer'>
                    <p>{t('No vulnerabilities reported')}</p>
                    <LightButton onClick={reportVulnerabilityClick}>{t('Report Vulnerabilities')}</LightButton>
                </div>);
        }

        return (
            <div className='reportContainer'>
                <p>{t('No vulnerabilities reported')}</p>
                <LightButton data-tip data-tooltip-id={buttonTooltipId} className='disabled'>{t('Report Vulnerabilities')}</LightButton>
                <Tooltip id={buttonTooltipId} className='reportVulnerabilityButtonTooltip' variant='dark' delayHide={300} place={'bottom'}>
                    {TooltipContent}
                </Tooltip>
            </div>);

    }, [severityIndex, severity, reportVulnerabilityClick, t]);

    const reportInfoDOM = useMemo(() => {
        if (severityIndex > 0) {
            if (!isColumnLayout) {
                return (
                    <ScoreReportView
                        reportText={t('This file belongs to applications that have been identified with IMPORTANT vulnerabilities by the National Institute of Standards and Technology (NIST)')}
                        buttonContent={t('View F-BVA Report')}
                        onViewClick={viewReportClick}
                    >
                    </ScoreReportView>);
            }

            return (
                <>
                    <ScoreMoreDetail
                        detailText={t('Our Vulnerability Assessment technology detects application and file based vulnerabilities before they are installed. We use our patented technology (U.S. 9749349 B1) to correlate vulnerabilities to software components, product installers, firmware packages and many other types of binary files, which are collected from a vast community of users and enterprise customers')}
                        detailLink={t('<a href=\"https://www.opswat.com/technologies/vulnerability-assessment\" rel=\"noopener noreferrer\" target=\"_blank\">Learn more about Vulnerabilities</a>')}
                    >
                    </ScoreMoreDetail>
                </>);
        }

        return (
            <ScoreMoreDetail
                detailText={t('Our Vulnerability Assessment technology detects application and file based vulnerabilities before they are installed. We use our patented technology (U.S. 9749349 B1) to correlate vulnerabilities to software components, product installers, firmware packages and many other types of binary files, which are collected from a vast community of users and enterprise customers')}
                detailLink={t('<a href=\"https://www.opswat.com/technologies/vulnerability-assessment\" rel=\"noopener noreferrer\" target=\"_blank\">Learn more about Vulnerabilities</a>')}
            >
            </ScoreMoreDetail>);

    }, [severityIndex, viewReportClick, t]);

    if (!ready) {
        return null;
    }

    return <div className='fileBasedVulnerability'>
        <Row>
            <Col md={isDisplayedOnOverview ? 6 : 12} className='scoreCol'>
                <p className='h6Mask'>{t('File-based Vulnerability Assessment')}</p>
                {scoreDOM}
            </Col>
            <Col md={isDisplayedOnOverview ? 6 : 12} className='reportCol'>
                {reportInfoDOM}
            </Col>
        </Row>
    </div>;
};

FileBasedVulnerability.propTypes = {
    severityIndex: PropTypes.number.isRequired,
    severity: PropTypes.string,
    viewReportClick: PropTypes.func,
    reportVulnerabilityClick: PropTypes.func,
    reportVulnerabilityResult: PropTypes.string,
    isSignIn: PropTypes.bool,
    isFileTypeSupport: PropTypes.bool,
    isColumnLayout: PropTypes.bool,
    isDisplayedOnOverview: PropTypes.bool
};

FileBasedVulnerability.defaultProps = {
    isColumnLayout: false,
    isDisplayedOnOverview: false
};

export default FileBasedVulnerability;
