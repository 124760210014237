import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { COOKIE_POLICY_STATUS, COOKIE_POLICY_STATUS_DURATION, COOKIE_POLICY_ACCEPTED } from '@mdc/constants';
import Cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';
import useCookieBannerState from './useCookieBannerState';

import './CookieBanner.scss';

const COOKIE_POLICY_STATUS_LOCAL = 'opswat-cookie-policy';

const CookieBanner = () => {
    const { t, ready } = useTranslation();
    const [isClient, setIsClient] = useState(false);
    const isCurrentEnvLocal = process.env.ENVIRONMENT === 'local';
    const cookieName = isCurrentEnvLocal ? COOKIE_POLICY_STATUS_LOCAL : COOKIE_POLICY_STATUS;
    const cookieOptions = {
        expires: COOKIE_POLICY_STATUS_DURATION,
        secure: isCurrentEnvLocal ? false : true,
        sameSite: 'Strict'
    };
    const [isRejected, setIsRejected] = useState(false);
    const [isAccepted, setIsAccepted] = useState(() => {
        const cookieState = Cookie.get(cookieName);
        if (!cookieState) {
            return false;
        }
        return cookieState;
    });
    const [isVisible, setIsVisible] = useCookieBannerState();
    const onAccept = () => {
        Cookie.set(cookieName, COOKIE_POLICY_ACCEPTED, cookieOptions);
        setIsAccepted(true);
        setIsVisible(false);
    };

    const onReject = () => {
        setIsRejected(true);
        setIsVisible(false);
    };

    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isVisible && ready && isClient && !isRejected && !isAccepted) {
        setIsVisible(true);
    }

    if (!isVisible) {
        return null;
    }

    return (
        <div className="cookieWrapper">
            <Container className="cookieBanner">
                <Row className="d-flex justify-content-center">
                    <Col
                        className="text d-flex"
                        md={{ span: 7, order: 1 }}
                        mx="auto"
                        sm={{ span: 12, order: 1 }}
                        xs={{ span: 12, order: 1 }}
                    >
                        <span className="align-self-center">
                            {t('To improve the user experience this website stores cookies on your computer. By continuing you accept our cookie policy. For additional infomation please read the Cookie Policy')}
                        </span>
                    </Col>
                    <Col
                        className="d-flex buttonWrapper justify-content-right"
                        md={{ span: 2, order: 2 }}
                        xs={{ span: 6, order: 2 }}
                    >
                        <Button
                            className="button accept"
                            onClick={onAccept}
                            variant="outline-primary"
                        >
                            {t('Accept')}
                        </Button>
                    </Col>
                    <Col
                        className="d-flex buttonWrapper justify-content-left"
                        md={{ span: 2, order: 3 }}
                        xs={{ span: 6, order: 3 }}
                    >
                        <Button
                            className="button reject"
                            onClick={onReject}
                            variant="outline-primary"
                        >
                            {t('Reject')}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CookieBanner;
