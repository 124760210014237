import { GtmService, i18nUtility } from '@mdc/services';
import { navigate } from 'gatsby';

class InternalNavigation {
    navigation(url) {
        const builtUrl = i18nUtility.rebuildUrlWithLangPrefix(url);
        navigate(builtUrl);
        GtmService.handleNavigation(builtUrl);
    }
}

export default new InternalNavigation();
