import BaseService from '../BaseService';

class HashService {
    constructor(instance) {
        this.instance = instance;
        this.base = new BaseService(this.instance);
    }

    getAdditional = (params, headers) => {
        return this.base.get(`/hash/${params.hash}/${params.info}`, headers);
    }

    getResults = (params, headers) => {
        return this.base.get(`/hash/${params.hash}`, headers);
    }

    getScanHistory = (params, headers) => {
        return this.base.get(`/hash/${params.hash}/scanhistory`, headers);
    }

    getVote = (params, headers) => {
        return this.base.get(`/hash/${params.hash}/vote`, headers);
    }

    saveUpVote = (params, data, headers) => {
        return this.base.put(`/hash/${params.hash}/upvote`, data, headers);
    }

    saveDownVote = (params, data, headers) => {
        return this.base.put(`/hash/${params.hash}/downvote`, data, headers);
    }

    deleteVote = (params, headers) => {
        return this.base.delete(`/hash/${params.hash}/vote`, headers);
    }
}

export default HashService;
