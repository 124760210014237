import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import './SubmissionStatus.scss';

const SubmissionStatus = (props) => {
    const value = props.value.toLowerCase();
    const { t, ready } = useTranslation();

    const classNames = classnames({
        'submissionStatus': true,
        'inProgress': value === 'inprogress',
        'closed': value === 'closed'
    });

    const text = value === 'closed' ? t('closed') : t('in progress');


    if (!ready) {
        return;
    }

    return (
        <div className={classNames}>
            {text}
        </div>
    );
};

SubmissionStatus.propTypes = {
    value: PropTypes.string.isRequired
};

export default SubmissionStatus;
