// eslint-disable-next-line no-unused-vars
import React, { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { useTranslation } from 'react-i18next';
import { dayjs, UtilsFunctions } from '@mdc/services';

import './ApexChart.scss';

const Chart = loadable(() => import('react-apexcharts'));

const getRatiosData = (lineData, stackedData) => {
    const ratiosData = lineData.map((yValue, index) => {
        if (yValue > 0 && stackedData[index] > yValue) {
            return 1;
        } else if (yValue > 0 && stackedData[index] < yValue) {
            return stackedData[index] / yValue;
        }
        return 0;
    });
    return ratiosData;
};

const ApexChart = ({ chartSeries, colors, xAxis, xFormat }) => {
    const { t, i18n, ready } = useTranslation();

    const yAxisOptions = useMemo(() => {
        const defaultYAxisOption = {
            showForNullSeries: false,
            show: true,
            showAlways: false,
            min: 0,
            forceNiceScale: true,
            labels: {
                show: true,
                align: 'right',
                style: {
                    fontSize: '12px',
                    fontFamily: 'SimplonNorm',
                    fontWeight: 500,
                },
                formatter: function (val) {
                    return (val + '').replace(/\.0+$/, '');
                }
            }
        };

        if (!chartSeries) {
            return defaultYAxisOption;
        }

        const lineData = Array.isArray(chartSeries[0]?.data) ? chartSeries[0].data : [];
        const maxY = lineData.length > 0 ? Math.max(...lineData) : 0;

        const stackedSeries = chartSeries.slice(1);
        const stackedData = lineData.map((yValue, index) => stackedSeries.reduce((sum, series) => sum + series.data[index], 0));

        // Find max ratio between stacked columns and area chart
        const ratiosData = getRatiosData(lineData, stackedData);

        let modifyStackedYAxis;
        for (let i = 0; i < ratiosData.length; i++) {
            if (!modifyStackedYAxis || ratiosData[i] > modifyStackedYAxis.ratio) {
                const ratioLine = lineData[i] / (maxY || 1);
                const amplify = ratioLine * 0.7;
                const maxStackedValue = stackedData[i] / amplify;
                const ratio = ratiosData[i];
                modifyStackedYAxis = {
                    ratio,
                    maxStackedValue
                };
            }
        }


        let lineYAxis = {
            ...defaultYAxisOption,
            opposite: false,
            max: maxY,
            labels: {
                ...defaultYAxisOption.labels,
                style: {
                    ...defaultYAxisOption.labels.style,
                    colors: [colors[0]],
                }
            }
        };

        const maxStackedY = !modifyStackedYAxis || modifyStackedYAxis.maxStackedValue > (maxY * 0.9) ? maxY : modifyStackedYAxis.maxStackedValue;

        return stackedSeries.length ? [
            lineYAxis,
            ...stackedSeries.map((s, index) => (
                {
                    ...defaultYAxisOption,
                    opposite: true,
                    max: maxStackedY,
                    labels: {
                        ...defaultYAxisOption.labels,
                        show: index === stackedSeries.length - 1 && modifyStackedYAxis?.maxStackedValue > 0 && maxStackedY !== maxY,
                        style: {
                            ...defaultYAxisOption.labels.style,
                            colors: [colors[index + 1]],
                        }
                    }
                }
            ))
        ] : [lineYAxis];
    }, [chartSeries]);

    const options = useMemo(() => ({
        chart: {
            id: 'basic-bar',
            foreColor: '#909090',
            stacked: true,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            type: 'line'
        },
        noData: {
            text: 'No data',
            align: 'center',
            verticalAlign: 'middle',
            style: {
                color: undefined,
                fontSize: '18px',
                fontFamily: 'SimplonNorm'
            }
        },
        colors: colors,
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'center',
            fontFamily: 'SimplonNorm',
            fontSize: '14px',
            fontWeight: 500,
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0
            }
        },
        stroke: {
            width: [2].concat(Array(chartSeries?.length > 1 ? chartSeries?.length - 1 : 0).fill(0)),
            curve: 'straight'
        },
        fill: {
            type: ['gradient'].concat(Array(chartSeries?.length > 1 ? chartSeries?.length - 1 : 0).fill('solid')),
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.4,
                stops: [0, 90, 100],
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '25%'
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 4,
            colors: ['#ffffff'],
            strokeColor: [colors[0]],
            strokeWidth: 2,
            shape: 'circle',
            hover: {
                sizeOffset: 1
            }
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: '13px',
                fontFamily: 'SimplonNorm'
            },
            x: {
                show: true,
                formatter: function (value, { dataPointIndex }) {
                    return i18n?.language?.split('-')[0] === 'en' ? dayjs(xAxis[dataPointIndex].from).format('MMMM Do YYYY, HH:mm') : dayjs(xAxis[dataPointIndex].from).format('YYYY MM DD, HH:mm');
                }
            },
            y: {
                formatter: function (value) {
                    return (value + '').replace(/\.0$/, '');
                }
            }
        },
        grid: {
            show: true,
            borderColor: '#ECEFF4',
            strokeDashArray: 3,
        },
        xaxis: {
            categories: xAxis?.map((y) => y.from),
            crosshairs: {
                show: false
            },
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: false,
                hideOverlappingLabels: true,
                style: {
                    fontSize: '12px',
                    fontFamily: 'SimplonNorm',
                    fontWeight: 500,
                },
                formatter: function (value) {
                    return dayjs(value).format(xFormat);
                }
            },
            tooltip: {
                enabled: false
            }
        },
        yaxis: yAxisOptions
    }), [chartSeries, yAxisOptions, colors, xFormat, xAxis, i18n]);

    const chartDom = useMemo(() => {
        if (!chartSeries) {
            const loaderHigh = 100;
            const minHigh = 20;
            const chartLength = 20 + UtilsFunctions.randomInt(6);
            let keyCounter = 0;

            return <ContentLoader
                height={'100%'}
                width={'100%'}
                viewBox={'0 0 100 100'}
                speed={1}
            >
                {Array(chartLength).fill('').map((v, index) => {
                    const chartHeight = minHigh + (loaderHigh - minHigh) * Math.random();
                    return <rect key={keyCounter++} x={`${loaderHigh * index / chartLength}`} y={`${loaderHigh - chartHeight}%`} rx='0' ry='0' width={`${50 / chartLength}%`} height={`${chartHeight}%`} />;
                })}
                <rect x={0} y={`${loaderHigh - 0.5}%`} rx='0' ry='0' width='100%' height={'0.5%'} />
            </ContentLoader>;
        }

        const hasChartData = chartSeries.filter((item) => item.data.filter((data) => data !== 0).length !== 0);

        if (!hasChartData.length) {
            return <div className='noChart'>
                <div className='noChartIcon' />
                <p>{t('No chart data available')}</p>
            </div>;
        }

        return <Chart
            options={options}
            series={hasChartData.length === 0 ? [] : chartSeries}
            type='area'
            height={'100%'}
        />;
    }, [options, chartSeries, t]);

    if (!ready) {
        return null;
    }

    return <div className='apexChart text-center'>
        {chartDom}
    </div>;
};

ApexChart.propTypes = {
    chartSeries: PropTypes.any,
    colors: PropTypes.any,
    xAxis: PropTypes.any,
    xFormat: PropTypes.any,
};

export default ApexChart;
