import { METADEFENDER } from '@mdc/constants';

const baseURL = METADEFENDER.baseUrl + '/' + METADEFENDER.version;

export default [
    {
        code: 'shell',
        raw: ({ type, id, apiKey }) => (`curl '${baseURL}/${type}/${id}' \\
    -H 'apikey: ${apiKey}'`),
        component: ({ type, id, apiKey }) => (`<code>curl '${baseURL}/${type}/${id}' \\
    -H 'apikey: ${apiKey}'</code>`)
    },
    {
        code: 'node.js',
        raw: ({ type, id, apiKey }) => (`var request = require('request');

const options = {
    url: '${baseURL}/${type}/${id}',
    headers: {
        'apikey': '${apiKey}'
    }
};

request(options, function(error, response, body) {
    console.log(JSON.stringify(body));
});
`),
        component: ({ type, id, apiKey }) => (`
<code><span class="kwd">var</span> request = <span class="glb">require</span>(<span class="str">'request'</span>);
            
<span class="kwd">var</span> options = {
    url: <span class="str">'${baseURL}/${type}/${id}'</span>,
    headers: {
        <span class="str">'apikey'</span>: <span class="str">'${apiKey}'</span>
    }
};

request(options, <span class="kwd">function</span>(error, response, body) {
    <span class="glb">console</span>.log(<span class="glb">JSON</span>.stringify(body));
});</code>`)
    },
    {
        code: 'python',
        raw: ({ type, id, apiKey }) => (`import requests

url = "${baseURL}/${type}/${id}"

headers = {
    'apikey': "${apiKey}"
}

response = requests.request("GET", url, headers=headers)

print(response.text)
`),
        component: ({ type, id, apiKey }) => (`
<code><span class="kwd">import</span> requests

url = <span class="str">"${baseURL}/${type}/${id}"</span>

headers = {
    <span class="str">'apikey'</span>: <span class="str">"${apiKey}"</span>
}

response = requests.request(<span class="str">"GET"</span>, url, headers=headers)

print(response.text)</code>`)
    },
    {
        code: 'ruby',
        raw: ({ type, id, apiKey }) => (`require 'uri'
require 'net/http'

url = URI("${baseURL}/${type}/${id}")

http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true

request = Net::HTTP::Get.new(url)
request["apikey"] = '${apiKey}'

response = http.request(request)
puts response.read_body`),
        component: ({ type, id, apiKey }) => (`
<code><span class="kwd">require</span> 'uri'
<span class="kwd">require</span> 'net/http'

url = URI(<span class="str">"${baseURL}/${type}/${id}"</span>)

http = Net::HTTP.new(url.host, url.port)
http.use_ssl = <span class="kwd">true</span>

request = Net::HTTP::Get.new(url)
request[<span class="str">"apikey"</span>] = <span class="str">'${apiKey}'</span>

response = http.request(request)
puts response.read_body</code>`)
    },
    {
        code: 'go',
        raw: ({ type, id, apiKey }) => (`package main

import (
\t"fmt"
\t"net/http"
\t"io/ioutil"
)

func main() {

\turl := "${baseURL}/${type}/${id}"

\treq, _ := http.NewRequest("GET", url, nil)

\treq.Header.Add("apikey", "${apiKey}")

\tres, _ := http.DefaultClient.Do(req)

\tdefer res.Body.Close()
\tbody, _ := ioutil.ReadAll(res.Body)

\tfmt.Println(res)
\tfmt.Println(string(body))

}`),
        component: ({ type, id, apiKey }) => (`
<code><span class="kwd">package</span> main

<span class="kwd">import</span> (
	<span class="str">"fmt"
	"net/http"
	"io/ioutil"</span>
)

<span class="kwd">func</span> <span class="glb">main</span>() {

	url := <span class="str">"${baseURL}/${type}/${id}"</span>

	req, _ := http.NewRequest(<span class="str">"GET"</span>, url, <span class="kwd">nil</span>)

	req.Header.Add(<span class="str">"apikey"</span>, <span class="str">"${apiKey}"</span>)

	res, _ := http.DefaultClient.Do(req)

	<span class="kwd">defer</span> res.Body.Close()
	body, _ := ioutil.ReadAll(res.Body)

	fmt.Println(res)
	fmt.Println(<span class="kwd">string</span>(body))

}</code>`)
    },
    {
        code: 'powershell',
        raw: ({ type, id, apiKey }) => (`$uri = '${baseURL}/${type}/${id}'

$headers = @{}
$headers.Add('apikey','${apiKey}')

$result = Invoke-WebRequest -Uri $uri -Headers $headers
Write-Output $result.content`),
        component: ({ type, id, apiKey }) => (`
<code><span class="kwd">$uri</span> = <span class="str">'${baseURL}/${type}/${id}'</span>

<span class="kwd">$headers</span> = @{}
<span class="kwd">$headers</span>.Add(<span class="str">'apikey'</span>,<span class="str">'${apiKey}'</span>)

<span class="kwd">$result</span> = Invoke-WebRequest -Uri <span class="kwd">$uri</span> -Headers <span class="kwd">$headers</span>
Write-Output <span class="kwd">$result</span>.content</code>`)
    }
];

