import { useMemo } from 'react';
import './AccountBanner.scss';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

const AccountBanner = ({ contentDom, handleCloseBanner, icon = 'info' }) => {
    const iconDom = useMemo(() => <span className='banner-info'>
        <i className={`icon-${icon}`}/>
    </span>, [icon]);

    const closeDom = useMemo(() => handleCloseBanner && <button className='banner-close' type='button' onClick={handleCloseBanner}>
        <i className='icon-close'/>
    </button>, [handleCloseBanner]);

    return <Row className='banner'>
        <Col className='banner-content'>
            {iconDom}
            {contentDom}
            {closeDom}
        </Col>
    </Row>;
};

AccountBanner.propTypes = {
    contentDom: PropTypes.node.isRequired,
    handleCloseBanner: PropTypes.func,
    icon: PropTypes.string
};

export default AccountBanner;

