/* eslint-disable no-useless-escape */
import { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import VoteButtons from '../../common/vote-buttons/VoteButtons';
import ContentLoader from 'react-content-loader';
import DownloadSanitizedButton from '../common/download-sanitized-button/DownloadsanitizedButton';
import { RESULT_TEXTS } from '@mdc/constants';
import { CopyButton } from '@mdc/ui';

import './ResultsHeader.scss';

const THREAT_FOUND_ID = 1;
const THREAT_NOT_FOUND_ID = 0;

const ResultsHeader = (
    {
        fileName,
        threatName,
        resultsId,
        sanitizedResult,
        downloadSanitized,
        loading,
        isSignedIn,
        upVotes,
        downVotes,
        handleUpVote,
        handleDownVote,
        alreadyVoted,
        disabledVote,
        hideThreatBar,
        addressCategories
    }) => {
    const { t, ready } = useTranslation();

    const resultTexts = useMemo(() => RESULT_TEXTS(t), [t]);

    const resultInfo = useMemo(() => {
        if (resultsId === THREAT_FOUND_ID) {
            return <span className='resultsHeaderInfo'>
                {`${t('Threat name')}${': '}`}
                <h2 className='resultsHeaderCritical'>{threatName ? threatName : resultTexts.resultStatus.unknown_threat}</h2>
            </span>;
        } else if (resultsId === THREAT_NOT_FOUND_ID) {
            return <p className='resultsHeaderInfo'>
                {`${t('No threats found on this file')}`}
            </p>;
        } else if (typeof resultsId === 'number' && Object.prototype.hasOwnProperty.call(resultTexts.resultStatus, `i${resultsId}`)) {
            return <p className='resultsHeaderInfo'>
                <span className='resultsHeaderCritical'>{resultTexts.resultStatus[`i${resultsId}`]}</span>
            </p>;
        }

        return undefined;
    }, [threatName, resultsId, t, resultTexts]);

    const downloadSanitizedVersionDom = <span className='downloadSanitizedButtonBox'>
        <DownloadSanitizedButton sanitized={sanitizedResult} downloadSanitized={downloadSanitized} />
    </span>;

    const textPlaceholder = <ContentLoader
        speed={1}
        height={10}
        width={200}
        primaryColor="#EDEEF3"
        secondaryColor="#f3f3f3">
        <rect x="0" y="0" rx="4" ry="4" width={200} height={10} />
    </ContentLoader>;

    const headerInfoDOM = useMemo(() => {
        if (!hideThreatBar) {
            return <>
                <Col lg={6} md={6} xs={12} className='fileNameWrapper wrapText'>
                    {(loading || !fileName) && <h1>{textPlaceholder}</h1>}
                    {(!loading && fileName) && <h1 title={fileName.length > 35 ? fileName : ''}>{fileName}</h1>}
                    {(!loading && fileName) && <CopyButton icon={'clone'} data={fileName} />}
                </Col>
                <Col lg={6} md={6} xs={12} className='resultsHeaderButtons'>
                    {downloadSanitizedVersionDom}
                </Col>
            </>;
        }

        return <Col className='fileNameWrapper'>
            {(loading || !fileName) && <h1>{textPlaceholder}</h1>}
            {(!loading && fileName) && <h1>{fileName}</h1>}
        </Col>;
    }, [loading, fileName, downloadSanitizedVersionDom, hideThreatBar]);

    const addressCategoriesProcessed = useMemo(() => {
        if (!addressCategories) {
            return;
        }

        const addressCateg = addressCategories?.reduce((info, address) => {
            const category = address?.category?.split(';') || null;
            return info.concat(category);
        }, []) || null;

        const noAddressCateg = addressCateg?.every((category) => category === null);

        if (Array.isArray(addressCateg) && !noAddressCateg) {
            const uniqueValues = addressCateg.filter((value, index, self) => self.indexOf(value) === index);
            return uniqueValues?.map((category) => {
                if (!category) {
                    return;
                }

                return <li key={category}>{category}</li>;
            });
        }

        return null;
    }, [addressCategories]);

    if (!ready) {
        return null;
    }

    return <div className='resultsHeader'>
        <Row className='resultsHeaderInfo'>
            {headerInfoDOM}
        </Row>

        {hideThreatBar && !addressCategoriesProcessed && <div className='separator' />}

        {!hideThreatBar &&
            <Row className='fileInfo pt-2 pb-2'>
                <Col lg={5} md={5} sm={5} xs={12} className='pl-0 align-items-center d-flex'>
                    {(loading || !resultInfo) && <p className='resultsHeaderInfo'>{textPlaceholder}</p>}
                    {!loading && resultInfo}
                </Col>
                {upVotes !== undefined && downVotes !== undefined && <Col lg={7} md={7} sm={7} xs={12} className='pr-1 pl-0'>
                    <div className='vote'>
                        <VoteButtons
                            isSignedIn={isSignedIn}
                            loginHref="/login"
                            signUpHref="/signUp"
                            handleUpVote={handleUpVote}
                            handleDownVote={handleDownVote}
                            upVotes={upVotes}
                            downVotes={downVotes}
                            alreadyVoted={alreadyVoted}
                            disabled={disabledVote} />
                    </div>
                </Col>}
            </Row>}


        {hideThreatBar && addressCategoriesProcessed &&
            <Row className='fileInfo pt-2 pb-2 addressCategoriesWrapper'>
                <Col className='pl-0 align-items-center d-flex'>
                    {(loading || !addressCategoriesProcessed) &&
                        <p className='resultsHeaderInfo'>{t('Tags')}</p>
                    }
                    {(!loading && addressCategoriesProcessed) &&
                        <ul className='addressCategories'>
                            <li className='tags'>{t('Tags')}</li>
                            {addressCategoriesProcessed}
                        </ul>
                    }
                </Col>
            </Row>}
    </div>;
};

ResultsHeader.propTypes = {
    fileName: PropTypes.string,
    threatName: PropTypes.string,
    resultsId: PropTypes.any,
    downloadSanitized: PropTypes.func.isRequired,
    sanitizedResult: PropTypes.object,
    loading: PropTypes.bool,
    isSignedIn: PropTypes.bool.isRequired,
    handleUpVote: PropTypes.func.isRequired,
    handleDownVote: PropTypes.func.isRequired,
    upVotes: PropTypes.number,
    downVotes: PropTypes.number,
    alreadyVoted: PropTypes.string,
    disabledVote: PropTypes.bool,
    hideThreatBar: PropTypes.bool,
    addressCategories: PropTypes.any
};

ResultsHeader.defaultProps = {
    threatName: '',
    hideThreatBar: false,
};


export default ResultsHeader;
