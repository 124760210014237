import { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

import './CommunityGraph.scss';

const MARGIN = { top: 20, right: 15, bottom: 0, left: 20 };
const WIDTH = 140 + MARGIN.left + MARGIN.right;
const HEIGHT = 150 + MARGIN.top + MARGIN.bottom;

const CommunityGraph = ({ upVotes, downVotes, voteScore }) => {
    const gradientMiddleOffset = upVotes && !downVotes ? 90 : 70;

    const upVotesDataSet = useMemo(() => {
        if (upVotes && voteScore !== -1) {
            return [[0, 0], [5, 5], [11, ((100 - voteScore) * 10)], [17, 5], [20, 0]];
        }

        return [[20, 0]];
    }, [upVotes, voteScore]);

    const downVotesDataSet = useMemo(() => {
        if (downVotes && voteScore !== -1) {
            return [[23, -10], [30, -voteScore * 10], [37, -10], [40, 0]];
        }

        return [];
    }, [downVotes, voteScore]);

    const dataSet = useMemo(() => {
        return upVotesDataSet.concat(downVotesDataSet);
    }, [voteScore, upVotesDataSet, downVotesDataSet]);

    let yScale = useMemo(() => {
        return d3.scaleLinear()
            .rangeRound([0, HEIGHT])
            .domain([
                d3.max(dataSet, function (d) {
                    return Math.abs(d[1]);
                }),
                -d3.max(dataSet, function (d) {
                    return Math.abs(d[1]);
                }),
            ]);
    }, [dataSet]);

    let xScale = useMemo(() => {
        return d3.scaleLinear()
            .rangeRound([0, WIDTH])
            .domain([
                0,
                d3.max(dataSet, function (d) {
                    return d[0];
                }),
            ]);
    }, [dataSet]);

    dataSet.sort(function (a, b) {
        if (a[0] < b[0]) {
            return -1;
        }

        return 0;
    });

    let line = useMemo(() => {
        return d3.line()
            .curve(d3.curveBundle.beta(1))
            .x(function (d) {
                return xScale(d[0]);
            })
            .y(function (d) {
                return yScale(d[1]);
            });
    }, [xScale, yScale]);

    const communityGraphDom = useMemo(() => {
        return <svg width={WIDTH} height={HEIGHT}>
            <g>
                <g>
                    <path
                        className='line'
                        d={line(dataSet)}
                    />
                </g>
                <linearGradient
                    id='lineChartGradient'
                    gradientTransform='rotate(90)'
                >
                    <stop offset='0%' stopColor={ !upVotes ? '#cccccc' : '#66cc33' }/>
                    <stop offset={`${gradientMiddleOffset}%`} stopColor='#cccccc'/>
                    {downVotes && voteScore !== -1 && <stop offset='90%' stopColor='#cc3333'/>}
                </linearGradient>
            </g>

        </svg>;
    }, [upVotes, downVotes, voteScore]);

    return <div className="communityGraph mx-auto">
        {communityGraphDom}
    </div>;
};

CommunityGraph.propTypes = {
    upVotes: PropTypes.number,
    downVotes: PropTypes.number,
    id: PropTypes.string,
    action: PropTypes.string,
    voteScore: PropTypes.number
};

export default CommunityGraph;
