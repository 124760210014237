import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { Col, Row } from 'react-bootstrap';

import './ScorePlaceholder.scss';

const ScorePlaceholder = ({ title, isColumnLayout }) => {
    if (isColumnLayout) {
        const rowCount = 18;
        let keyCounter = 0;

        return (
            <div className='scorePlaceholder'>
                <Row>
                    <Col xs={12}>
                        <p className='h6Mask'>{title}</p>
                        <ContentLoader
                            height={110}
                            width={200}
                            speed={1}
                            primaryColor="#EDEEF3"
                            secondaryColor="#f3f3f3"
                        >
                            <rect x="0" y="0" rx="2" ry="4" width="130" height="15"/>
                            <rect x="0" y="30" rx="4" ry="2" width="60" height="70"/>
                            <rect x="75" y="55" rx="4" ry="4" width="80" height="16"/>
                        </ContentLoader>
                    </Col>
                    <Col xs={12}>
                        <ContentLoader
                            height={250}
                            width={200}
                            speed={1}
                            primaryColor="#EDEEF3"
                            secondaryColor="#f3f3f3"
                        >
                            {Array(rowCount).fill('').map((row, index) =>
                                <rect key={keyCounter} x="0" y={17 + 12 * index} rx="4" ry="4" width={140 + Math.random() * 40} height="8"/>
                            )}
                        </ContentLoader>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <div className='scorePlaceholder'>
            <Row>
                <Col md={6}>
                    <p className='h6Mask'>{title}</p>
                    <ContentLoader
                        height={90}
                        width={400}
                        speed={1}
                        primaryColor="#EDEEF3"
                        secondaryColor="#f3f3f3"
                    >
                        <rect x="0" y="0" rx="2" ry="2" width="75" height="90"/>
                        <rect x="90" y="20" rx="4" ry="4" width="250" height="20"/>
                        <rect x="90" y="50" rx="4" ry="4" width="150" height="15"/>
                    </ContentLoader>
                </Col>
                <Col md={6}>
                    <ContentLoader
                        height={90}
                        width={400}
                        speed={1}
                        primaryColor="#EDEEF3"
                        secondaryColor="#f3f3f3"
                    >
                        <rect x="0" y="0" rx="4" ry="4" width="400" height="11"/>
                        <rect x="0" y="17" rx="4" ry="4" width="380" height="11"/>
                        <rect x="0" y="34" rx="4" ry="4" width="390" height="11"/>
                        <rect x="0" y="51" rx="4" ry="4" width="250" height="11"/>

                        <rect x="0" y="80" rx="4" ry="4" width="120" height="11"/>
                    </ContentLoader>
                </Col>
            </Row>
        </div>
    );
};

ScorePlaceholder.propTypes = {
    title: PropTypes.string,
    isColumnLayout: PropTypes.bool
};

ScorePlaceholder.defaultProps = {
    isColumnLayout: false
};

export default ScorePlaceholder;
