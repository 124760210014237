import ContentLoader from 'react-content-loader';
import { Col } from 'react-bootstrap';

const CardPlaceholder = () => {
    return (
        <Col xs={12} sm={6} md={4} xl={3} className='storeProductPlaceholder'>
            <ContentLoader
                height={80}
                width={50}
                speed={1}
                backgroundColor="#EDEEF3"
                foregroundColor="#f3f3f3"
            >
                <rect x="0" y="0" rx="1" ry="1" width="700" height="1200"/>
            </ContentLoader>
        </Col>
    );
};

export default CardPlaceholder;
