import { Children, cloneElement, useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { NotificationContext } from '@mdc/ui';
import { useTranslation } from 'react-i18next';

import UploadPreloader from '../upload-preloader/UploadPreloader';
import useWindowDropZone from './UseWindowDropZone';
import { NOTIFICATION_TYPES, UPLOAD_PRELOADER_STATES } from '@mdc/constants';
import { withUploadPreloaderProvider } from '@mdc/services';

export const DropZoneContext = createContext();

const DropZoneProvider = (props) => {
    const [onFileDropSubscribers, setOnFileDropSubscribers] = useState([]);
    const { notify } = useContext(NotificationContext);
    const { ready, t } = useTranslation();

    const addSubscriber = (callback, id) => {
        const subscribers = id ? onFileDropSubscribers.filter((sub) => sub.id !== id) : onFileDropSubscribers;
        setOnFileDropSubscribers([...subscribers, { callback, id }]);
    };

    const isInProgress = () => props.uploadProvider.getPreloaderState() === UPLOAD_PRELOADER_STATES.IN_PROGRESS;

    useWindowDropZone({
        onDrop: (event) => {
            if (isInProgress()) {
                return;
            }

            let length = event?.dataTransfer?.items?.length;
            for (let i = 0; i < length; i++) {
                let entry = event.dataTransfer?.items[i]?.webkitGetAsEntry();
                if (entry && entry.isDirectory) {
                    notify({
                        message: t('We don’t support directory scanning, please upload a valid file instead'),
                        type: NOTIFICATION_TYPES.CRITICAL
                    });
                    props.uploadProvider.setPreloaderState(UPLOAD_PRELOADER_STATES.INACTIVE);
                    return;
                }
            }

            const { files } = event?.dataTransfer ?? {};
            props.uploadProvider.setPreloaderState(UPLOAD_PRELOADER_STATES.INACTIVE);
            if (!files?.length) {
                return;
            }
            onFileDropSubscribers.forEach((sub) => {
                sub.callback(files);
            });
        },
        onDragEnter: () => {
            if (isInProgress()) {
                return;
            }

            props.uploadProvider.setPreloaderState(UPLOAD_PRELOADER_STATES.WAITING);
        },
        onDragLeave: () => {
            if (isInProgress()) {
                return;
            }

            props.uploadProvider.setPreloaderState(UPLOAD_PRELOADER_STATES.INACTIVE);
        }
    });

    if (!ready) {
        return null;
    }

    return (
        <DropZoneContext.Provider value={{
            onFileDrop: addSubscriber
        }}
        >
            {props.children}
            <UploadPreloader state={props.uploadProvider.getPreloaderState()} />
        </DropZoneContext.Provider>
    );
};

DropZoneProvider.propTypes = {
    children: PropTypes.node.isRequired,
    uploadProvider: PropTypes.any.isRequired,
};

export default withUploadPreloaderProvider(DropZoneProvider);

export const DropZoneConsumer = (props) => {
    const extraProps = {};
    /* eslint-disable no-unused-vars */
    for (let key in props) {
        if (key !== 'children') {
            extraProps[key] = props[key];
        }
    }
    /* eslint-enable no-unused-vars */
    return (
        <DropZoneContext.Consumer>
            {({ onFileDrop }) => {
                const children = props.children;
                return Children.map(children, (child) => {
                    return cloneElement(child, { ...extraProps, onFileDrop });
                });
            }}
        </DropZoneContext.Consumer>
    );
};

DropZoneConsumer.propTypes = {
    children: PropTypes.node.isRequired,
};
