import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import './TechCard.scss';

const TechCard = ({ iconClass, text, smallText, href }) => {

    const cardDom = <Row className="align-items-center">
        <Col className="d-flex align-items-center">
            <i className={`icon ${iconClass}`}/>
            <div>
                <p>
                    {text}
                </p>
                {smallText && <p>
                    <small>
                        {smallText}
                    </small>
                </p>}
            </div>
        </Col>
    </Row>;

    return (
        <div className='techCardBox'>
            <OutboundLink
                href={href}
                target='_blank'
                rel='noopener noreferrer'
                className='techCard'
                type='button'
            >
                {cardDom}
            </OutboundLink>
        </div>
    );
};

TechCard.propTypes = {
    href: PropTypes.string.isRequired,
    iconClass: PropTypes.string.isRequired,
    onCardClick: PropTypes.func,
    smallText: PropTypes.string,
    text: PropTypes.string.isRequired,
};

export default TechCard;
