import { useMemo } from 'react';

export const PDF_HEADER_VALUES_DLP = (t) => [[
    { content: t('Hit'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
    { content: t('Location'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
    { content: t('Certainty'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
    { content: t('Redacted'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
    { content: t('Before'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
    { content: t('After'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
]];

export const usePdfCCNDataDLP = (dlpResults) => {
    return useMemo(() => {
        if (!dlpResults) {
            return;
        }
        const headRowsCCNDLP = dlpResults?.ccn?.hits.map(hit => {
            return {
                hit: hit.hit,
                location: hit.location
            };
        });

        const bodyRowsCCNDLP = dlpResults?.ccn?.hits.map((hit) => {
            return {
                id: hit?.hit,
                'Location': hit?.location,
                'Certainty': hit?.certainty,
                'Redacted': hit?.tryRedact ? 'Redacted' : 'Not Redacted',
                'Before': hit?.before || '-',
                'After': hit?.after || '-',
            };
        }).flat(1);
        return { headRowsCCNDLP: headRowsCCNDLP, bodyRowsCCNDLP: bodyRowsCCNDLP };
    }, [dlpResults]);
};


export const usePdfSSNDataDLP = (dlpResults) => {
    return useMemo(() => {
        if (!dlpResults) {
            return;
        }
        const headRowsSSNDLP = dlpResults?.ssn?.hits.map(hit => {
            return {
                hit: hit.hit,
                location: hit.location
            };
        });

        const bodyRowsSSNDLP = dlpResults?.ssn?.hits.map((hit) => {
            return {
                id: hit?.hit,
                'Location': hit?.location,
                'Certainty': hit?.certainty,
                'Redacted': hit?.tryRedact ? 'Redacted' : 'Not Redacted',
                'Before': hit?.before || '-',
                'After': hit?.after || '-',
            };
        }).flat(1);
        return { headRowsSSNDLP: headRowsSSNDLP, bodyRowsSSNDLP: bodyRowsSSNDLP };
    }, [dlpResults]);
};

export const usePdfIPV4DataDLP = (dlpResults) => {
    return useMemo(() => {
        if (!dlpResults) {
            return;
        }
        const headRowsIPV4DLP = dlpResults?.ipv4?.hits.map(hit => {
            return {
                hit: hit.hit,
                location: hit.location
            };
        });
        const bodyRowsIPV4DLP = dlpResults?.ipv4?.hits.map((hit) => {
            return {
                id: hit?.hit,
                'Location': hit.location,
                'Certainty': hit.certainty,
                'Redacted': hit.tryRedact ? 'Redacted' : 'Not Redacted',
                'Before': hit.before || '-',
                'After': hit.after || '-',
            };
        });

        return { headRowsIPV4DLP: headRowsIPV4DLP, bodyRowsIPV4DLP: bodyRowsIPV4DLP };
    }, [dlpResults]);
};

export const usePdfCIDRDataDLP = (dlpResults) => {
    return useMemo(() => {
        if (!dlpResults) {
            return;
        }
        const headRowsCIDRDLP = dlpResults?.cidr?.hits.map(hit => {
            return {
                hit: hit.hit,
                location: hit.location
            };
        });

        const bodyRowsCIDRDLP = dlpResults?.cidr?.hits.map((hit) => {
            return {
                id: hit?.hit,
                'Location': hit?.location,
                'Certainty': hit?.certainty,
                'Redacted': hit?.tryRedact ? 'Redacted' : 'Not Redacted',
                'Before': hit?.before || '-',
                'After': hit?.after || '-',
            };
        }).flat(1);
        return { headRowsCIDRDLP: headRowsCIDRDLP, bodyRowsCIDRDLP: bodyRowsCIDRDLP };
    }, [dlpResults]);
};

export const DLPCCNPdf = (pdfCCNDataDLP) => {
    const bodyCCNDLP = pdfCCNDataDLP?.bodyRowsCCNDLP?.reduce((acc, item, index) => {
        if (item?.id === pdfCCNDataDLP?.headRowsCCNDLP[index]?.hit) {
            // eslint-disable-next-line no-unused-vars
            acc.push(Object.values(item));
        }

        return acc;
    }, []);

    return bodyCCNDLP;
};

export const DLPSSNPdf = (pdfSSNDataDLP) => {
    const bodySSNDLP = pdfSSNDataDLP?.bodyRowsSSNDLP?.reduce((acc, item, index) => {
        if (item?.id === pdfSSNDataDLP?.headRowsSSNDLP[index]?.hit) {
            // eslint-disable-next-line no-unused-vars
            acc.push(Object.values(item));
        }

        return acc;
    }, []);

    return bodySSNDLP;
};

export const DLPIPV4Pdf = (pdfIPV4DataDLP) => {
    const bodyIPV4DLP = pdfIPV4DataDLP?.bodyRowsIPV4DLP?.reduce((acc, item, index) => {
        if (item?.id === pdfIPV4DataDLP.headRowsIPV4DLP[index].hit) {
            // eslint-disable-next-line no-unused-vars
            acc.push(Object.values(item));
        }

        return acc;
    }, []);

    return bodyIPV4DLP;
};

export const DLPCIDRPdf = (pdfCIDRDataDLP) => {
    const bodyCIDRDLP = pdfCIDRDataDLP?.bodyRowsCIDRDLP?.reduce((acc, item, index) => {
        if (item?.id === pdfCIDRDataDLP?.headRowsCIDRDLP[index]?.hit) {
            // eslint-disable-next-line no-unused-vars
            acc.push(Object.values(item));
        }

        return acc;
    }, []);

    return bodyCIDRDLP;
};
