import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { UPLOAD_PRELOADER_STATES } from '@mdc/constants';
import { withUploadPreloaderProvider } from '@mdc/services';

import './UploadPreloader.scss';

const UploadPreloader = (props) => {
    const { uploadProvider } = props;
    const [targetElem, setTargetElem] = useState(null);

    useEffect(() => {
        if (!targetElem) {
            setTargetElem(document.getElementsByTagName('body')[0]);
        }

    }, [targetElem]);

    if (!targetElem || uploadProvider.getPreloaderState() === UPLOAD_PRELOADER_STATES.INACTIVE) {
        return null;
    }

    const contentClasses = classnames({
        content: true,
        'withText': uploadProvider.getPreloaderText() && uploadProvider.getPreloaderText().length > 0,
        'waiting': uploadProvider.getPreloaderState() === UPLOAD_PRELOADER_STATES.WAITING,
        'inProgress': uploadProvider.getPreloaderState() === UPLOAD_PRELOADER_STATES.IN_PROGRESS
    });
    return createPortal((<div className='uploadPreloader'>
        <div className={contentClasses}>
            <span>
                {uploadProvider.getPreloaderText()}
            </span>
        </div>
    </div>), targetElem);
};

UploadPreloader.defaultProps = {
    state: UPLOAD_PRELOADER_STATES.INACTIVE
};

UploadPreloader.propTypes = {
    state: PropTypes.string,
    text: PropTypes.string
};

export default withUploadPreloaderProvider(UploadPreloader);
