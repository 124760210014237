import { i18n } from '@mdc/services';

export default [
    {
        translationKey: i18n.t('Multiscanning'),
        param: 'rule',
        value: 'multiscan',
    },

    {
        translationKey: i18n.t('Unarchiving'),
        param: 'rule',
        value: 'unarchive'
    },
    {
        translationKey: i18n.t('Deep CDR'),
        param: 'rule',
        value: 'cdr'
    },
    {
        translationKey: i18n.t('Sandbox'),
        param: 'sandbox',
        value: 'sandbox',
    },
    {
        translationKey: i18n.t('Hash Lookup'),
        param: 'hash-lookup',
        value: 'true',
        disabled: 'true'
    },
    {
        translationKey: i18n.t('Private processing'),
        param: 'privateprocessing',
        value: '1',
        paidOnly: 'true',
        paidDescription: i18n.t('Private processing is only available for paid users')
    },
    {
        translationKey: i18n.t('Archive password'),
        param: 'filepassword',
        value: '',
        pwdProtectedOnly: 'true'
    },
    {
        translationKey: i18n.t('Proactive DLP'),
        param: 'rule',
        value: 'dlp'
    }
];
