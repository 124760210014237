import { WrapRootElement } from './wrap-root-element';
import { withAssetPrefix } from 'gatsby-link';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { i18nConfig } from '@mdc/constants';
import { initReactI18next } from 'react-i18next';
import {
    AngularRedirectService,
    Authenticator,
    logService,
    i18nUtility
} from '@mdc/services';
import '@mdc/styles/node_modules/bootstrap/scss/bootstrap.scss';

const i18nLocalConfig = {
    saveMissing: false,
    backend: {
        loadPath: withAssetPrefix('/locale/{{lng}}/{{ns}}.json')
    }
};

const i18nConfiguration = Object.assign({}, i18nConfig, i18nLocalConfig);
const i18nPromise = i18n
    .use(initReactI18next)
    .init(i18nConfiguration);

export const wrapRootElement = ({ element }) => {
    return WrapRootElement({ element }, i18n);
};

export const replaceHydrateFunction = () => {
    const DOMelement = document.getElementById('___mdc');
    return async (element, container, callback) => {
        await i18nPromise;
        ReactDOM.hydrate(element, DOMelement, callback);
    };
};

export const onRouteUpdate = ({ location }) => {
    const redirectUrl = AngularRedirectService.getRedirectUrl(location.href);
    if (redirectUrl) {
        window && window.location.replace(redirectUrl);
    }

    // Replace URL to match with language
    i18nUtility.replaceHistory();
};

export const onClientEntry = () => {
    const currentUrl = decodeURIComponent(window.location.href);
    const redirectUrl = AngularRedirectService.getRedirectUrl(currentUrl);
    if (redirectUrl) {
        window && window.location.replace(redirectUrl);
    }
};

export const onInitialClientRender = () => {
    Authenticator.init();
    const gatsbyWrapperDom = document.getElementById('gatsby-focus-wrapper');
    if (gatsbyWrapperDom) {
        gatsbyWrapperDom.setAttribute('id', 'mdc-focus-wrapper');
    }
};

export const registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        const SW_URL = '/sw.js';
        window.addEventListener('load', async () => {
            try {
                await navigator.serviceWorker.register(SW_URL);
            } catch (error) {
                logService.error(error);
            }
        });
    }
};
