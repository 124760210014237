import { useMemo } from 'react';

export const PDF_HEADER_VALUES_CDR = (t) => [[
    { content: t('Object'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
    { content: t('Action'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
    { content: t('Count'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
]];

export const usePdfDataCDR = (sanitizationResultsDetails) => {
    return useMemo(() => {
        if (!sanitizationResultsDetails || !Array.isArray(sanitizationResultsDetails)) {
            return;
        }
        const headRowsCDR = sanitizationResultsDetails.map((file) => (file.object_name));

        const bodyRowsCDR = Object.entries(sanitizationResultsDetails).map((value, index) => {
            const count = value[1].count === 0 ? '-' : value[1].count;
            return {
                id: headRowsCDR[index],
                'Action': value[1].action,
                'Count': count
            };
        }).flat(1);
        return { headRowsCDR: headRowsCDR, bodyRowsCDR: bodyRowsCDR };
    }, [sanitizationResultsDetails]);
};

export const CDRPdfData = (pdfDataCDR) => {
    const bodyCDR = pdfDataCDR?.headRowsCDR.map((header) => {
        const headRowEntriesLength = pdfDataCDR?.bodyRowsCDR.filter((bodyRowTemp) => bodyRowTemp.id === header).length;
        const pdfBodyRowsCDR = pdfDataCDR?.bodyRowsCDR.reduce((acc, item) => {
            if (item.id === header) {
                // eslint-disable-next-line no-unused-vars
                const { id, ...itemProcessed } = item;
                acc.push(Object.values(itemProcessed));
            }

            return acc;
        }, []);

        if (pdfBodyRowsCDR.length === headRowEntriesLength) {
            const unshiftObject = {
                rowSpan: headRowEntriesLength,
                content: header,
                styles: {
                    valign: 'middle',
                }
            };

            if (headRowEntriesLength !== 0) {
                pdfBodyRowsCDR[0].unshift(unshiftObject);
            } else {
                pdfBodyRowsCDR.unshift([unshiftObject]);
            }
        }

        return pdfBodyRowsCDR;
    }).flat(1);
    return bodyCDR;
};
