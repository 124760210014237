import { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@mdc/ui';
import { Button, Col, Row } from 'react-bootstrap';

import './NetPromoterScore.scss';

const NetPromoterScore = ({ title, show, minScore, maxScore, negativeText, positiveText, placeholderText, onClosed, onSubmit, feedbackTitle }) => {
    const { t, ready } = useTranslation();

    const [selectedScore, setSelectedScore] = useState('');
    const [textInput, setTextInput] = useState('');
    const inputRef = useRef(null);

    const onScoreClose = () => {
        if (onClosed) {
            onClosed({ time: new Date() });
        }
        setTextInput('');
        setSelectedScore('');
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onScoreClose();
        }
    };

    const onScoreSubmit = useMemo(() => () => {
        if (onSubmit) {
            onSubmit({ time: new Date(), score: selectedScore, text: textInput });
        }
        setTextInput('');
        setSelectedScore('');
    }, [onSubmit, selectedScore, textInput]);

    const scoreDom = useMemo(() => {
        if (selectedScore === '') {
            let keyCounter = 0;
            return <>
                <Row>
                    <Col xs={12} className='scoreWrapper'>
                        <div className='scoreButtonWrapper'>
                            {Array(maxScore - minScore + 1).fill('').map((_, index) => <Button
                                className='scoreButton'
                                key={keyCounter++}
                                onClick={() => setSelectedScore(index + minScore)}>
                                {minScore + index}
                            </Button>)}
                            {negativeText && positiveText && <Row className="explainWrapper">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <span>{`${minScore} - ${negativeText}`}</span>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <span>{`${maxScore} - ${positiveText}`}</span>
                                </Col>
                            </Row>}
                        </div>
                    </Col>
                </Row>
            </>;
        }

        return <Row className='inputWrapper'>
            <Col
                className="d-flex"
                md={9}
                sm={8}
                xs={12}>
                <TextInput onChange={setTextInput} initialValue={textInput} placeholder={placeholderText} ref={inputRef} />
            </Col>
            <Col
                className="d-flex justify-content-sm-start justify-content-center"
                md={1}
                sm={2}
                xs={6}
            >
                <Button
                    className="scoreButton"
                    onClick={() => setSelectedScore('')}
                >
                    {selectedScore}
                </Button>
            </Col>
            <Col
                className="d-flex justify-content-center"
                md={2}
                sm={2}
                xs={6}
            >
                <Button
                    className="buttonSubmit"
                    onClick={onScoreSubmit}
                    variant="outline-primary"
                >
                    {t('Submit')}
                </Button>
            </Col>
        </Row>;
    }, [selectedScore, onScoreSubmit]);

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    }, [scoreDom]);

    if (!ready || !show) {
        return null;
    }

    return (
        <div className="netPromoterScoreWrapper">
            <Container className="netPromoterScoreBanner">
                <Row>
                    <Col className="text d-flex justify-content-center">
                        <span className="align-self-center">
                            {selectedScore === '' ? title : feedbackTitle}
                        </span>
                    </Col>
                </Row>
                {scoreDom}
            </Container>
            <button className='closeButton' onClick={onScoreClose} tabIndex="0" onKeyDown={handleKeyDown}>
                <i className='icon-close' />
            </button>
        </div>
    );
};

NetPromoterScore.defaultProps = {
    show: false,
    minScore: 0,
    maxScore: 10,
    placeholderText: ''
};

NetPromoterScore.propTypes = {
    title: PropTypes.string.isRequired,
    show: PropTypes.bool,
    minScore: PropTypes.number,
    maxScore: PropTypes.number,
    negativeText: PropTypes.string,
    positiveText: PropTypes.string,
    placeholderText: PropTypes.string,
    onClosed: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    feedbackTitle: PropTypes.string.isRequired
};


export default NetPromoterScore;
