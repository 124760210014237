import PropTypes from 'prop-types';

import './ScoreMoreDetail.scss';

const ScoreMoreDetail = ({ detailText, detailLink }) => {

    return <div className='scoreMoreDetail'>
        <p>
            {detailText}
        </p>
        {typeof detailLink === 'string' ? <span className='detailLink' dangerouslySetInnerHTML={{ __html: detailLink }}/> : detailLink}
    </div>;
};

ScoreMoreDetail.propTypes = {
    detailText: PropTypes.string,
    detailLink: PropTypes.string,
    redirectLink: PropTypes.any
};

export default ScoreMoreDetail;
