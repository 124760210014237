import * as log from 'loglevel';
import { localStorage } from '@mdc/services';

const ENVIRONMENT = 'prod';
const LOG_LEVEL_KEY = 'loglevel';

const LOG_LEVELS = ['trace', 'debug', 'info', 'warn', 'error', 'silent'];

class LogService {
    constructor() {
        let logLevel = localStorage?.getItem(LOG_LEVEL_KEY);
        if (ENVIRONMENT !== 'prod' && !logLevel) {
            // Set default local storage value for non-product env
            localStorage?.setItem(LOG_LEVEL_KEY, 'info');
        }

        const logType = ENVIRONMENT !== 'prod' ? 'info' : 'silent';

        logLevel = LOG_LEVELS.includes(logLevel) ? logLevel : logType;
        log.setLevel(logLevel, false);
    }

    log(...args) {
        log.info(...args);
    }

    warn(...args) {
        log.warn(...args);
    }

    error(...args) {
        log.error(...args);
    }
}

export const logService = new LogService();
