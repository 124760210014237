import { Fragment } from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';

const ButtonsRowPlaceholder = (props) => {
    return (
        <ContentLoader
            height={48}
            width={1020}
            speed={1}
            primaryColor="#EDEEF3"
            secondaryColor="#f3f3f3"
            {...props}
        >
            <rect x="0" y="0" rx="6" ry="6" width={240} height="32" />
            <rect x="260" y="0" rx="6" ry="6" width={240} height="32" />
            <rect x="520" y="0" rx="6" ry="6" width={240} height="32" />
            <rect x="780" y="0" rx="6" ry="6" width={240} height="32" />

            <rect x="0" y="58" rx="6" ry="6" width="1060" height=".3" />
        </ContentLoader>
    );
};

const ButtonsPlaceholder = (props) => {
    const { numberOfRows } = props || 10;
    let keyCounter = 0;
    return (
        <Fragment>
            {Array(numberOfRows)
                .fill('')
                .map((e, i) => (
                    <ButtonsRowPlaceholder key={keyCounter++} style={{ opacity: Number(2 / i).toFixed(1) }} />
                ))}
        </Fragment>
    );
};

ButtonsPlaceholder.propTypes = {
    numberOfRows: PropTypes.number,
};

export default ButtonsPlaceholder;
