import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import SecurityTechnologies from './security-technologies/SecurityTechnologies';
import Resources from './resources/Resources';

import './MainMenu.scss';

const MainMenu = ({ isMenuOpen, gatsbyLinks, headerRef, outSideClick }) => {
    const { t, ready } = useTranslation();
    const menuRef = useRef(null);

    const handleMouseDown = (event) => {
        if (menuRef && !menuRef.current.contains(event.target) && headerRef && !headerRef.current.contains(event.target) && outSideClick) {
            outSideClick();
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            window.addEventListener('mousedown', handleMouseDown);
        }

        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
        };
    }, [isMenuOpen]);

    const menuClass = classNames({
        'MainMenu': true,
        isOpened: isMenuOpen,
    });

    if (!ready) {
        return null;
    }

    return (
        <div className={menuClass} ref={menuRef}>
            <div className='MainMenuContent'>
                <Container>
                    <Row>
                        <Col
                            lg="3"
                            md="12"
                            xs="12"
                        >
                            <p className="trustNoText h3Mask" dangerouslySetInnerHTML={{ __html: t('Trust No File.<br/>Trust No Device') }}/>
                            <p className="guideText">
                                {t('Simply submit suspicious files to MetaDefender Cloud for analysis. A comprehensive report is created to inform you about the contents of the file')}
                            </p>
                        </Col>
                        <Col
                            lg="6"
                            md="12"
                            xs="12"
                        >
                            <SecurityTechnologies/>
                        </Col>
                        <Col
                            className="d-none d-lg-block"
                            lg="3"
                            md="12"
                            xs="12"
                        >
                            <Resources gatsbyLinks={gatsbyLinks}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

MainMenu.propTypes = {
    isMenuOpen: PropTypes.bool.isRequired,
    gatsbyLinks: PropTypes.object.isRequired,
    headerRef: PropTypes.object.isRequired,
    outSideClick: PropTypes.func
};

export default MainMenu;
