import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Navbar, Container } from 'react-bootstrap';
import UserMenu from './user-menu/UserMenu';
import { MainMenu } from '@mdc/ui';
import { throttle } from 'lodash';
import { Authenticator } from '@mdc/services';

import './Header.scss';

const Header = (props) => {
    const [showGlass, setShowGlass] = useState(false);
    const useGlassEffect = props.useGlassEffect;
    const glassBackground = useGlassEffect ? <div className='glass-bg'></div> : null;
    const [showMainMenu, setShowMainMenu] = useState(false);
    const headerRef = useRef(null);

    const onShowMenuClick = () => {
        setShowMainMenu((state) => !state);
    };

    const handleScroll = throttle(function () {
        const shouldShowGlass = useGlassEffect && window.scrollY > 0;
        setShowGlass(shouldShowGlass);
    }, 150, { leading: true });

    useEffect(() => {
        if (useGlassEffect) {
            window.addEventListener('scroll', handleScroll);
        }

        return (() => {
            if (useGlassEffect) {
                window.removeEventListener('scroll', handleScroll);
            }
        });
    }, [useGlassEffect]);

    useEffect(() => {
        // Check if users already scrolled before refreshing/revisiting the page
        // and display the relevant menu design
        if (window) {
            if (window.scrollY > 0) {
                setShowGlass(true);
            }
        }
    }, []);

    const headerClasses = classnames({
        showGlass,
        sticky: showGlass,
        showMainMenu
    }, 'mainHeader');

    const justifyClasses = classnames({
        'justify-content-end': !useGlassEffect,
        'justify-content-center': useGlassEffect,
    }, 'user-menu-content');

    return (
        <>
            <header className={headerClasses} ref={headerRef}>
                {glassBackground}
                <div className='night-bg'></div>
                <Container>
                    <Navbar className='align-items-center pl-0 pr-0 main'>
                        <Navbar.Brand>
                            <a className='logo' href='/' aria-label='OPSWAT MetaDefender Cloud' />
                        </Navbar.Brand>
                        <Navbar.Collapse className={justifyClasses}>
                            {props.children}
                            <UserMenu
                                className='user-menu'
                                onLogoutClick={() => (async () => Authenticator.logout())()}
                                onShowMenuClick={onShowMenuClick}
                                isMenuOpen={showMainMenu}
                                onSignIn={props.onSignIn}
                                onLicensingClick={props.onLicensingClick}
                            ></UserMenu>
                        </Navbar.Collapse>
                    </Navbar>
                </Container >
                <div className='headerMainMenu'>
                    <MainMenu
                        isMenuOpen={showMainMenu}
                        gatsbyLinks={props.gatsbyLinks}
                        headerRef={headerRef}
                        outSideClick={() => setShowMainMenu(false)} />
                </div>
            </header ></>
    );
};

Header.defaultProps = {
    useGlassEffect: false,
    onLicensingClick: () => window.history.replaceState('', '', '/licensing')
};

Header.propTypes = {
    children: PropTypes.node,
    onLicensingClick: PropTypes.func.isRequired,
    onSignIn: PropTypes.func,
    useGlassEffect: PropTypes.bool,
    gatsbyLinks: PropTypes.object.isRequired
};

export default Header;
