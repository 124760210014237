import BaseService from '../BaseService';

class ReportService {
    constructor(instance) {
        this.instance = instance;
        this.base = new BaseService(this.instance);
    }

    getAmDetection = (params, headers) => {
        return this.base.get(`/report/am-detection/${params.period}/${params.hashes}`, headers);
    }

    getMdDetection = (params, headers) => {
        return this.base.get(`/report/md-detection/${params.period}/${params.hashes}`, headers);
    }

    getMarketshare = (params, headers) => {
        return this.base.get(`/report/marketshare/${params.date}`, headers);
    }

    getOutbreak = (params, headers) => {
        return this.base.get(`/report/outbreak/${params.type}`, headers, null, this.base.defaultCache());
    }

    getOutbreakList = (params, headers) => {
        return this.base.get(`/report/outbreak-list/${params.type}`, headers, null, this.base.defaultCache());
    }

    getSanitization = (headers) => {
        return this.base.get('/report/sanitization/', headers, null, this.base.defaultCache());
    }

    getThreatLocation = (params, headers) => {
        return this.base.get(`/report/threat-location/${params.id}`, headers, null, this.base.defaultCache());
    }

    getThreatLocationCategories = (headers) => {
        return this.base.get('/report/threat-location/categories', headers, null, this.base.defaultCache());
    }
}

export default ReportService;
