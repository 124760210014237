class AngularRedirectService {

    getAngularRoute(url) {
        const a = document.createElement('a');
        a.href = url;

        const hash = a.hash;
        const acceptedPathNamesResults = ['/results', '/jp/results', '/en/results'];

        if (!hash.startsWith('#!')) {
            return;
        }

        let angularPath;
        let angularSpa;
        let hashSegments;

        if (acceptedPathNamesResults.includes(a.pathname) && hash.startsWith('#!/')) {
            angularPath = '/';
            angularSpa = 'results';
        }


        angularPath = angularPath || a.pathname;
        hashSegments = hash.split('/');
        if (hashSegments[0] === '#!') {
            hashSegments.shift(); //remove #!
        } else {
            hashSegments[0] = hashSegments[0].replace('#!', '');
        }

        angularSpa = angularSpa || hashSegments.shift();
        const [angularRoute, queryParams] = hashSegments.join('/').split('?');

        return [angularPath, angularSpa, angularRoute, queryParams];
    }

    getRedirectUrl(url) {
        const angularRouteSegments = this.getAngularRoute(url);

        if (!angularRouteSegments) {
            return;
        }

        let [angularPath, angularSpa, angularRoute, queryParams] = angularRouteSegments;

        if (angularSpa === 'results') {
            angularRoute = angularRoute.replace('/analysis', '');
            const withParams = queryParams ? `?${queryParams}` : '';

            return `/results/${angularRoute}${withParams}`;
        }

        if (angularSpa === 'leaderboard') {
            let redirectUrl = '';

            if (angularRoute === '/voters') {
                redirectUrl = `/leaderboard/top-voters?${queryParams}`;
            } else if (angularRoute === '') {
                redirectUrl = `/leaderboard?${queryParams}`;
            }

            return redirectUrl;
        }

        if (angularPath === '/vulnerabilities') {
            return `/vulnerabilities/${angularSpa || ''}`;
        }
    }
}

export default new AngularRedirectService();
