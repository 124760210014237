/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

import './FileType.scss';

const FileType = ({ title, icon, data }) => {
    const totalFiles = useMemo(() => {
        if (!data) {
            return 0;
        }

        return data.reduce((total, current) => total + (current?.doc_count || 0), 0);
    }, [data]);

    const dataDom = useMemo(() => {
        if (!data) {
            const numberOfRows = 5;
            let keyCounter = 0;

            return Array(numberOfRows).fill('').map(() => (
                <div className='fileTypeContent d-flex flex-row align-items-center mb-3' key={keyCounter++}>
                    <ContentLoader
                        height={8}
                        width={'100%'}
                        speed={1}
                        viewBox={'0 0 100 8'}
                    >
                        <rect x={0} y='0' rx='2' ry='2' width={'38%'} height={8}/>
                        <rect x={'40%'} y='0' rx='2' ry='2' width={'38%'} height={8}/>
                        <rect x={'80%'} y='0' rx='2' ry='2' width={'10%'} height={8}/>
                    </ContentLoader>
                </div>
            ));
        }

        const max = Math.max.apply(Math, data.map((item) => item?.doc_count));

        return <div className='d-flex flex-row align-items-center'>
            <div className='fileTypeTitle'>
                {data.map((item) => <p className='mb-2' key={item?.id} title={item.key}>{item.key}</p>)}
            </div>
            <div className='horizontalBar'>
                {data.map((item) => <div className='horizontalBarChart mb-2' key={item?.id}>
                    <div className='fill' style={{ width: `${(item?.doc_count / max) * 100}%` }}/>
                </div>)}
            </div>
            <div>
                {data.map((item) => <div className='valueChart mb-2' key={item?.id}>
                    {item?.doc_count}
                </div>)}
            </div>
        </div>;
    }, [data]);

    return <Col xs={12} md={6} className='fileTypeWrapper mt-3'>
        <p className='h6Mask'>
            <i className={`icon ${icon} mr-1`}/>
            {title}{' ('}{totalFiles}{')'}
        </p>
        {dataDom}
    </Col>;
};

FileType.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    data: PropTypes.array
};

export default FileType;
