import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './ConfirmModal.scss';

const ConfirmModal = ({ show, onAccept, onCancel, title, body, acceptText, cancelText, acceptVariant, cancelVariant, size, className }) => {
    const onAcceptClicked = () => {
        onAccept && onAccept();
    };

    return (
        <Modal
            show={show}
            size={size}
            onHide={onCancel}
            dialogClassName={className}
            animation={false}
            centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant={acceptVariant}
                    onClick={onAcceptClicked}
                >
                    {acceptText}
                </Button>
                {cancelText && <Button
                    variant={cancelVariant}
                    onClick={onCancel}
                >
                    {cancelText}
                </Button>}
            </Modal.Footer>
        </Modal>
    );
};

ConfirmModal.defaultProps = {
    size: 'md',
    acceptVariant: 'outline-danger',
    cancelVariant: 'outline-secondary',
    confirmModal: 'confirmModal'
};

ConfirmModal.propTypes = {
    acceptText: PropTypes.string.isRequired,
    acceptVariant: PropTypes.string,
    body: PropTypes.any.isRequired,
    cancelText: PropTypes.string,
    cancelVariant: PropTypes.string,
    className: PropTypes.string,
    onAccept: PropTypes.func,
    onCancel: PropTypes.func,
    show: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.any
};

export default ConfirmModal;
