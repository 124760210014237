import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card as BootstrapCard } from 'react-bootstrap';

import './Card.scss';

const BG_COLORS = {
    default: 'default',
    dark: 'dark'
};

const Card = ({ icon, title, btnText, btnURL, content, bgColor = BG_COLORS.default }) => {
    const renderAnchorTag = btnText && btnURL ? (
        <a
            className="anchor"
            href={btnURL}
        >
            {btnText}
            <i className="icon-arrow-right card-btn-icon" />
        </a>
    ) : (
        null
    );

    const cardClasses = classNames({
        default: bgColor === BG_COLORS.default,
        dark: bgColor === BG_COLORS.dark,
    });

    return (
        <>
            <BootstrapCard className={cardClasses}>
                <i className={`icon-${icon} card-icon`} />

                <BootstrapCard.Body>
                    <BootstrapCard.Title className="card-title">
                        {title}
                    </BootstrapCard.Title>
                    <div
                        className="card-content"
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </BootstrapCard.Body>
                <BootstrapCard.Footer>
                    {renderAnchorTag}
                </BootstrapCard.Footer>
            </BootstrapCard>
        </>
    );
};

Card.propTypes = {
    bgColor: PropTypes.string,
    btnText: PropTypes.string,
    btnURL: PropTypes.string,
    content: PropTypes.node.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default Card;
