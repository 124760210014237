const hasWindow = (function () {
    return typeof window !== 'undefined';
})();

const hasLocalStorage = (function () {
    if (!hasWindow) {
        return false;
    }
    return typeof localStorage !== 'undefined';
})();

const getRandomInt = (maxValue) => {
    if (typeof window !== 'undefined' && window.crypto) {
        const randomBuffer = new Uint32Array(1);
        window && window?.crypto.getRandomValues(randomBuffer);
        const randomNumber = randomBuffer[0] / (0xFFFFFFFF + 1);
        return Math.floor(randomNumber * maxValue);
    } else {
        return Math.floor(Math.random() * maxValue);
    }
};

const getRandomID = (IDLength) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < IDLength; i++) {
        const randomIndex = getRandomInt(charactersLength);
        result += characters.charAt(randomIndex);
    }
    return result;
};

export { hasWindow, hasLocalStorage, getRandomID };
