import { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../common/icon-button/IconButton';
import AdvancedOptionsProvider from './AdvancedOptionsProvider';
import Popup from '../../common/popup/Popup';
import ADVANCED_OPTIONS from './Options';
import { UserContext } from '@mdc/ui';

import './AdvancedOptionsPopup.scss';

const AdvancedOptionsPopup = ({ options, onOptionClick, isDisabled }) => {
    const userContext = useContext(UserContext);
    const popupTriggerRef = useRef(null);

    const advancedOptionsProvider = new AdvancedOptionsProvider();
    const optionList = advancedOptionsProvider.getOptionsList(options, onOptionClick);

    const optionListOrdered = optionList.reduce((acc, item) => {
        if (item.props.isDisabled === true && item.props.hasSuboptions === false) {
            acc.disabled.push(item);
        } else if (item.props.hasSuboptions === true) {
            acc.withSuboptions.push(item);
        } else {
            acc.enabled.push(item);
        }
        return acc;
    }, { disabled: [], enabled: [], withSuboptions: [] });

    const subOptionsList = advancedOptionsProvider.getSubOptionsList(options, onOptionClick);

    const subOptionsListFragment = subOptionsList.length ? (
        <div className="subOptions">
            {subOptionsList}
        </div>
    ) : null;

    const multiScanOptionFragments = advancedOptionsProvider.getMultiScanOptionsList(options, onOptionClick);
    const paidOptionFragments = advancedOptionsProvider.getPaidOptions(options, onOptionClick, userContext?.isPaidUser, userContext?.data?.enforce_private_scan, userContext?.state === userContext.STATES.LOGGED_IN);
    const passwordOptionFragments = advancedOptionsProvider.getPasswordOptions(options, onOptionClick);

    return (
        <span className="advancedIconButton">
            <IconButton iconClass="icon-settings" isDisabled={isDisabled} ref={popupTriggerRef}/>
            <Popup className="advancedOptionsPopup" isDisabled={isDisabled} triggerRef={popupTriggerRef}>
                <div className="advancedOptionsColumn">
                    {optionListOrdered.disabled}
                    <hr/>
                    {multiScanOptionFragments}
                    {optionListOrdered.enabled}
                    <hr/>
                    {passwordOptionFragments}
                    <hr/>
                    {paidOptionFragments}
                </div>
                <div className="advancedOptionsColumn">

                    {optionListOrdered.withSuboptions}
                    {subOptionsListFragment}
                </div>
            </Popup>
        </span>
    );
};

export default AdvancedOptionsPopup;

AdvancedOptionsPopup.defaultProps = {
    options: ADVANCED_OPTIONS
};

AdvancedOptionsPopup.propTypes = {
    isDisabled: PropTypes.bool,
    onOptionClick: PropTypes.func,
    options: PropTypes.array,
    selectedOptions: PropTypes.object
};
