import { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PlainTablePlaceholder from '../../results/common/plain-table-placeholder/PlainTablePlaceholder';
import classNames  from 'classnames';

import './TextModal.scss';

const TextModal = ({ show, title, body, size, className, onClose }) => {
    const modalClassNames = classNames('textModal', className);
    const modalBodyDom = useMemo(() => {
        if (!body) {
            return <PlainTablePlaceholder numberOfColumns={2} numberOfRows={5}/>;
        }

        return body.map((entry) => {
            return <div key={entry?.key} className='textModalRowWrapper d-flex flex-row'>
                {entry?.key && <div className={`textModalColumnTitle ${entry?.className}`}>{entry?.key}</div>}
                <div className='textModalColumnValue'>
                    {entry?.value}
                </div>
            </div>;
        });
    }, [body]);

    return (
        <Modal
            show={show}
            size={size}
            dialogClassName={modalClassNames}
            animation={false}
            centered={true}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modalBodyDom}
            </Modal.Body>
        </Modal>
    );
};

TextModal.defaultProps = {
    size: 'md'
};

TextModal.propTypes = {
    body: PropTypes.any,
    className: PropTypes.string,
    show: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.any,
    onClose: PropTypes.func
};

export default TextModal;
