import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './SanitizationResultsSection.scss';

const SanitizationResultsSection = (props) => {
    const { sanitized, removed } = props;
    const { t, ready } = useTranslation();

    const sanitizationWithResults = (
        <div className='sanitizationWithResults'>
            <div className='iconSanitization'>
                <div>
                    <i className="icon-doc-add iconDoc" />
                </div>
                <div>
                    <i className="icon-doc-remove iconDoc" />
                </div>
            </div>
            <div className='scoreSanitization'>
                <div>
                    <span className="numberSanitized" data-testid="sanitized-number">
                        {sanitized}
                    </span>
                </div>
                <div>
                    <span className="numberRemoved" data-testid="removed-number">
                        {removed}
                    </span>
                </div>
            </div>
            <div className='textSanitization'>
                <div>
                    <span className="text">
                        {t('items sanitized')}
                    </span>
                </div>
                <div>
                    <span className="text">
                        {t('items removed')}
                    </span>
                </div>
            </div>
            <div className='okSanitization'>
                <div>
                    <i className="icon-ok" />
                </div>
                <div>
                    <i className="icon-ok" />
                </div>
            </div>
        </div>
    );

    if (!ready) {
        return null;
    }

    return (
        <div className='sanitizationResults'>
            <p className='h6Mask'>{t('Deep CDR')}</p>
            {sanitizationWithResults}
        </div>
    );
};

SanitizationResultsSection.propTypes = {
    sanitized: PropTypes.number,
    removed: PropTypes.number,
    sanitizationError: PropTypes.string
};

export default SanitizationResultsSection;
