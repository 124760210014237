import { Row, Col } from 'react-bootstrap';
import LightButton from '../light-button/LightButton';
import DownloadSanitizedButton from '../download-sanitized-button/DownloadsanitizedButton';
import PropTypes from 'prop-types';

import './ScoreReportView.scss';

const ScoreReportView = ({ reportText, buttonContent, onViewClick, sanitizedResult, downloadSanitizedVersion, sanitizedFileLink }) => {

    return <div className='scoreReportView'>
        <p>
            {reportText}
        </p>
        {buttonContent &&
        <Row>
            <Col sm={6} md={6} lg={6} xl={6}> <LightButton onClick={onViewClick}>{buttonContent}</LightButton> </Col>
            {downloadSanitizedVersion && !sanitizedFileLink &&
            <Col sm={6} md={6} lg={6} xl={6} className="downloadButton">
                <DownloadSanitizedButton sanitized={sanitizedResult} downloadSanitized={downloadSanitizedVersion}/>
            </Col>
            }
            { sanitizedFileLink &&
             <Col sm={6} md={6} lg={6} xl={6} className="sanitizedFile">
                 {sanitizedFileLink}
             </Col>

            }
        </Row>}
    </div>;
};

ScoreReportView.propTypes = {
    reportText: PropTypes.string,
    buttonContent: PropTypes.string,
    onViewClick: PropTypes.func,
    sanitizedResult: PropTypes.object,
    downloadSanitizedVersion: PropTypes.func,
    sanitizedFileLink: PropTypes.object
};

export default ScoreReportView;
