/* eslint-disable camelcase */
import { BackendService, logService, localStorage } from '@mdc/services';
import { STRIPE_PUBLIC_KEY } from '@mdc/constants';
import metadefenderService from '../metadefender/MetadefenderService';
import countries from '../utils/countries';

const CURRENCY_SYMBOLS = {
    'usd': '$',
    'eur': '€',
    'gbp': '£',
    'jpy': '¥'
};

class StoreService {
    /**
     * Create a store service.
     */
    constructor() {
        this._stripe = null;
        this.storeData = null;
    }

    /**
     * Get the Stripe library
     */
    getStripeLibrary = () => {
        if (!this._stripe) {
            this._stripe = typeof Stripe !== 'undefined' ? Stripe(STRIPE_PUBLIC_KEY) : null; // eslint-disable-line no-undef
        }
        return this._stripe;
    };

    /**
     * Get all available currencies
     *
     * @returns {array} a list of currencies
     */
    getCurrencies() {
        return Object.keys(CURRENCY_SYMBOLS);
    }

    /**
     * Get all available currencies
     *
     * @returns {object} a list of currencies and their cymbols
     */
    getCurrenciesAndSymbols() {
        return CURRENCY_SYMBOLS;
    }

    /**
     * Get currency based on provided location.
     *
     * @param {string} continentCode 2 letter continent code
     * @param {string} countryCode 2 letter country code
     * @returns {string} currency code
     */
    getCurrency(countryName) {
        if (countries.uk.includes(countryName)) {
            return 'gbp';
        }

        if (countryName === 'Japan') {
            return 'jpy';
        }

        if (countries.eu.includes(countryName)) {
            return 'eur';
        }

        return 'usd';
    }

    /**
     * Get the store products.
     *
     * @property {string} currency
     */
    async getStoreData(currency = 'usd', uiData) {
        const storeData = await this.getStoreProductsData();

        const currencySymbol = CURRENCY_SYMBOLS[currency];
        const existingProducts = storeData?.products?.filter((product) => uiData[product.title]);

        existingProducts.sort((product1, product2) => {
            return Object.keys(uiData).indexOf(product1.title) - Object.keys(uiData).indexOf(product2.title);
        });

        const products = existingProducts?.map((p) => {
            const product = Object.assign({}, p, uiData[p.title]);
            product.plans = p.plans[currency] || [];
            return product;
        });

        return {
            currencySymbol,
            products
        };
    }


    /**
     * Get all available products
     *
     * @returns {array} a list of products
     */
    async getAllProducts() {
        return (await this.getStoreProductsData())?.products;
    }

    /**
     * Create a checkout session and redirect to payment page.
     *
     * @param {array} paymentPlans array of valid payment plan IDs
     * @throws Error
     */
    async createSubscription(paymentPlans, currency, selectedPlansPrices) {
        const data = await BackendService.createSubscription(paymentPlans, currency, selectedPlansPrices);
        const { sessionId } = data;

        if (sessionId) {
            return this.getStripeLibrary().redirectToCheckout({ sessionId });
        }

        throw data;
    }

    /**
     * Get user's subscriptions history
     */
    async getSubscription() {
        const [promise] = metadefenderService.store.getSubscription();
        const response = await promise;
        const { data } = response.data;
        data.forEach((subscription) => subscription.forEach((license) => license.currencySymbol = CURRENCY_SYMBOLS[license.currency]));
        return data;
    }

    /**
     * Get store products
     */
    async getStoreProductsData() {
        // If getting store data, waiting for last process complete to avoid duplicated API fetching
        while (this.loading) {
            await new Promise(((resolve) => setTimeout(() => resolve(), 100)));
        }
        this.loading = true;

        this.apikey = localStorage.getItem('apiKey');
        if (this.apikey && this.apikey.match(/^[0-9a-f]{32}$/) && !this.storeData) {
            try {
                const [promise] = metadefenderService.store.getStoreData({ apikey: this.apikey });
                const response = await promise;
                this.storeData = response.data;
            } catch (error) {
                logService.error(error);
                this.loading = false;
                throw (error);
            }
        }

        this.loading = false;
        return this.storeData;
    }

    async createBillingPortalSession(currency) {
        const { data } = await BackendService.createBillingPortalSession(currency);
        const { session } = data;
        if (session && window && !data?.error) {
            // eslint-disable-next-line security/detect-non-literal-fs-filename
            window.open(session.url);
            return;
        }

        throw data;
    }

    /**
     * Get number of commercial engines
     */
    async getEnginesNumber() {
        try {
            const url = 'status/engines-cloud';
            const [promise] = metadefenderService.url.getUrlResults({ address: url });
            const enginesNumber = await promise;

            return enginesNumber?.data?.commercial_engines_count;

        } catch (error) {
            logService.error(error);
        }
    }

    verifyBlockedCountry(country) {
        const blockedCountries = [
            'Belarus', // Belarus
            'Congo', // Congo
            'Ukraine', // Ukraine
            'Eritrea', // Eritrea
            'Cuba', // Cuba
            'Iran', // Iran
            'Iraq', // Iraq
            'Lebanon', // Lebanon
            'Liberia', // Liberia
            'Libya', // Libya
            'North Korea', // North Korea
            'Sudan', // Sudan
            'Syria', // Syria
            'Somalia', // Somalia
            'Venezuela', // Venezuela
            'Yemen', // Yemen
            'Zimbabwe' // Zimbabwe
        ];

        return blockedCountries.includes(country);
    }
}

export default new StoreService();

