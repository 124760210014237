import { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const Chart = loadable(() => import('react-apexcharts'));

import './PieChart.scss';

const PieChart = ({ data }) => {
    const series = useMemo(() => !data || data.every((d) => !d) ? [] : data.map((d) => d.value), [data]);

    const options = useMemo(() => ({
        chart: {
            type: 'pie',
        },
        labels: data?.map((d) => d.title),
        theme: {
            monochrome: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false,
            show: false
        },
        noData: {
            text: 'No data',
            align: 'center',
            verticalAlign: 'middle',
            style: {
                fontSize: '15px',
                fontFamily: 'SimplonNorm',
                color: '#707da1'
            }
        },
        tooltip: {
            enabled: true,
            color: '#FFFFFF'
        },
        legend: {
            show: false
        },
    }), [series, data]);

    const dom = useMemo(() => {
        if (!data) {
            return <ContentLoader height='100%' width='100%' className='pieContentLoader'>
                <circle cx='50%' cy='50%' r='42%'/>
            </ContentLoader>;
        }

        const hasChartData = series?.filter((item) => item !== 0).length !== 0;

        return <div className={`${!hasChartData ? 'noData' : ''}`}>
            <Chart
                series={!hasChartData ? [] : series}
                options={options}
                height={160}
                type='pie'
            />
        </div>;
    }, [series, options, data]);

    return <div className='pieChart w-100'>
        {dom}
    </div>;
};

PieChart.propTypes = {
    data: PropTypes.array,
};

export default PieChart;
