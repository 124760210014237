import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

const SPACE_OFFSET = 20;
const SPACE_RANGE = 100;

const BAR_WIDTH = 7;

const HistoryPlaceholder = ({ width, height }) => {

    const column = [];
    let x = 0;
    let keyCounter = 0;

    while (x < width) {
        const tall = ((Math.random() * 0.5 + 0.5) * height);
        column.push({ x, y: height - tall, h: tall });
        x += Math.random() * SPACE_RANGE + SPACE_OFFSET + BAR_WIDTH;
    }


    return <ContentLoader
        speed={1}
        height={height}
        width={width}
        primaryColor="#EDEEF3"
        secondaryColor="#f3f3f3">

        {column.map((c) => <rect key={keyCounter++} x={c.x} y={c.y} rx="4" ry="4" width={BAR_WIDTH} height={c.h}/>)}

        <rect x='0' y={height - 1} width={width} height={1}/>


    </ContentLoader>;
};

HistoryPlaceholder.defaultProps = {
    width: 700,
    height: 200
};

HistoryPlaceholder.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
};


export default HistoryPlaceholder;
