import PropTypes from 'prop-types';

const TranslationFix = ({ message, type }) => {

    switch (type) {
        case 'div':
            return <div key={Math.random()} dangerouslySetInnerHTML={{ __html: message }}/>;

        case 'p':
            return <p key={Math.random()} dangerouslySetInnerHTML={{ __html: message }}/>;

        case 'span':
            return <span  key={Math.random()} dangerouslySetInnerHTML={{ __html: message }}/>;

    }
};

TranslationFix.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

export default TranslationFix;
