import { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import CodeData from './CodeSnippet.Data';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CopyButton from '../../common/copy-button/CopyButton';

import './CodeSnippet.scss';

const CodeSnippet = (props) => {
    const { t, ready } = useTranslation();

    const [copyContent, setCopyContent] = useState(CodeData[0].raw(props));

    const onTabSelect = (key) => {
        setCopyContent(CodeData.filter((snippet) => snippet.code === key)[0].raw(props));
    };

    if (!ready) {
        return null;
    }

    return (<div className='codeSnippet'>
        <p className='h6Mask'>{t('Get this data from our API')}</p>
        <Tab.Container id="custom-tabs" defaultActiveKey={CodeData[0].code} onSelect={onTabSelect}>
            <Nav>
                <div className='codeLabel'>
                    <a className='disabled'>{t('code')}</a>
                </div>
                {CodeData.map((snippet) => <Nav.Item key={snippet.code}>
                    <Nav.Link eventKey={snippet.code}>{snippet.code}</Nav.Link>
                </Nav.Item>)}
                <CopyButton icon={'clone'} data={copyContent}/>
            </Nav>
            <Tab.Content>
                {CodeData.map((snippet) =>
                    <Tab.Pane key={snippet.code} eventKey={snippet.code}>
                        <div dangerouslySetInnerHTML={{ __html: snippet.component(props) }}/>
                    </Tab.Pane>)}
            </Tab.Content>
        </Tab.Container>
    </div>);
};

CodeSnippet.propTypes = {
    apiKey: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default CodeSnippet;
