import axios from 'axios';
import { CDN_PATH } from '@mdc/constants';
import GenericPubSub from '../generic-pub-sub/GenericPubSub';

import { logService, objectToURLParamsStr } from '@mdc/services';

export const SERVICE_ERRORS = {
    REPORT_ERROR: 'Report error',
    INVALID_REPORT: 'Invalid report'
};

const getReportsUrl = (reportName, folder) => {
    const report = folder ? `${folder}${reportName}` : reportName;
    try {

        const cdnPattern = /\/\/([^/]*cdn\.opswat\.com[^/]*)\//;
        const cdnMatch = cdnPattern.exec(`${CDN_PATH}/`);
        if (cdnMatch) {
            const cdn = cdnMatch[1];
            return `https://${cdn}/${report}`;
        }
    }
    catch (error) {

        // eslint-disable-next-line no-console
        logService.error(error);
    }

    return report;
};

const getReportsPath = (reportName, folder) => {
    return folder ? `${folder}${reportName}` : reportName;
};

class CDNService extends GenericPubSub {
    constructor() {
        super(SERVICE_ERRORS);
    }

    getCDNReport = async (reportName, folder, handleNavigate, getCDNData = true) => {
        const reportURL = getCDNData ? getReportsUrl(reportName, folder) : getReportsPath(reportName, folder);

        try {
            let { data } = getCDNData ? await axios.get(reportURL) : { data: null };
            // eslint-disable-next-line security/detect-non-literal-require
            let localData = !getCDNData ? require('@mdc/constants/' + reportURL) : null;

            if (typeof data !== 'object') {
                try {
                    data = JSON.parse(data);
                } catch (error) {
                    this._publish(this.topics.INVALID_REPORT, error);
                }
            }

            return getCDNData ? data : localData;
        } catch (error) {
            const path = window.location.pathname;
            const qParams = { date: 'Latest' };
            handleNavigate(`${path}?${objectToURLParamsStr(qParams)}`);

            this._publish(this.topics.REPORT_ERROR, error);
        }
    };
}

export default new CDNService();
