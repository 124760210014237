import { useState, useEffect, useMemo } from 'react';
import PlainTableRow from './PlainTableRow';
import PropType from 'prop-types';
import { LightButton } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import Pagination from './Pagination';
import classNames from 'classnames';
import PlainTableHeader from './PlainTableHeader';
import { SORT_STATES } from '../table/sort-button/SortButton';

import './PlainTable.scss';

const PlainTable = ({
    columnsData,
    data,
    hasLoadMore,
    loadMoreAction,
    hasPagination,
    hasCollapseAll,
    hasSorting,
    tableBanner,
    shouldExpandAll,
    isEntireRowExpandable,
    customClassName,
    dontCollapse,
    showExpandSymbol
}) => {
    const ITEMS_DISPLAYED = useMemo(() => {
        if (hasLoadMore) {
            return 5;
        }

        return hasPagination ? 10 : 0;
    }, [hasLoadMore, hasPagination]);

    const dataLength = data?.length;

    const [dataLimit, setDataLimit] = useState();
    const [tableData, setTableData] = useState(data);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [noOfItemsPerPage, setNoOfItemsPerPage] = useState(dataLength < 10 ? dataLength : 10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [numberOfExpandedCells, setNumberOfExpandedCells] = useState(0);
    const [rowModifiers, setRowModifiers] = useState({ sort: null });

    const lessColClassNames = classNames({
        lessCol: columnsData.filter((col) => !!col.Header).length <= 2 || dontCollapse
    });

    const onSort = ({ accessor, state }) => {
        const currentModifiers = rowModifiers;
        setRowModifiers(Object.assign({}, currentModifiers, { sort: { accessor, state } }));
    };

    const compareFunction = (rowDataCurrent, rowDataNext) => {
        let childrenCurr;
        let childrenNext;
        if (Date.parse(rowDataCurrent)) {
            const rowDataCurrentDate = new Date(rowDataCurrent);
            const rowDataNextDate = new Date(rowDataNext);
            return rowDataCurrentDate - rowDataNextDate;
        }

        if (rowDataCurrent?.props?.children) {
            childrenCurr = rowDataCurrent.props.children;
            childrenNext = rowDataNext.props.children;

            return new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare(childrenCurr, childrenNext);

        }

        return new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare(rowDataCurrent, rowDataNext);

    };

    const sortTableData = (info) => {
        const { accessor, state } = rowModifiers.sort || {};
        const rowDataArr = [...info];
        if (!state) {
            return rowDataArr;
        }

        let sortedTableData;

        switch (state) {
            case SORT_STATES.UP:
                sortedTableData = rowDataArr.sort((a, b) => compareFunction(a[accessor], b[accessor]));
                break;
            case SORT_STATES.DOWN:
                sortedTableData = rowDataArr.sort((a, b) => compareFunction(b[accessor], a[accessor]));
                break;
            case SORT_STATES.INACTIVE:
                sortedTableData = info;
                break;
            default:
        }

        return sortedTableData;
    };

    const processedTableData = useMemo(() => {
        const modifiers = rowModifiers;
        let modifiedRowData = tableData?.length ? tableData : data;
        if (modifiers.sort) {
            modifiedRowData = sortTableData(modifiedRowData);
        }

        return modifiedRowData;
    }, [tableData, rowModifiers, data]);

    const mobileCollapseRowClassName = classNames({
        hidden: !hasCollapseAll || !numberOfExpandedCells
    }, 'mobileCollapseRow', lessColClassNames);

    const collapseAllClassNames = classNames({
        isEntireRowExpandable: isEntireRowExpandable
    }, 'cell-collapseAll mobileCollapse', lessColClassNames);

    const { t, ready } = useTranslation();

    const columnsMeta = useMemo(() => {
        return columnsData.map((info) => {
            return info.accessor;
        });
    }, [columnsData]);

    const handleItemsPerPage = (key) => {
        setNoOfItemsPerPage(parseInt(key));
        setDataLimit(parseInt(key));
    };

    const handleSetCurrentPage = (key) => {
        setCurrentPage(parseInt(key));
        setTableData(data.slice(noOfItemsPerPage * (parseInt(key) - 1), parseInt(key) * noOfItemsPerPage));
    };

    useEffect(() => {
        if ((hasLoadMore || hasPagination) && dataLength > ITEMS_DISPLAYED && !loadMoreAction) {
            setDataLimit(ITEMS_DISPLAYED);
        } else {
            setDataLimit(dataLength);
        }
    }, [hasLoadMore, hasPagination, dataLength, loadMoreAction]);

    const handleLoadMoreClick = useMemo(() => () => {
        if (!loadMoreAction) {
            if (dataLength > dataLimit + ITEMS_DISPLAYED) {
                setDataLimit(dataLimit + ITEMS_DISPLAYED);
            } else {
                setDataLimit(dataLength);
                setHasMoreData(false);
            }
        } else {
            setLoadingMore(true);
            (async () => {
                const moreItemLength = await loadMoreAction();
                setLoadingMore(false);
                setDataLimit(dataLength + moreItemLength);
            })();
        }
    }, [loadMoreAction, dataLength, dataLimit]);

    const onCollapseAllClick = () => {
        setIsCollapsed(!isCollapsed);
        setNumberOfExpandedCells(0);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onCollapseAllClick();
        }
    };

    useEffect(() => {
        setTableData(data?.slice(0, dataLimit));
    }, [dataLimit, data]);

    const collapseAllDom = useMemo(() => {
        if (hasCollapseAll && numberOfExpandedCells > 0) {
            return <button className='collapseAllIconWrapper' onClick={onCollapseAllClick} onKeyDown={handleKeyDown}>
                <i className='icon icon-angle-up' />
            </button>;
        }
    }, [isCollapsed, numberOfExpandedCells, hasCollapseAll, onCollapseAllClick]);

    const onExpandCellClick = (isCellExpanded, shouldUseDataLength = false) => {
        if (isCellExpanded) {
            setNumberOfExpandedCells(numberOfExpandedCells + 1);
        } else {
            setNumberOfExpandedCells(numberOfExpandedCells - 1);
        }

        if (shouldUseDataLength) {
            setNumberOfExpandedCells(data?.length);
        }
    };

    const tableDataDom = useMemo(() => {
        return processedTableData?.map((info, index) => {
            return <PlainTableRow
                key={info?.id}
                trClassName={`tr-${index} plainTableRow`}
                data={info}
                columnsMeta={columnsMeta}
                columnsData={columnsData}
                shouldCollapse={!isCollapsed}
                onExpandCellClick={onExpandCellClick}
                hasSorting={hasSorting}
                onSort={onSort}
                shouldExpandAll={shouldExpandAll}
                isEntireRowExpandable={isEntireRowExpandable}
                dontCollapse={dontCollapse || false}
                showExpandSymbol={showExpandSymbol}
            />;
        });
    }, [isCollapsed, columnsData, numberOfExpandedCells, columnsMeta, processedTableData, isEntireRowExpandable, data]);

    const loadMoreDom = useMemo(() => {
        if (hasLoadMore && hasMoreData) {
            return <tr className='loadMoreButton'>
                <td colSpan='100%'>
                    <LightButton
                        onClick={handleLoadMoreClick}
                        disabled={loadingMore || !hasMoreData}
                    >
                        {loadingMore ? t('Loading') : t('Load more')}
                    </LightButton>
                </td>
            </tr>;
        }

        return <tr />;
    }, [hasLoadMore, hasMoreData, loadingMore, handleLoadMoreClick, t]);

    const paginationDom = hasPagination
        ?
        <Pagination
            dataLength={dataLength}
            itemsPerPage={noOfItemsPerPage}
            onItemsPerPageClick={handleItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={handleSetCurrentPage}
        />
        : undefined;

    const tableDom = <>
        <tbody>
            {tableBanner && <tr className='tableBanner'>
                <td colSpan='100%'>
                    {tableBanner}
                </td>
            </tr>}
            <tr className={mobileCollapseRowClassName}>
                <td colSpan='100%' className={collapseAllClassNames} onClick={() => isEntireRowExpandable ? onCollapseAllClick() : null}>
                    {collapseAllDom}
                </td>
            </tr>
            {tableDataDom}
        </tbody>
        <tfoot>
            {loadMoreDom}
        </tfoot>
    </>;

    if (!ready) {
        return '';
    }

    return <>
        <table className={classNames(lessColClassNames, customClassName, 'plainTable')}>
            <PlainTableHeader
                columnsData={columnsData}
                isCollapsed={isCollapsed}
                hasCollapseAll={hasCollapseAll}
                onCollapseAllClick={onCollapseAllClick}
                collapseAllDom={collapseAllDom}
                hasSorting={hasSorting}
                onSort={onSort}
                isEntireRowExpandable={isEntireRowExpandable}
            />
            {tableDom}
        </table>
        {paginationDom}
    </>;
};

PlainTable.propTypes = {
    columnsData: PropType.array.isRequired,
    data: PropType.array.isRequired,
    hasLoadMore: PropType.bool,
    loadMoreAction: PropType.func,
    hasPagination: PropType.bool,
    hasCollapseAll: PropType.bool,
    hasSorting: PropType.bool,
    tableBanner: PropType.object,
    shouldExpandAll: PropType.bool,
    isEntireRowExpandable: PropType.bool,
    customClassName: PropType.string,
    dontCollapse: PropType.bool,
    showExpandSymbol: PropType.bool
};

export default PlainTable;
