import BaseService from '../BaseService';

class UrlService {
    constructor(instance) {
        this.instance = instance;
        this.base = new BaseService(this.instance);
    }

    getResults = (params, headers) => {
        return this.base.get(`/url/${params.address}`, headers);
    }

    getUrlResults = (params, headers) => {
        return this.base.get(`/${params.address}`, headers);
    }
}

export default UrlService;
