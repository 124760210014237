import { setup } from 'axios-cache-adapter';
import { METADEFENDER } from '@mdc/constants';

import ApikeyService from './apikey/ApikeyService';
import DomainService from './domain/DomainService';
import FileService from './file/FileService';
import HashService from './hash/HashService';
import IpService from './ip/IpService';
import ReportService from './report/ReportService';
import SandboxService from './sandbox/SandboxService';
import StatusService from './status/StatusService';
import StoreService from './store/StoreService';
import UrlService from './url/UrlService';
import UserService from './user/UserService';
import VulnerabilitiesService from './vulnerabilities/VulnerabilitiesService';

class MetadefenderService {
    constructor() {
        this.baseUrl = METADEFENDER.baseUrl;
        this.version = METADEFENDER.version;

        this.instance = setup({
            baseURL: this.baseUrl + '/' + this.version,
            timeout: METADEFENDER.timeout,
            cache: { maxAge: 0 }
        });

        this.apikey = new ApikeyService(this.instance);
        this.domain = new DomainService(this.instance);
        this.file = new FileService(this.instance);
        this.hash = new HashService(this.instance);
        this.ip = new IpService(this.instance);
        this.report = new ReportService(this.instance);
        this.sandbox = new SandboxService(this.instance);
        this.status = new StatusService(this.instance);
        this.store = new StoreService(this.instance);
        this.url = new UrlService(this.instance);
        this.user = new UserService(this.instance);
        this.vulnerabilities = new VulnerabilitiesService(this.instance);
    }

    addCommonHeader(headerObject) {
        Object.entries(headerObject).forEach(([key, value]) => {
            this.instance.defaults.headers.common[key] = value;
        });
    }

    getCommonHeaders() {
        return this.instance.defaults.headers.common;
    }
}

export default new MetadefenderService();
