import { createContext, Component } from 'react';
import PropTypes from 'prop-types';

export const ThemeContext = createContext({
    themeContext: {
        active: 'city'
    }
});

export function withThemeProvider(WrappedComponent) {
    return function ThemeComponent(props) {
        return (
            <ThemeContext.Consumer>
                {(contexts) => (<WrappedComponent
                    {...props}
                    {...contexts}
                />)}
            </ThemeContext.Consumer>
        );
    };
}

export class ThemeProvider extends Component {
    state = {
        active: 'city',
    };

    getActive() {
        return this.state.active;
    }

    setActive(theme) {
        this.setState(() => ({
            active: theme,
        }));
    }

    render = () => {
        return (
            <ThemeContext.Provider value={{
                themeProvider: {
                    getActive: () => this.getActive(),
                    setActive: (theme) => this.setActive(theme)
                }
            }}
            >
                {this.props.children}
            </ThemeContext.Provider>
        );
    }
}

ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired,
    themes: PropTypes.array
};
