import Table from '../common/table/Table';
import PropTypes from 'prop-types';
import ChartBar from '../common/table/cell-components/ChartBar';
import './LeaderboardTable.scss';
import { useTranslation } from 'react-i18next';

const LeaderboardTable = ({ leaderboard, color }) => {
    const { t, ready } = useTranslation();

    let index = 1;
    const columns = [
        {
            Header: '#',
            accessor: 'index'
        },
        {
            Header: t('Nickname'),
            accessor: 'nickname'
        },
        {
            Header: t('Submissions'),
            accessor: 'score'
        },
        {
            Header: '',
            accessor: 'leaderboard',
            component: ChartBar
        }
    ];

    const maxScore = leaderboard.reduce((max, item) => {
        return (item && item.score > max) ? item.score : max;
    }, 0);

    const data = leaderboard.map((item) => {
        const itemScore = item.score;

        let now;
        let power;
        if (itemScore === maxScore) {
            now = 100;
            power = 30;
        }
        else {
            now = Math.round((100 * itemScore) / maxScore + 1);
            power = Math.round((30 * itemScore) / maxScore + 1);
        }

        item.index = index++;
        item.leaderboard = { now, power, color };
        return item;
    });

    if (!ready) {
        return null;
    }

    return (
        <div className="leaderboardTable">
            <Table columns={columns}
                data={data}
                useSorting={false}
            />
        </div>

    );
};

LeaderboardTable.propTypes = {
    leaderboard: PropTypes.array.isRequired,
    color: PropTypes.string.isRequired,
};

export default LeaderboardTable;
