import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownButton, ButtonGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './Pagination.scss';

const HOW_MANY_ITEMS_PER_PAGE = [10, 20, 30, 40, 50];

const Pagination = ({ dataLength, itemsPerPage, onItemsPerPageClick, currentPage, setCurrentPage }) => {
    const { t, ready } = useTranslation();
    const noOfItemsPerPage = HOW_MANY_ITEMS_PER_PAGE.filter((noOfItems) => noOfItems < dataLength);
    if (noOfItemsPerPage.length === 0 || noOfItemsPerPage[noOfItemsPerPage.length-1] < dataLength) {
        noOfItemsPerPage.push(dataLength);
    }
    const noOfPages = Math.ceil(dataLength / itemsPerPage) || 1;
    const pages = [...Array(noOfPages).keys()].map((page) => page + 1);

    const pageExists = (page) => {
        return pages.includes(page);
    };

    const nextPageClassName = classNames({
        disabled: !pageExists(currentPage + 1)
    }, 'icon icon-angle-right');

    const prevPageClassName = classNames({
        disabled: !pageExists(currentPage - 1)
    }, 'icon icon-angle-left');

    const itemsPerPageDom = useMemo(() => {
        return <div className='resultsWrapper'>
            <DropdownButton
                as={ButtonGroup}
                key={'primary'}
                id='paginationDropdown'
                variant='primary'
                title={itemsPerPage}
            >
                {noOfItemsPerPage.map((noOfItems) => {
                    return <Dropdown.Item
                        key={noOfItems}
                        eventKey={noOfItems}
                        onSelect={onItemsPerPageClick}
                        active={itemsPerPage === noOfItems}
                    >
                        {noOfItems}
                    </Dropdown.Item>;
                })}
            </DropdownButton>
            <span className='itemsPerPage'>{t('items per page | 1-{{itemsPerPage}} of {{dataLength}} items', { itemsPerPage: itemsPerPage, dataLength: dataLength })}</span>
        </div>;
    }, [itemsPerPage, noOfItemsPerPage, dataLength]);

    const paginationDom = useMemo(() => {
        return <div className='pagesWrapper'>
            <ButtonGroup>
                <Button variant='link' onClick={() => pageExists(currentPage - 1) && setCurrentPage(currentPage - 1)} aria-label='Previous page'>
                    <i className={prevPageClassName}/>
                </Button>
                <div className='pageSelector'>
                    <DropdownButton
                        as={ButtonGroup}
                        key={'primary'}
                        id='paginationDropdown'
                        variant='primary'
                        title={currentPage > noOfPages ? noOfPages : currentPage}
                    >
                        {pages.map((page) => {
                            return <Dropdown.Item
                                key={page}
                                eventKey={page}
                                onSelect={setCurrentPage}
                                active={currentPage === page}
                            >
                                {page}
                            </Dropdown.Item>;

                        })}
                    </DropdownButton>
                    <span className='itemsPerPage'>{t('of {{noOfPages}} pages', { noOfPages: noOfPages })}</span>
                </div>
                <Button variant='link' onClick={() => pageExists(currentPage + 1) && setCurrentPage(currentPage + 1)} aria-label='Next page'>
                    <i className={nextPageClassName}/>
                </Button>
            </ButtonGroup>
        </div>;
    }, [currentPage, noOfPages, pages]);

    if (!ready) {
        return '';
    }

    return <div className='paginationWrapper'>
        {itemsPerPageDom}
        {paginationDom}
    </div>;
};

Pagination.propTypes = {
    dataLength: PropTypes.number,
    itemsPerPage: PropTypes.number,
    onItemsPerPageClick: PropTypes.func,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func
};

export default Pagination;
