import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, TextInput } from '@mdc/ui';
import { Authenticator, AdvancedOptions, localStorage } from '@mdc/services';
import { ADVANCED_OPTIONS_KEY, MULTISCAN_LAST_ADVANCED_OPTIONS_KEY } from '@mdc/constants';
import { Badge } from 'react-bootstrap';

const RULE_PARAM = 'rule';

class AdvancedOptionsProvider {
    constructor() {
        this.selectedOptions = AdvancedOptions.getSelectedOptions();
        this.t = useTranslation().t;

        Authenticator.events.on(Authenticator.events.topics.STATE_CHANGE, this.onAuthStateChange);
    }

    onAuthStateChange = () => {
        this.selectedOptions = AdvancedOptions.getSelectedOptions();
    };

    isOptionSelected = ({ param, value }) => ( this.selectedOptions.includes(value) || this.selectedOptions.includes(param) || this.selectedOptions.includes(`${param}-${value}`));


    getOptionsList = (options, onOptionClick) => {
        return options.filter((option) => {
            return (!option.paidOnly && !option.pwdProtectedOnly && option.param !== 'rule') || (option.param === 'rule' && (option.disabled));
        })
            .map((option) => {
                const getValue = ({ param, value }) => {
                    if (Array.isArray(value) && param !== RULE_PARAM) {
                        // eslint-disable-next-line no-prototype-builtins
                        if (this.selectedOptions.hasOwnProperty(param)) {
                            return this.selectedOptions[param];
                        }

                        if (param === 'sandbox') {
                            return value[0].values[0].key;
                        }

                        return value[0].key;
                    }
                    return value;
                };

                const isDisabled = option.disabled && option.disabled === 'true';
                const isSelected = option.checked || this.isOptionSelected(option);
                const value = getValue(option);

                const hasSuboptions = Array.isArray(option.value);

                const label = value === 'dlp' ?
                    (<a
                        href='https://docs.opswat.com/mdcloud/operation/homepage'
                        rel="noopener noreferrer"
                        target='_blank'
                    >
                        {this.t(option.translationKey)}
                    </a>) :
                    this.t(option.translationKey);

                return (
                    <Checkbox
                        isDisabled={isDisabled}
                        isSelected={isSelected}
                        key={option.translationKey}
                        onChange={onOptionClick.bind(null, option.param, value)}
                        hasSuboptions={hasSuboptions}
                        useManualCheck
                    >
                        {label}
                    </Checkbox>
                );
            });
    };

    getMultiScanOptionsList = (options, onOptionClick) => {
        return options.filter((option) => {
            return option.param === 'rule' && !option.disabled;
        }).map((option) => {
            const isMultiScanSelected = this.isOptionSelected({ param: 'rule', value: 'multiscan' });
            let isSelected = this.isOptionSelected(option);

            // If "multiscan" is disable, other checkbox will be disable and value is from local storage
            if (!isMultiScanSelected && option.value !== 'multiscan') {
                const analyzedOptions = localStorage.getItem(ADVANCED_OPTIONS_KEY);
                isSelected = analyzedOptions && analyzedOptions[MULTISCAN_LAST_ADVANCED_OPTIONS_KEY]?.split(',')?.includes(option.value) || false;
            }

            const isDisabled = !isMultiScanSelected && option.value !== 'multiscan';

            return (
                <Checkbox
                    isDisabled={isDisabled}
                    isSelected={isSelected}
                    key={option.translationKey}
                    onChange={onOptionClick.bind(null, option.param, option.value)}
                    useManualCheck
                >
                    {this.t(option.translationKey)}
                </Checkbox>
            );
        });
    };

    getSubOptionsList = (options, onOptionClick) => {
        const subOptionItems = options.filter((option) => {
            return Array.isArray(option.value);
        });


        return subOptionItems.map((option) => {
            const optionValue = option.value;
            if (Array.isArray(optionValue)) {
                const checkboxes = optionValue.map((valueObj) => {
                    const subOptions = valueObj.values.map((newValueObj) => {
                        const isSelected = this.isOptionSelected({ param: valueObj.param, value: newValueObj.key });
                        const label = newValueObj.label ? newValueObj.label : this.t(newValueObj.translationKey);

                        return (
                            <Checkbox
                                isSelected={ isSelected }
                                key={newValueObj.key}
                                onChange={onOptionClick.bind(null, valueObj.param, newValueObj.key)}
                                useManualCheck
                            >
                                {label}

                            </Checkbox>
                        );
                    });

                    return (
                        <div key={valueObj.fieldName}>
                            <span className='subOptionFieldName'>{this.t(valueObj.fieldName)}</span>
                            {subOptions}
                        </div>
                    );
                });

                return (
                    <div
                        className="subOption"
                        key={option.param}
                    >
                        <span className="options">
                            {checkboxes}
                        </span>
                    </div>
                );
            }

            return null;
        });
    };

    getPaidOptions = (options, onOptionClick, isPaidUser, isEnforcedPrivateScanning, isLoggedIn) => {
        const checkboxes = options.filter((option) => {

            return option?.paidOnly;
        }).map((option) => {
            const isSelected = this.isOptionSelected({ value: option.value, param: option.param });
            const label = option.label ? option.label : this.t(option.translationKey);

            const onButtonClick = () => {
                if (isLoggedIn) {
                    window.location.href = '/store';
                } else {
                    Authenticator.authRedirect('/login');
                }
            };

            let isSelectdWhenInforced;

            if (!isEnforcedPrivateScanning) {
                isSelectdWhenInforced = isSelected ? isSelected : false;
            } else {
                isSelectdWhenInforced = true;
            }

            return <Fragment key={option.value}>
                { isPaidUser ? <Checkbox
                    isDisabled={isEnforcedPrivateScanning}
                    isSelected={isSelectdWhenInforced}
                    key={option.value}
                    onChange={isPaidUser ? onOptionClick.bind(null, option.param, option.value) : undefined}
                    useManualCheck
                >
                    <>
                        {label}
                    </>
                </Checkbox> :
                    <Checkbox
                        isDisabled={true}
                        isSelected={false}
                        key={option.value}
                        useManualCheck
                    >
                        <>
                            {label}
                            {!isPaidUser && <Badge className='paidOnlyBadge' variant='dark'>{this.t('Paid only')}</Badge>}
                        </>
                    </Checkbox>
                }
                {option.paidDescription && !isPaidUser && <p className='paidDescription'>
                    {option.paidDescription}. {<a href='#' onClick={onButtonClick}>{isLoggedIn ? this.t('Upgrade') : this.t('Login')}</a>}
                </p>}
            </Fragment>;
        });
        return (
            <div className='paidOnlyOptions'>
                {checkboxes}
            </div>
        );
    };

    getPasswordOptions = (options, onOptionClick) => {
        const checkboxes = options.filter((option) => {
            return option.pwdProtectedOnly;
        }).map((option) => {
            const isSelected = this.isOptionSelected({ value: '', param: option.param });
            const isMultiScanSelected = this.isOptionSelected({ param: 'rule', value: 'multiscan' });
            const label = option.label ? option.label : this.t(option.translationKey);
            const isDisabled = !isMultiScanSelected;

            return (
                <div className='passwordProtected' key={option?.param}>
                    <Checkbox
                        isDisabled={isDisabled}
                        isSelected={isSelected}
                        key={option.value}
                        onChange={onOptionClick.bind(null, option.param, '')}
                        useManualCheck
                    >
                        {label}
                    </Checkbox>
                    <TextInput
                        placeholder={this.t('Add password')}
                        onChange={(value) => onOptionClick('passwordTextInput', value)}
                        onSubmit={() => { return; }}
                        disabled={!isSelected}
                    />
                </div>
            );
        });
        return (
            <div className='passwordOption'>
                {checkboxes}
            </div>
        );
    };
}

export default AdvancedOptionsProvider;
