export default {
    NO_THREAT: 0,
    INFECTED: 1,
    SUSPICIOUS: 2,
    FAILED_TO_SCAN: 3,
    CLEANED: 4,
    UNKNOWN: 5,
    QUARANTINED: 6,
    SKIPPED_CLEAN: 7,
    SKIPPED_DIRTY: 8,
    EXCEEDED_ARCHIVE_DEPTH: 9,
    ENGINE_IS_UPDATING: 10,
    ABORTED: 11,
    ENCRYPTED_ARCHIVE: 12,
    EXCEEDED_ARCHIVE_SIZE: 13,
    EXCEEDED_ARCHIVE_FILE_NUMBER: 14,
    PASSWORD_PROTECTED_DOCUMENT: 15,
    MISMATCH: 17,
    POTENTIALLY_VULNERABLE_FILE: 18,
    POTENTIALLY_UNWANTED_PROGRAM: 22,
    UNSUPPORTED_FILE_TYPE: 23,
};
