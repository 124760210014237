import PropTypes from 'prop-types';
import classnames from 'classnames';

import './CveStatus.scss';

const CveStatus = ({ isUnderAnalysis, message }) => {
    const iconClass = classnames('icon', { 'icon-warning-empty': isUnderAnalysis, 'icon-block': !isUnderAnalysis });
    const barClass = classnames('barContainer', { 'barWarning': isUnderAnalysis, 'barBlock': !isUnderAnalysis });
    return (
        <div className={barClass}>
            <span className='iconContainer'>
                <i className={iconClass}/>
            </span>
            <span className='textContainer'>
                {message}
            </span>
        </div>
    );
};

CveStatus.propTypes = {
    isUnderAnalysis: PropTypes.bool,
    message: PropTypes.string,
};

export default CveStatus;
