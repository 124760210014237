/* eslint no-console: 0 */
import { hasLocalStorage, logService } from '@mdc/services';

class LocalStorage {
    PREFIX = 'ngStorage-';

    constructor() {
        this.available = hasLocalStorage;
    }

    isAvailable() {
        return this.available;
    }

    key(index) {
        if (!this.isAvailable()) {
            return;
        }

        return window.localStorage.key(`${this.PREFIX}${index}`);
    }

    getItem(key, noPrefix = false) {
        if (!this.isAvailable()) {
            return;
        }
        if (!key) {
            throw new Error('Failed to execute \'getItem\' on \'Storage\': 1 argument required, but only 0 present.');
        }

        const localStorageKey = noPrefix ? key : `${this.PREFIX}${key}`;

        const value = window.localStorage.getItem(localStorageKey);

        try {
            return JSON.parse(value);
        } catch (error) {
            return value;
        }
    }

    setItem(key, value, noPrefix = false) {
        if (!this.isAvailable()) {
            return;
        }

        const localStorageKey = noPrefix ? key : `${this.PREFIX}${key}`;

        try {
            let stringifiedValue;

            if (typeof value !== 'string') {
                stringifiedValue = JSON.stringify(value);
            }

            window.localStorage.setItem(localStorageKey, stringifiedValue || value);

        } catch (error) {
            logService.error(error);
        }
    }

    removeItem(key, noPrefix = false) {
        if (!this.isAvailable()) {
            return;
        }

        const localStorageKey = noPrefix ? key : `${this.PREFIX}${key}`;
        return window.localStorage.removeItem(localStorageKey);
    }

    clear() {
        if (!this.isAvailable()) {
            return;
        }

        return window.localStorage.clear();
    }

    length() {
        if (!this.isAvailable()) {
            return;
        }

        return window.localStorage.length;
    }
}

export const localStorage = new LocalStorage();
