import { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TextInput.scss';

const TextInput = (props, ref) => {
    const [value, setValue] = useState(props.initialValue || '');
    const { placeholder, propClasses, onSubmit, isFocused } = props;
    const onChangeCallback = props.onChange;
    const [textPlaceHolder, setTextPlaceHolder] = useState('');

    const onChange = (evt) => {
        const val = evt.target.value;
        setValue(val);
        onChangeCallback(val.trim());
    };

    const onKeyDown = (evt) => {
        if (evt.key === 'Enter') {
            onSubmit && onSubmit();
        }
    };

    const classNames = classnames('textInput', propClasses);

    useEffect(() => {
        setTextPlaceHolder(placeholder);
    }, [placeholder]);

    return (
        <input
            name="textInput"
            aria-label="textInput"
            className={classNames}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={textPlaceHolder}
            type="text"
            value={value}
            disabled={props.disabled}
            ref={ref}
            autoFocus={isFocused}
        />
    );
};

export default forwardRef(TextInput);

TextInput.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    propClasses: PropTypes.object,
    placeholder: PropTypes.string,
    initialValue: PropTypes.string,
    disabled: PropTypes.bool,
    isFocused: PropTypes.bool
};

TextInput.defaultProps = {
    isFocused: false
};
