import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Checkbox.scss';

const Checkbox = ({ children, isDisabled, isSelected, onChange, useManualCheck }) => {
    const [isClient, setIsClient] = useState(false);
    const [selected, setSelected] = useState(isSelected);
    const [disabled, setDisable] = useState(isDisabled);

    const onChangeCallback = (evt) => {
        if (!useManualCheck) {
            setSelected(isSelected);
        }
        onChange && onChange(evt);
    };

    useMemo(() => {
        if (selected !== isSelected) {
            setSelected(isSelected);
        }

        if (disabled !== isDisabled) {
            setDisable(isDisabled);
        }
        if (children === 'Multiscanning') {
            setDisable(true);
            setSelected(true);
        }

    }, [isDisabled, isSelected]);

    useEffect(() => {
        setIsClient(true);
    }, [isClient]);

    const labelClasses = classnames({
        checked: selected,
        disabled: disabled
    });

    const dataCy = useMemo(() => {
        if (typeof children !== 'object') {
            return children;
        }

        return children?.props?.children === 'Proactive DLP' ? 'Proactive DLP' : null;
    }, [children]);

    const checkboxClasses = classnames({
        'checked': selected
    }, 'checkboxWrapper');

    if (!isClient) {
        return null;
    }

    return (
        <div className={checkboxClasses} data-cy={dataCy}>
            <label className={labelClasses}>
                <input
                    name="checkbox"
                    checked={selected}
                    disabled={disabled}
                    onChange={onChangeCallback}
                    type="checkbox"
                />
                {children}
            </label>
        </div>
    );
};

Checkbox.defaultProps = {
    isSelected: false,
    useManualCheck: false
};

Checkbox.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isDisabled: PropTypes.bool,
    isSelected: PropTypes.any,
    onChange: PropTypes.func,
    useManualCheck: PropTypes.bool
};

export default Checkbox;
