import { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import { UPLOAD_PRELOADER_STATES } from '@mdc/constants';

export const UploadPreloaderContext = createContext({});

export function withUploadPreloaderProvider(WrappedComponent) {
    return function UploadPreloaderComponent(props) {
        return (
            <UploadPreloaderContext.Consumer>
                {(contexts) => (<WrappedComponent
                    {...props}
                    {...contexts}
                />)}
            </UploadPreloaderContext.Consumer>
        );
    };
}

export class UploadPreloaderProvider extends Component {
    state = {
        preloaderState: UPLOAD_PRELOADER_STATES.INACTIVE,
    };

    getPreloaderState() {
        return this.state.preloaderState;
    }
    setPreloaderState(newState) {
        this.setState({ preloaderState: newState });
    }
    getPreloaderText() {
        return this.state.preloaderText;
    }
    setPreloaderText(text) {
        this.setState({ preloaderText: text });
    }

    render = () => {
        return (
            <UploadPreloaderContext.Provider value={{
                uploadProvider: {
                    getPreloaderState: () => this.getPreloaderState(),
                    setPreloaderState: (state) => this.setPreloaderState(state),
                    getPreloaderText: () => this.getPreloaderText(),
                    setPreloaderText: (text) => this.setPreloaderText(text),
                }
            }}
            >
                {this.props.children}
            </UploadPreloaderContext.Provider>
        );
    }
}

UploadPreloaderProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
