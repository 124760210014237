import { useState, useRef, useMemo } from 'react';
import { Col, Row, Tooltip, Overlay, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './ApikeyInfo.scss';
import Clipboard from 'react-clipboard.js';
import '../common/icon-button/IconButton.scss';
import IconButton from '../common/icon-button/IconButton';
import { useTranslation } from 'react-i18next';
import { placeholders } from '@mdc/ui';

const ApikeyInfo = (props) => {
    const [show, setShow] = useState(false);
    const { t, ready } = useTranslation();
    const target = useRef(null);
    const refInput = useRef(null);
    const { TextPlaceholder } = placeholders;

    const handleUpdate = () => {
        props.updateLimits();
    };

    const tooltipDOM = (tooltipProps) => {
        tooltipProps.show = tooltipProps.show.toString();
        return (
            <Tooltip id="tooltip-bottom" {...tooltipProps}>
                {t('Copied!')}
            </Tooltip>
        );
    };

    const apikeyDom = useMemo(() => {
        if (!props.apikey && !props.apikeyOnly) {
            return;
        }

        return <Row>
            <Col lg={5} md={4} sm={4}>
                {t('API key')}
            </Col>
            <Col lg={7} md={8} sm={8} className="valueCol">
                <span className="valuesText">
                    {props.apikey}
                </span>
                <Clipboard component="span" data-clipboard-text={props.apikey}>
                    <IconButton iconClass="icon-clone" onClick={() => setShow(!show)} ref={target} />
                    <Overlay target={target.current} show={show} rootClose={true} onHide={() => setShow(false)} placement="bottom-end">
                        {tooltipDOM}
                    </Overlay>
                </Clipboard>
            </Col>
        </Row>;
    }, [props.apikey, show, tooltipDOM, target, t]);

    const userData = useMemo(() => {
        if ((!props.user || typeof props.userOrganization === 'undefined') && !props.apikeyOnly) {
            return <Col xs={12}><TextPlaceholder /></Col>;
        }
        if (props.apikeyOnly) {
            return <>{apikeyDom}</>;
        }
        if (props.user) {
            if (props.userOrganization === null) {
                return <>
                    <Col lg={6} md={8} sm={12}>
                        {apikeyDom}
                        <Row>
                            <Col lg={5} md={4} sm={4}> {t('Prevention API limit')} </Col>
                            <Col lg={7} md={8} sm={8} className="valuesText valueCol"> {props.preventionLimit} </Col>
                        </Row>
                        <Row>
                            <Col lg={5} md={4} sm={4}> {t('Reputation API limit')} </Col>
                            <Col lg={7} md={8} sm={8} className="valuesText valueCol"> {props.reputationLimit} </Col>
                        </Row>
                        <Row>
                            <Col lg={5} md={4} sm={4}> {t('Threat Intel API limit')} </Col>
                            <Col lg={7} md={8} sm={8} className="valuesText valueCol"> {props.limitThreatIntelSearch} </Col>
                        </Row>
                        <Row>
                            <Col lg={5} md={4} sm={4}> {t('Sandbox API limit')} </Col>
                            <Col lg={7} md={8} sm={8} className="valuesText valueCol"> {props.sandboxLimit} </Col>
                        </Row>
                        <Row>
                            <Col lg={5} md={4} sm={4}> {t('Daily feed limit')} </Col>
                            <Col lg={7} md={8} sm={8} className="valuesText valueCol"> {props.dailyFeedLimit} </Col>
                        </Row>
                        <Row>
                            <Col lg={5} md={4} sm={4}> {t('Paid user')} </Col>
                            <Col lg={7} md={8} sm={8} className="valuesText valueCol"> {t(props.paidUser)} </Col>
                        </Row>
                        <Row>
                            <Col lg={5} md={4} sm={4}> {t('Limit interval')} </Col>
                            <Col lg={7} md={8} sm={8} className="valuesText valueCol"> {t(props.limitInterval)} </Col>
                        </Row>
                        {props.expirationDate && <Row>
                            <Col lg={5} md={4} sm={4}> {t('Valid until')} </Col>
                            <Col lg={7} md={8} sm={8} className="valuesText valueCol"> {props.expirationDate} </Col>
                        </Row>}
                    </Col>
                    <Col lg={6} md={12}>
                        <Row className="group">
                            <Col xs={12} sm={8}>

                                <span className="contactText"
                                    // eslint-disable-next-line no-useless-escape
                                    dangerouslySetInnerHTML={{ __html: t('Contact <a href=\"https://www.opswat.com/contact\">OPSWAT sales</a> to increase your limits to best meet your needs') }} />
                            </Col>
                            <Col xs={12} sm={4}>
                                <Button variant="primary" className="buttons upgradeButton" onClick={handleUpdate}>
                                    {t('Upgrade limits')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </>;
            }
            return <>
                <Col xs={12}>
                    <Row>
                        <Col lg={6} md={8} sm={12}>
                            {apikeyDom}
                        </Col>
                    </Row>
                    {props.throttlingLimit > 0 && <Row>
                        <Col lg={6} md={8} sm={12}>
                            <Row>
                                <Col lg={5} md={4} sm={4}> {t('Organization Throttling Limit')} </Col>
                                <Col lg={7} md={8} sm={8} className="valueCol"> {props.throttlingLimit} </Col>
                            </Row>
                        </Col>
                    </Row>}
                </Col>

                <Col xs={12} className="text-md-left text-center">
                    <Row className="group">
                        <Col xs={12}>
                            <div className="contactText mt-md-4 text-md-left text-center">{t('Your limits are controlled by the organization.')}</div>
                            <a href={`${props.ssoIssuer}organization/overview`} rel="noopener noreferrer" target="_blank" className="d-block">
                                {t('View organization details')}
                            </a>
                        </Col>
                    </Row>
                </Col>
            </>;
        }
    }, [props, show, target, handleUpdate, refInput, t]);

    if (!ready) {
        return null;
    }

    return (
        <Row>
            <div className="apikeyInformation">
                <Row className="description">
                    <Col>
                        {t('API key information and limits')}
                    </Col>
                </Row>
                <Row>
                    {userData}
                </Row>
            </div>
        </Row>
    );
};

ApikeyInfo.propTypes = {
    apikey: PropTypes.string,
    preventionLimit: PropTypes.number,
    reputationLimit: PropTypes.number,
    limitThreatIntelSearch: PropTypes.number,
    sandboxLimit: PropTypes.number,
    dailyFeedLimit: PropTypes.number,
    paidUser: PropTypes.string,
    limitInterval: PropTypes.string,
    updateLimits: PropTypes.func,
    expirationDate: PropTypes.any,
    user: PropTypes.object,
    userOrganization: PropTypes.any,
    ssoIssuer: PropTypes.string,
    throttlingLimit: PropTypes.number,
    apikeyOnly: PropTypes.bool,
};
ApikeyInfo.defaultProps = {
    apikeyOnly: false
};

export default ApikeyInfo;
