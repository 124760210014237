/* eslint-disable security/detect-object-injection */
import { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../table-header/TableHeader';
import TableRow from '../table-row/TableRow';
import { sort } from 'fast-sort';
import { SORT_STATES } from '../sort-button/SortButton';
import { TableDataManager, UPDATE_REASON } from '@mdc/services';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import TablePlaceholder from '../../content-placeholder/TablePlaceholder';

import './DataTable.scss';

const DataTable = ({ columns, rowDataManager, useSorting, useInfiniteScroll }) => {
    const [rowModifiers, setRowModifiers] = useState({ sort: null });
    const [hasMoreData, setHasMoreData] = useState(true);
    const [rowData, setRowData] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const { t } = useTranslation();
    const updateReason = useRef(null);
    const isRequestingNewData = useRef(false);
    const tablePage = useRef(null);
    const tableColumns = useMemo(() => {
        return columns.map(({ Header, accessor, component, filter }) => {
            return {
                Header,
                accessor,
                component,
                filter,
                classes: `cell-${accessor}`
            };
        });
    }, [columns]);

    const columnsMeta = useMemo(() => {
        return tableColumns.map(({ accessor, classes, component }) => {
            return { accessor, classes, component };
        });
    }, [columns]);

    const onFilter = (filterObject) => {
        rowDataManager.updateFilter(filterObject);
    };

    const onSort = ({ accessor, state }) => {
        const currentModifiers = rowModifiers;
        setRowModifiers(Object.assign({}, currentModifiers, { sort: { accessor, state } }));
    };

    const sortTableData = (data) => {
        const { accessor, state } = rowModifiers.sort || {};
        const rowDataArr = [...data];
        if (!state) {
            return;
        }
        let sortedTableData;
        switch (state) {
            case SORT_STATES.UP:
                sortedTableData = sort(rowDataArr).asc((rowInfo) => {
                    return rowInfo[accessor];
                });
                break;
            case SORT_STATES.DOWN:
                sortedTableData = sort(rowDataArr).desc((rowInfo) => {
                    return rowInfo[accessor];
                });
                break;
            case SORT_STATES.INACTIVE:
                sortedTableData = data;
                break;
            default:
        }

        return sortedTableData;
    };

    const processedTableData = useMemo(() => {
        const modifiers = rowModifiers;
        let modifiedRowData = rowData;

        if (modifiers.sort) {
            modifiedRowData = sortTableData(modifiedRowData);
        }

        return modifiedRowData;
    }, [rowData, rowModifiers]);

    const rows = useMemo(() => {
        return processedTableData.map((rowInfo, index) => {
            const isOdd = (index + 1) % 2 === 1;
            let keyCounter = 0;
            return <TableRow isOdd={isOdd} key={keyCounter++} columnsMeta={columnsMeta} rowData={rowInfo}/>;
        });
    }, [rowData, rowModifiers]);

    const onRowDataUpdate = ({ data, reason }) => {
        switch (reason){
            case UPDATE_REASON.PAGE_CHANGE:
                setRowData(data);
                break;
            case UPDATE_REASON.FILTER:
                setRowData(data);
                if (!data.length) {
                    setHasMoreData(false);
                }
                break;
            case UPDATE_REASON.NEW_DATA:
                setRowData(data);
                setHasMoreData(true);
                setShowLoader(false);
                isRequestingNewData.current = false;
                break;
            case UPDATE_REASON.NO_MORE_DATA:
                setHasMoreData(false);
                setShowLoader(false);
                isRequestingNewData.current = false;
                break;
        }
        updateReason.current = reason;
    };

    const onStateUpdate = ({ reason }) => {
        if (reason === UPDATE_REASON.NO_MORE_DATA) {
            setHasMoreData(false);
            setShowLoader(false);
        }
    };

    const requestNewData = async () => {
        if (useInfiniteScroll && isRequestingNewData.current) {
            return;
        }
        tablePage.current = tablePage?.current + 1 || 1;
        isRequestingNewData.current = true;
        setShowLoader(true);
        rowDataManager.setPage(tablePage.current);
        await rowDataManager.requestNewData();
    };

    useEffect(() => {
        if (!rowDataManager) {
            return;
        }
        setRowData(rowDataManager.getPaginatedData(rowDataManager.getCurrentPage()));

        rowDataManager.on(rowDataManager.topics.DATA_UPDATE, onRowDataUpdate);
        rowDataManager.on(rowDataManager.topics.STATE_UPDATE, onStateUpdate);

        return function clean() {
            rowDataManager.on(rowDataManager.topics.DATA_UPDATE, onRowDataUpdate);
            rowDataManager.on(rowDataManager.topics.STATE_UPDATE, onStateUpdate);
        };
    }, [rowDataManager]);

    const noDataInfo = (
        <tr>
            <td colSpan={columns.length} className="dataTableNoData">
                <div>
                    {t('No data')}
                </div>
            </td>
        </tr>
    );


    const LoadingComponent = useMemo(() => {
        if (!showLoader) {
            return null;
        }

        return (
            <tr key="loader" className="loaderRow">
                <td colSpan={columns.length}>
                    <TablePlaceholder numberOfRows={10} numberOfColumns={5}/>
                </td>
            </tr>
        );
    }, [showLoader]);

    const hasData = rows.length > 0;

    const tableBody = useMemo(() => {
        const showNoData = !hasData && !showLoader;

        if (useInfiniteScroll) {
            return (
                <InfiniteScroll
                    element={'tbody'}
                    pageStart={0}
                    loadMore={requestNewData}
                    initialLoad={true}
                    loader={LoadingComponent}
                    treshhold={140}
                    useWindow={true}
                    hasMore={hasMoreData}>
                    {rows}
                    {showNoData && noDataInfo}
                </InfiniteScroll>
            );
        }

        return (
            <tbody>
                {rows}
                {showNoData && noDataInfo}
                {!hasData && updateReason.current !== UPDATE_REASON.NEW_DATA && LoadingComponent}
            </tbody>
        );
    }, [useInfiniteScroll, hasData, rows, hasMoreData, showLoader]);

    return (
        <table className="tableContainer table tableStriped" role="table">
            <TableHeader
                columns={tableColumns}
                onFilter={onFilter}
                onSort={onSort}
                useSorting={useSorting}/>
            {tableBody}
        </table>
    );
};

DataTable.propTypes = {
    columns: PropTypes.array.isRequired,
    rowDataManager: PropTypes.instanceOf(TableDataManager),
    useSorting: PropTypes.bool.isRequired,
    useInfiniteScroll: PropTypes.bool
};

export default DataTable;

