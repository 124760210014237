import isCidr from 'is-cidr';
import { isIP } from 'is-ip';

class IpRangeValidate {
    validate(value) {
        // eslint-disable-next-line security/detect-unsafe-regex
        return value && (isIP(value) || isCidr(value) || /^([01]?\d\d?|2[0-4]\d|25[0-5])(?:\.(?:[01]?\d\d?|2[0-4]\d|25[0-5])){3}(?:\/[0-2]\d|\/3[0-2])?$/.test(value));
    }
}

export default new IpRangeValidate();
