import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NOTIFICATION_TYPES } from '@mdc/constants';
import { NotificationContext } from '@mdc/ui';
import { StatusPageService, logService, localStorage } from '@mdc/services';

const INCIDENT_ID = 'incidentId';

const StatusNotification = ({ children }) => {
    const { t, ready } = useTranslation();
    const { notify } = useContext(NotificationContext);
    const [incidentID, setIncidentID] = useState(localStorage.getItem(INCIDENT_ID) || '');

    const handleClick = () => {
        localStorage.setItem(INCIDENT_ID, `${localStorage.getItem(INCIDENT_ID)}-dismissed`);
    };
    useEffect(() => {
        let isMounted = true;

        (async () => {
            try {
                const newIncidentID = await StatusPageService.getIncident();
                if (isMounted && newIncidentID && newIncidentID !== incidentID) {
                    setIncidentID(newIncidentID);
                }
            } catch (err) {
                logService.error(err);
            }
        })();

        return () => {
            isMounted = false;
        };
    }, []); // Empty dependency array to run only once on mount

    useEffect(() => {
        if (incidentID === 'No incidents') {
            return;
        }

        if (incidentID && incidentID.split('-')[1] !== 'dismissed') {
            notify({
                message: t('We are experiencing issues with our service. Check <a href="https://trust.opswat.com" rel="noopener noreferrer" target="_blank">our status page</a> for details'),
                type: NOTIFICATION_TYPES.INFO,
                alwaysVisible: true,
                handleClick: handleClick
            });
        }
    }, [incidentID]);


    if (!ready) {
        return null;
    }

    return <>
        {children}
    </>;
};

StatusNotification.propTypes = {
    children: PropTypes.node
};

export default StatusNotification;
