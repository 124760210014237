import GenericPubSub from '../generic-pub-sub/GenericPubSub';
import { authHelper } from './AuthHelper';
import metadefenderService from '../metadefender/MetadefenderService';
import { localStorage } from '../local-storage/LocalStorage';

const TOPICS = {
    ERROR: 'error',
    STATE_CHANGE: 'state-change'
};

class Authenticator {
    constructor() {
        this.events = new GenericPubSub(TOPICS);
        this.user = null;
        this.apikey = null;
        this.isInitialized = false;
    }

    getUser() {
        return this.user;
    }

    onStateChange(data) {
        const apikey = this.apikey;
        metadefenderService.addCommonHeader({ apikey });
        this.events._publish(this.events.topics.STATE_CHANGE, data);
    }

    async init() {
        if (this.isInitialized) {
            return;
        }

        let isLoggedIn = false;
        try {
            authHelper.initCsrfToken();

            const sso = authHelper.checkSSO();
            if (sso && sso.apikey) {
                localStorage.setItem('apiKey', sso.apikey);
                localStorage.setItem('submittedEmail', true);
                this.apikey = sso.apikey;
                isLoggedIn = true;
            }

            if (!this.apikey) {
                this.apikey = await authHelper.getFingerPrint();
                localStorage.setItem('apiKey', this.apikey);
            }

            try {
                this.user = await authHelper.checkApiKey(this.apikey, isLoggedIn);
            } catch (err) {
                this.events._publish(this.events.topics.ERROR, { message: err.message });
            }

            if (!isLoggedIn) {
                return this.onStateChange();
            }

            const reputation = await this.getReputationPoints();
            const updatedUser = Object.assign({}, sso, this.user, { reputation });

            this.user = updatedUser;
            this.apikey = this.user.apikey || this.user.portal_api_key;

            this.onStateChange(updatedUser);
        } catch (err) {
            this.events._publish(this.events.topics.ERROR, { message: err.message });
        }

        this.isInitialized = true;
    }

    async getReputationPoints() {
        const apikey = this.apikey;
        try {
            const [reputationPromise] = metadefenderService.apikey.getReputation({ apikey });
            const reputationResponse = await reputationPromise;
            return reputationResponse.data.reputation;
        } catch (e) {
            return 0;
        }
    }

    async logout() {
        await authHelper.logout();
        this.apikey = undefined;

        this.onStateChange();
    }

    authRedirect(url) {
        authHelper.redirect(url);
    }
}


export default new Authenticator();
