import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import './LightButton.scss';

const LightButton = (props) => {
    return <Button
        {...props}
        className={`${props.className ? props.className : ''} lightButton`}
        variant='dark'
    >
    </Button>;
};

LightButton.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    className: PropTypes.any
};

export default LightButton;
