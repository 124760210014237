import * as FileType from 'file-type';
import { FileInfoService, logService, metadefenderService, FileResultsService } from '..';
import { ERRORS } from '@mdc/constants';
import AdvancedOptions from '../advanced-options/AdvancedOptions';

const SANDBOX_GROUP_KEYS = ['sandbox'];
const ARCHIVE_FILE_EXT = ['zip', '7z', 'rar', 'gz', 'tar'];

class FileUploadService {
    constructor(file, maxFileSizeUpload) {
        const fileInfo = file instanceof FileInfoService ? file : new FileInfoService(file, maxFileSizeUpload);
        this.fileInfo = fileInfo;
        this.file = fileInfo.file;
        FileResultsService.esKey = undefined;
        this.onProgressCallback = function () { return; };
        this.onSuccessCallback = function () { return; };
        this.onErrorCallback = function () { return; };
    }

    onProgress(callback) {
        this.onProgressCallback = callback;
    }

    onSuccess(callback) {
        this.onSuccessCallback = callback;
    }

    _notifySuccess(response) {
        this.onSuccessCallback(response);
    }

    _getPercentDone(loaded) {
        const fileSize = this.file?.size;
        return Math.floor((loaded / fileSize) * 100);
    }

    _updateProgress({ loaded }) {
        const percentDone = this._getPercentDone(loaded);
        this.onProgressCallback(percentDone);
    }

    onError(callback) {
        this.onErrorCallback = callback;
    }

    _sendError(message, headers) {
        this.onErrorCallback(message, headers);
    }


    async _upload(data, headers) {
        try {
            const [uploadPromise] = metadefenderService.file.upload(data, headers, this._updateProgress.bind(this));
            const response = await uploadPromise;

            if (response.data?.esKey) {
                FileResultsService.esKey = response.data.esKey;
            }

            this._notifySuccess(response.data);
        } catch (err) {
            const response = err.response;

            if (response?.status === 429 && response?.data?.error?.code === 429000) {
                this._sendError(ERRORS.LIMIT_ERROR, response.headers);
                return;
            }

            this._sendError((response?.data?.error?.messages) || err.message);
        }
    }

    async _hashLookup(hash, advancedOptionsHeader) {
        try {
            const [lookupPromise] = metadefenderService.hash.getResults({ hash: hash });
            const response = await lookupPromise;

            this._notifySuccess(response.data);
        } catch (err) {
            const response = err.response;

            if (response?.status === 404 && response?.data?.error?.code === 404003) {
                this._processUpload(advancedOptionsHeader);
            } else {
                this._sendError((response?.data?.error?.message) || err.message);
            }
        }
    }

    async _processUpload(advancedOptionsHeader) {

        const file = this.file;
        const data = new FormData();
        const defaultHeaders = {
            'Content-Type': 'multipart/form-data'
        };
        let headers = {};

        // Remove 'hash-lookup' header if any
        delete advancedOptionsHeader['hash-lookup'];

        try {
            const fileType = await FileType.fromBlob(file);
            // If file is archive, remove all sandbox header
            if (fileType && ARCHIVE_FILE_EXT.includes(fileType.ext?.toLowerCase())) {
                SANDBOX_GROUP_KEYS.forEach((key) => {
                    if (advancedOptionsHeader[key]) {
                        delete advancedOptionsHeader[key];
                    }
                });
            }
        } catch (e) {
            // Ignore if check file type fail
            logService.error(e);
        }

        // Process 'rule' header
        if (advancedOptionsHeader.rule) {
            // eslint-disable-next-line require-atomic-updates
            advancedOptionsHeader.rule = AdvancedOptions.processRuleHeaders(advancedOptionsHeader.rule);
        }

        if (advancedOptionsHeader.rule === '') {
            // Delete rule header if no more
            delete advancedOptionsHeader.rule;
        }

        Object.assign(headers, defaultHeaders, advancedOptionsHeader);

        // eslint-disable-next-line no-prototype-builtins
        if (AdvancedOptions.hasHeader('rule') && defaultHeaders.hasOwnProperty('rule')) {
            const ruleSet = new Set(
                [...defaultHeaders.rule.split(','), ...advancedOptionsHeader.rule.split(',')]
            );
            const rules = [];
            ruleSet.forEach((rule) => {
                rules.push(rule);
            });
            headers.rule = rules.join(',');
        }

        data.append('file', file);

        await this._upload(data, headers);
    }

    async upload(isUserLogin) {
        const fileInfo = this.fileInfo;


        if (!fileInfo.isValid()) {
            this._sendError(ERRORS.FILE_SIZE_EXCEEDED);
            return;
        }

        if (fileInfo.isEmpty()) {
            this._sendError(ERRORS.FILE_EMPTY);
            return;
        }

        const advancedOptionsHeader = AdvancedOptions.getHeaders();

        if (advancedOptionsHeader.rule === 'multiscan' && !isUserLogin) {
            const md5HashGenerator = this.fileInfo.getMd5();
            const md5Hash = await md5HashGenerator;
            await this._hashLookup(md5Hash, advancedOptionsHeader);
        } else {
            this._processUpload(advancedOptionsHeader);
        }
    }
}

export default FileUploadService;
