import { useContext } from 'react';
import AdvancedOptionsProvider from './AdvancedOptionsProvider';
import { Button, Container, Modal, Navbar } from 'react-bootstrap';
import ADVANCED_OPTIONS from './Options';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@mdc/ui';

import './AdvancedOptionsFullSize.scss';

const AdvancedOptionsFullSize = ({ show, onAccept, onClose, options, onOptionClick }) => {
    const userContext = useContext(UserContext);
    const { t, ready } = useTranslation();

    const advancedOptionsProvider = new AdvancedOptionsProvider();
    const optionList = advancedOptionsProvider.getOptionsList(options, onOptionClick);

    const optionListOrdered = optionList.reduce((acc, item) => {
        if (item.props.isDisabled === true) {
            acc.disabled.push(item);
        } else if (item.props.hasSuboptions === true) {
            acc.withSuboptions.push(item);
        } else {
            acc.enabled.push(item);
        }
        return acc;
    }, { disabled: [], enabled: [], withSuboptions: [] });

    const subOptionsList = advancedOptionsProvider.getSubOptionsList(options, onOptionClick);

    const subOptionsListFragment = subOptionsList.length ? (
        <div className="subOptions">
            {subOptionsList}
        </div>
    ) : null;
    const multiScanOptionFragments = advancedOptionsProvider.getMultiScanOptionsList(options, onOptionClick);
    const paidOptionFragments = advancedOptionsProvider.getPaidOptions(options, onOptionClick, userContext?.isPaidUser, userContext?.data?.enforce_private_scan, userContext?.state === userContext.STATES.LOGGED_IN);
    const passwordOptionFragments = advancedOptionsProvider.getPasswordOptions(options, onOptionClick);

    const onAcceptClicked = () => {
        onAccept && onAccept();
    };
    const onCloseClicked = () => {
        onClose && onClose();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onCloseClicked();
        }
    };

    if (!ready) {
        return null;
    }

    return (
        <Modal
            show={show}
            onHide={onCloseClicked}
            dialogClassName='AdvancedOptionsFullSize'
            animation={false}
        >
            <Modal.Header>
                <Container>
                    <Navbar>
                        <Navbar.Brand href="/">
                            <div className="logo" />
                        </Navbar.Brand>
                        <Navbar.Collapse className="justify-content-end">
                            <Button
                                className="close-btn"
                                onClick={onCloseClicked}
                                onKeyDown={handleKeyDown}
                            >
                                <i className="icon icon-close" />
                            </Button>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <h6 className='h1'>{t('Advanced options')}</h6>
                    <div className='advancedOptionsList'>
                        {optionListOrdered.disabled}
                        <hr />
                        {multiScanOptionFragments}
                        <hr />
                        {optionListOrdered.enabled}
                        <hr />
                        <div className='passwordProtected'>
                            {passwordOptionFragments}
                        </div>
                        <hr />
                        {paidOptionFragments}
                        <hr />
                        {optionListOrdered.withSuboptions}
                        {subOptionsListFragment}
                    </div>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Button
                        onClick={onAcceptClicked}>
                        {t('Apply and Process')}
                    </Button>
                </Container>
            </Modal.Footer>
        </Modal>
    );
};

export default AdvancedOptionsFullSize;

AdvancedOptionsFullSize.defaultProps = {
    options: ADVANCED_OPTIONS
};

AdvancedOptionsFullSize.propTypes = {
    show: PropTypes.bool,
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onOptionClick: PropTypes.func,
    options: PropTypes.array,
};
