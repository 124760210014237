import { useContext, useEffect, useState } from 'react';
import NetPromoterScore from './NetPromoterScore';
import { localStorage, dayjs, metadefenderService, logService } from '@mdc/services';
import Cookies from 'js-cookie';
import UserContext from '../user-context/UserContext';
import { useTranslation } from 'react-i18next';
import { COOKIE_ALREADY_USED } from '@mdc/constants';

const NET_PROMOTER_SCORE_KEY = 'netPromoterScore';

const NetPromoterScoreManager = () => {
    const { t, ready } = useTranslation();
    const [show, setShow] = useState(false);
    const userContext = useContext(UserContext);

    const onClosed = () => {
        const store = {
            time: Date.now(),
            submitted: false,
        };
        localStorage.setItem(NET_PROMOTER_SCORE_KEY, store);
        setShow(false);
    };

    const onSubmit = (info) => {
        setShow(false);

        (async () => {
            const store = {
                time: Date.now(),
                submitted: false,
            };

            try {
                const data = {
                    score: info.score,
                    additionalFeedback: info.text || ''
                };
                await metadefenderService.apikey.submitNetPromoterScore(data)[0];
                store.submitted = true;
            } catch (err) {
                logService.error(err);
                if (err.response?.data?.error?.code === 429000) {
                    // If error is "Rate limit exceeded" (1 request/day), that means user already submitted today
                    // Skip and treat as submit successfully
                    store.submitted = true;
                }
            }
            localStorage.setItem(NET_PROMOTER_SCORE_KEY, store);
        })();
    };

    useEffect(() => {
        if (userContext.state === userContext.STATES.LOGGED_IN) {
            const storage = localStorage.getItem(NET_PROMOTER_SCORE_KEY);
            const isAlreadyUsed = Cookies.get(COOKIE_ALREADY_USED);
            const duration = dayjs.duration(Date.now() - (typeof storage?.time === 'number' ? storage?.time : 0));
            const isSubmitted = !!storage?.submitted;

            if (isAlreadyUsed && ((!isSubmitted && duration.asWeeks() >= 2) || (isSubmitted && duration.asMonths() >= 3))) {
                setShow(true);
            }
        }
    }, [userContext.state]);

    if (!ready) {
        return null;
    }

    return <>
        <NetPromoterScore
            onClosed={onClosed}
            onSubmit={onSubmit}
            title={t('How likely is that you will recommend our product to a friend or colleague?')}
            feedbackTitle={t('[Optional] Please give us more details of why you chose this score')}
            minScore={1}
            maxScore={10}
            negativeText={t('Not likely')}
            positiveText={t('Very likely')}
            show={show}
        />
    </>;
};

export default NetPromoterScoreManager;
