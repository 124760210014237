import BaseService from '../BaseService';

class UserService {
    constructor(instance) {
        this.instance = instance;
        this.base = new BaseService(this.instance);
    }

    getLeaderboard = (headers) => {
        return this.base.get('/user/leaderboard', headers);
    }
}

export default UserService;
