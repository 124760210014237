import PropTypes from 'prop-types';

const CveAffectedVersion = ({ value }) => {
    if (value.includes('-->')) {
        const [min, max] = value.split('-->');
        return (<span dangerouslySetInnerHTML={{ __html: min + ' <i class="icon icon-arrow-right"></i> ' + max }}/>);
    }
    return (<span dangerouslySetInnerHTML={{ __html: value }}/>);
};

CveAffectedVersion.propTypes = {
    value: PropTypes.string
};

export default CveAffectedVersion;
