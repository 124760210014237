import { Fragment } from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';

const TableRowPlaceholder = (props) => {
    const random = Math.random() * (1 - 0.7) + 0.7;
    return (
        <ContentLoader
            height={60}
            width={1060}
            speed={1}
            primaryColor="#EDEEF3"
            secondaryColor="#f3f3f3"
            {...props}
        >
            <rect x="0" y="13" rx="6" ry="6" width={300 * random} height="12" />
            <rect x="0" y="32" rx="6" ry="6" width={400 * random} height="12" />

            <rect x="553" y="20" rx="6" ry="6" width={120 * random} height="12" />
            <rect x="855" y="20" rx="6" ry="6" width={60 * random} height="12" />

            <rect x="0" y="58" rx="6" ry="6" width="1060" height=".3" />
        </ContentLoader>
    );
};

const TablePlaceholder = (props) => {
    const { numberOfRows } = props || 10;
    let keyCounter = 0;
    return (
        <Fragment>
            {Array(numberOfRows)
                .fill('')
                .map((e, i) => (
                    <TableRowPlaceholder key={keyCounter++} style={{ opacity: Number(2 / i).toFixed(1) }} />
                ))}
        </Fragment>
    );
};

TablePlaceholder.propTypes = {
    numberOfRows: PropTypes.number,
};

export default TablePlaceholder;
