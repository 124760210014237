import { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { logService, metadefenderService } from '@mdc/services';

import './VulnerabilityReportModal.scss';

const VulnerabilityReportModal = ({ fileName, hash, show, onClose, successCallback }) => {
    const { t, ready } = useTranslation();

    const noteRef = useRef(null);

    const [cve, setCve] = useState('');
    const [isCveValid, setIsCveValid] = useState(true);
    const [loadingCve, setLoadingCve] = useState(false);
    const [isExistCve, setIsExistCve] = useState(undefined);

    const [submittingCve, setSubmittingCve] = useState(false);

    const onCveChange = (e) => {
        const cveFile = e.target.value;
        setCve(cveFile);
        setIsExistCve(undefined);

        /* eslint-disable-next-line security/detect-unsafe-regex */
        const isValid = !cveFile || cveFile.match(/^((CVE-)?[1-2][\d]{3}-[\d]{4,8})?$/);
        setIsCveValid(isValid);
    };

    const onLoadCve = useMemo(() => () => {
        setLoadingCve(true);

        (async () => {
            try {
                const response = await metadefenderService.vulnerabilities.getInfo({ cve })[0];
                if (response?.data?.cve) {
                    setIsExistCve(true);
                    setLoadingCve(false);
                    return;
                }
            } catch (e) {
                /* eslint-disable-next-line no-console */
                logService.error(e);
            }

            setIsExistCve(false);
            setLoadingCve(false);
        })();
    }, [cve]);

    const onSubmit = useMemo(() => () => {
        setSubmittingCve(true);
        (async () => {
            try {
                const data = {
                    'fileName': fileName,
                    'hash': hash,
                    'note': noteRef.current.value,
                    'cveId': cve,
                    'scanLink': window.location.href
                };

                const response = await metadefenderService.apikey.saveVulnerability(data)[0];
                if (response?.data?.message) {
                    successCallback(response.data.message);
                }
            } catch (e) {
                /* eslint-disable-next-line no-console */
                logService.error(e);
            }
            setSubmittingCve(false);
        })();
    }, [cve, successCallback]);

    const getCveExistValue = (isExistCve) => {
        if (isExistCve === true) {
            return t('CVE found in our records');
        } else if (isExistCve === false) {
            return t('CVE was not found in our records. You will add new one');
        }

        return '';
    };

    if (!ready) {
        return null;
    }

    return (
        <Modal
            show={show}
            size='md'
            onHide={onClose}
            dialogClassName='vulnerabilityReportModal'
            animation={false}
            centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>
                        {t('File Information')}
                    </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {t('If you are aware of any binary that is associated with specific known vulnerability, report it here for review and help to improve the OPSWAT vulnerability technology and internet safety')}
                </p>
                <Form.Group>
                    <Form.Label>{t('Hash')}</Form.Label>
                    <Form.Control value={hash} readOnly/>
                </Form.Group>

                <Form.Group>
                    <Form.Label>{t('File Name')}</Form.Label>
                    <Form.Control value={fileName} readOnly/>
                </Form.Group>

                <Form.Group>
                    <Form.Label>{t('Note')}</Form.Label>
                    <Form.Control as={'textarea'} ref={noteRef} placeholder={t('Please provide more information about the potential vulnerability')}/>
                </Form.Group>

                <h3 className='mb-4'>
                    {t('CVE Information')}
                </h3>

                <Form.Group>
                    <Form.Label>{t('CVE')}</Form.Label>
                    <InputGroup>
                        <FormControl
                            onChange={onCveChange}
                            className={classnames('cveInput', { 'inValid': !isCveValid })}
                            placeholder="CVE-####..."
                            disabled={loadingCve}
                        />
                        <InputGroup.Append>
                            <Button onClick={onLoadCve} disabled={!cve || !isCveValid || loadingCve}>
                                {loadingCve ? t('Loading') : t('Load CVE')}
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <Form.Text className={classnames('cveInfo', 'text-muted', { 'exist': isExistCve, 'inValid': !isCveValid })}>
                        {(cve && !isCveValid) ? t('Wrong pattern for CVE') : getCveExistValue(isExistCve)}
                    </Form.Text>
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button disabled={!isCveValid || loadingCve || submittingCve} onClick={onSubmit}>
                    {t('Submit')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

VulnerabilityReportModal.propTypes = {
    fileName: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    successCallback: PropTypes.func.isRequired
};

export default VulnerabilityReportModal;
