import BaseService from '../BaseService';

class VulnerabilitiesService {
    constructor(instance) {
        this.instance = instance;
        this.base = new BaseService(this.instance);
    }

    getInfo = (params, headers) => {
        return this.base.get(`/cve/${params.cve}`, headers);
    };

    getCves = (headers) => {
        return this.base.get('/cve', headers);
    };

    getVulnerability = (data, headers) => {
        return this.base.get(`/vulnerability/${data.hash}?limit=${data.limit ? data.limit : 25}&offset=${data.offset ? data.offset : 0}`, headers);
    };
}

export default VulnerabilitiesService;
