import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { Col, Row } from 'react-bootstrap';
import ExpandableCell from './ExpandableCell';
import sanitizeHtml from 'sanitize-html';

import './HorizontalTable.scss';

const HorizontalTable = ({ title, data, expandableData, placeHolderRows }) => {
    const { t, ready } = useTranslation();

    const lineDom = <div className="line"></div>;
    let keyCounter = 0;
    const horizontalTablePlaceholderDom = useMemo(() => <Row>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <ContentLoader
                height={30 * placeHolderRows}
                width={400}
                speed={1}
                primaryColor="#EDEEF3"
                secondaryColor="#f3f3f3"
            >
                {Array(placeHolderRows).fill('').map((row, index) => <rect key={keyCounter++} x="0" y={10 + index * 30} rx="4" ry="4" width={100 + 250 * Math.random()} height="11"/>)}
            </ContentLoader>
        </Col>
    </Row>, [placeHolderRows]);

    const horizontalTableDom = useMemo(() => {
        if (!data) {
            return <Row className='horizontalTableRow'>
                <section className='horizontalTableWrapper'>
                    {horizontalTablePlaceholderDom}
                </section>
            </Row>;
        }

        if (!data.length) {
            return null;
        }

        return <Row className='horizontalTableRow'>
            <section className='horizontalTableWrapper'>
                {
                    title &&
                <>
                    <h6>{t(title)}</h6>
                    {lineDom}
                </>
                }
                <table className='horizontalTable'>
                    <tbody>
                        {
                            data.map((entry) => {
                                const sanitizedData = entry?.shouldSanitizeHTML && sanitizeHtml(entry.data, {
                                    allowedAttributes: {
                                        a: ['href', 'name', 'target', 'rel'],
                                        img: ['src']
                                    } },
                                );

                                return <tr key={entry?.name}>
                                    <td className='lighterText noMarginTop'>{t(entry.field)}</td>
                                    {entry?.shouldSanitizeHTML ? <td dangerouslySetInnerHTML={{ __html: sanitizedData }}/> : <td>{entry.data}</td>}
                                </tr>;
                            })
                        }
                        {
                            expandableData && expandableData.map((entry) => {
                                return <ExpandableCell key={entry?.name} entryName={entry.name} entryFunctions={entry.functions}/>;
                            })
                        }
                    </tbody>
                </table>
            </section>
        </Row>;
    }, [data, expandableData, t]);

    if (!ready) {
        return null;
    }

    return (
        <>
            { horizontalTableDom }
        </>
    );
};

HorizontalTable.propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    expandableData: PropTypes.array,
    placeHolderRows: PropTypes.number
};

HorizontalTable.defaultProps = {
    placeHolderRows: 6
};

export default HorizontalTable;
