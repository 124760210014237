import { useMemo, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './Leaderboard.scss';
import PropTypes from 'prop-types';
import { i18nUtility, dayjs, QueryParams } from '@mdc/services';
import { useTranslation } from 'react-i18next';
import LeaderboardTable from './LeaderboardTable';
import DatesDropdown from './DatesDropdown';

const Leaderboard = ({ availableDates, leaderboard, updateDate, title, description, color }) => {
    const { date } = QueryParams();
    const queryDate = date ? date : 'latest';
    const { t, ready } = useTranslation();

    const currentDate = useMemo(() => {
        const isJpFormatDate = dayjs.locale(i18nUtility.lookupLang())==='en'? dayjs().format('MMMM YYYY') : dayjs().format('YYYY MMMM');
        if (date && date.toLowerCase() !== 'latest') {
            return dayjs(date).format('MMMM YYYY');
        }
        return isJpFormatDate;

    }, [QueryParams(), t, i18nUtility.lookupLang()]);

    useEffect(() => {
        window.history.replaceState('', '', `?date=${typeof date !== 'undefined' && date.toLowerCase() !== 'latest' ? date : 'latest'}`);
    }, []);

    if (!ready) {
        return null;
    }

    return (
        <div className = "topVoters">
            <Row>
                <Col>
                    <div className = "titleText">
                        <i className = "icon-user-check userIcon"/>
                        <h2>{t(title)}</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={10} md={10}>
                    <div className= "leaderboardText">
                        {currentDate}  {t(description)}
                    </div>
                </Col>
                <Col xs={12} sm={2} md={2}>
                    <span className = "dateGroup">
                        <DatesDropdown
                            queryDate={queryDate}
                            availableDates={availableDates}
                            updateDate={updateDate}
                        />
                    </span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <LeaderboardTable
                        leaderboard={leaderboard}
                        color={color}
                    />
                </Col>
            </Row>
        </div>
    );
};

Leaderboard.propTypes = {
    availableDates: PropTypes.array.isRequired,
    leaderboard: PropTypes.array.isRequired,
    updateDate: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
};

export default Leaderboard;
