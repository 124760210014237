import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { getLocalisedOpswatLink } from '@mdc/services';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import './Resources.scss';

const Resources = ({ gatsbyLinks }) => {
    const { t, ready } = useTranslation();
    const [resources, setResources] = useState();

    useEffect(() => {
        setResources(
            <Container className="resources">
                <p className='h4Mask'>
                    {t('Resources')}
                </p>

                <ul>
                    <li>
                        <OutboundLink href="https://docs.opswat.com/mdcloud\"
                            rel='noopener noreferrer'
                            target='_blank'>
                            {t('APIs')}
                        </OutboundLink>
                    </li>
                    <li>
                        <OutboundLink href={getLocalisedOpswatLink('https://www.opswat.com/research-center')}
                            rel='noopener noreferrer'
                            target='_blank'>
                            {t('Reports')}
                        </OutboundLink>
                    </li>
                    <li>
                        <OutboundLink href="https://docs.opswat.com/mdcloud"
                            rel='noopener noreferrer'
                            target='_blank'>
                            {t('Developers')}
                        </OutboundLink>
                    </li>
                    <li>
                        {gatsbyLinks.marketShare}
                    </li>
                    <li>
                        {gatsbyLinks.deepCDR}
                    </li>
                    <li>
                        {gatsbyLinks.packageEfficacy}
                    </li>
                    <li>
                        {gatsbyLinks.malwareOutbreak}
                    </li>
                    <li>
                        {gatsbyLinks.leaderboard}
                    </li>
                    <li>
                        {gatsbyLinks.appstore}
                    </li>
                    <li>
                        {gatsbyLinks.store}
                    </li>
                </ul>
            </Container>
        );
    }, [gatsbyLinks, t]);

    if (!ready) {
        return null;
    }

    return <>
        {resources}
    </>;
};

Resources.propTypes = {
    gatsbyLinks: PropTypes.object
};


export default Resources;
