// eslint-disable-next-line no-unused-vars
import React, { useMemo } from 'react';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Filter.scss';

const Filter = ({ items, selectedItem, handleUpdate, icon, alignRight = false }) => {
    const processedItems = useMemo(() => items.map((currentItem) => {
        return <Dropdown.Item
            key={currentItem.key}
            eventKey={currentItem.key}
            onClick={() => handleUpdate(items.find((service) => service.key === currentItem.key))}
            className={selectedItem.label === currentItem.label ? 'dropdownItem selectedItem' : 'dropdownItem'}
        >
            {currentItem.label}
        </Dropdown.Item>;
    }), [selectedItem, items]);

    const dropdownButtonClassNames = classNames({
        'alignRight': alignRight
    }, 'customFilter');

    const iconDom = useMemo(() => {
        if (!icon) {
            return;
        }

        return <i className={`icon ${icon}`}/>;
    }, [icon]);

    const currentSelectionDom = useMemo(() => {
        return <span className='currentSelection'>{iconDom}{selectedItem.label}</span>;
    }, [selectedItem, items]);

    return <DropdownButton
        as={ButtonGroup}
        title={currentSelectionDom}
        className={dropdownButtonClassNames}
    >
        {processedItems}
    </DropdownButton>;
};

Filter.propTypes = {
    selectedItem: PropTypes.object,
    handleUpdate: PropTypes.func,
    items: PropTypes.array.isRequired,
    icon: PropTypes.string,
    alignRight: PropTypes.bool
};

export default Filter;
