import { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactFlow, { ReactFlowProvider, Controls, isNode, } from 'react-flow-renderer';
import { Tooltip } from 'react-tooltip';
import CustomNodeComponent from './CustomNodeComponent';
import './ProcessesDiagram.scss';
import dagre from 'dagre';

const position = { x: 0, y: 0 };

const generateTooltipBody = (obj) => {
    const body = Object.entries(obj).map(([key, value]) => {
        if (key === 'metaData') {
            return (
                <div className='child'>
                    <div className='label'>{key.replace(/_/g, ' ')}:</div>
                    <pre>{JSON.stringify(value, null, 4)}</pre>
                </div>
            );
        }

        if (key === 'Command') {
            return (
                <div className='child'>
                    <div className='label'>{key.replace(/_/g, ' ')}:</div>
                    <div className='box'>{value?.toString()}</div>
                </div>
            );
        }

        if (Array.isArray(value) && value.length !== 0) {
            return (
                <div className='child'>
                    <div className='label'>{key.replace(/_/g, ' ')}:</div>
                    <div className='array-box'>
                        {value.map((item) => (
                            <div key={item?.toString()}>
                                {item?.toString()}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        if (['string', 'number', 'boolean'].includes(typeof value)) {
            return (
                <div className='child'>
                    <div className='label'>{key.replace(/_/g, ' ')}:</div>
                    <div>{value?.toString()}</div>
                </div>
            );
        }

        if (typeof value === 'object' && Object.keys(value).length !== 0) {
            return (
                <div>
                    <div className='label'>{key.replace(/_/g, ' ')}:</div>
                    <div className='children'>{generateTooltipBody(value)}</div>
                </div>
            );
        }
    });

    return body;
};

const ProcessesDiagram = ({ sandboxResult }) => {
    const allowedActions = {
        'StartProcess': 'Process',
        'CreateFile': 'File',
        'OpenWordDocument': 'File',
        'ShellCreatedFile': 'File',
        'WriteTextFile': 'File',
        'WriteBinaryFile': 'File',
        'CopyFile': 'File',
        'MoveFile': 'File',
        'CopyProgram': 'File',
        'MoveProgram': 'File',
        'HttpRequest': 'Network',
        'ExecuteScript': 'Script',
        'ExecuteSchedulerJob': 'Execute',
        'ExecQuery': 'Execute'
    };

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 50;
    const nodeHeight = 70;
    let initialElements = [];

    const icons = {
        'icon-terminal': ['iexplore', 'outlook', 'explorer', 'powershell'],
        'icon-file-word-solid': ['winword'],
    };

    const onLoad = (reactFlowInstance) => {
        return reactFlowInstance.fitView();
    };

    const checkForChildren = (currentItem, lastItem) => {
        let icon;
        Object.keys(icons).forEach((key) => {
            if (currentItem.additionalInformation.Program && icons[key].includes(currentItem.additionalInformation.Program)) {
                icon = key;
            }
            if (currentItem?.action === 'HttpRequest') {
                icon = 'icon-network';
            }
            return icon;
        });

        const node = {
            id: `horizontal-${currentItem.dataUUID}`,
            type: 'special',
            sourcePosition: 'right',
            targetPosition: 'left',
            data: {
                icon: icon || 'icon-file-solid',
                ...currentItem,
                actionLabel: allowedActions[currentItem.action],
            },
            position: position,
        };

        const line = {
            id: `horizontal-${currentItem.dataUUID}`,
            source: lastItem.id,
            type: 'smoothstep',
            target: `horizontal-${currentItem.dataUUID}`,
        };

        initialElements.push(node);
        initialElements.push(line);
    };

    const processedElements = useMemo(() => {
        const filtredResults = sandboxResult?.filter((item) => Object.keys(allowedActions).includes(item.action));
        let lastItemPid = null;
        let indexOf = null;
        let lastItem = null;
        let parentId = null;

        filtredResults.map((item, index) => {
            const { dataUUID, ...reducedItem } = item;

            if (item.additionalInformation.ParentPid === '00000000') {
                let icon;
                parentId = dataUUID;

                Object.keys(icons).forEach((key) => {
                    if (item.additionalInformation.Program && icons[key].includes(item.additionalInformation.Program)) {
                        icon = key;
                        return icon;
                    }
                });

                const node = {
                    id: `horizontal-${dataUUID}`,
                    type: 'special',
                    sourcePosition: 'right',
                    targetPosition: 'left',
                    data: {
                        icon: icon || 'icon-file-solid',
                        ...reducedItem,
                        actionLabel: allowedActions[item.action],
                    },
                    position: position,
                };

                initialElements.push(node);
                lastItemPid = item.additionalInformation.Pid;
                indexOf = initialElements.indexOf(node);
            }

            if (item.additionalInformation.ParentPid === lastItemPid) {

                lastItem = initialElements[indexOf];

            } else {

                lastItem = initialElements.slice(-1)[0];
            }

            index < filtredResults.length && item.dataUUID !== parentId && checkForChildren(item, lastItem);

            return initialElements;
        });


        return initialElements;

    }, [sandboxResult]);


    const getLayoutedElements = (elements, direction = 'LR') => {
        dagreGraph.setGraph({ rankdir: direction });

        elements.forEach((el) => {
            if (isNode(el)) {
                dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
            } else {
                dagreGraph.setEdge(el.source, el.target);
            }
        });

        dagre.layout(dagreGraph);

        return elements.map((el) => {
            if (isNode(el)) {
                const nodeWithPosition = dagreGraph.node(el.id);
                el.targetPosition = 'left';
                el.sourcePosition = 'right';

                el.position = {
                    x: (nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000) * 2,
                    y: nodeWithPosition.y - nodeHeight / 2,
                };
            }

            return el;
        });
    };

    const layoutedElements = getLayoutedElements(processedElements);

    const renderTooltip = ({ activeAnchor }) => {
        const validData = activeAnchor?.getAttribute('data-item');
        const item = validData && JSON.parse(validData);

        if (!item) {
            return;
        }

        const { actionLabel, ...data } = item;
        const tooltipBody = generateTooltipBody(data);

        return (
            <>
                <div className='title'>{actionLabel}</div>
                <div className='body'>{tooltipBody}</div>
            </>
        );
    };

    const ReacFlowDom = useMemo(() => {
        if (!layoutedElements) {
            return;
        }
        return <ReactFlowProvider>
            <ReactFlow
                elements={layoutedElements}
                connectionLineType='smoothstep'
                onLoad={onLoad}
                nodeTypes={{ special: CustomNodeComponent }}
                nodesDraggable={false}
                nodesConnectable={false}
            />
            <Controls className='controls'
                showInteractive={false}
            />
            <Tooltip
                id='customNode'
                clickable={true}
                variant='light'
                opacity={1}
                place='bottom'
                position={{ x: 0, y: 0 }}
                noArrow={true}
                delayHide={1000}
                border='1px solid #e9ecf2'
                className='tooltip-node'
                render={renderTooltip}
            />
        </ReactFlowProvider>;
    }, [layoutedElements]);

    return (
        <div className='layoutflow'>
            {ReacFlowDom}
        </div>
    );
};

ProcessesDiagram.propTypes = {
    sandboxResult: PropTypes.object,
    activeAnchor: PropTypes.any,
};

export default ProcessesDiagram;
