import { useEffect, useRef, useState, useMemo, useContext, useCallback } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import IconButton from '../common/icon-button/IconButton';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Toast from 'react-bootstrap/Toast';
import { dayjs, InternalNavigation, localStorage } from '@mdc/services';
import { InAppNotificationContext } from '@mdc/ui';
import ContentLoader from 'react-content-loader';

import './NotificationPopup.scss';

const NOTIFICATION_COUNT = 'notificationCount';

const NotificationPopup = () => {
    const [show, setShow] = useState(false);
    const wrapperRef = useRef(null);
    const popupTriggerRef = useRef(null);
    const { t, ready } = useTranslation();
    const notificationCount = localStorage.getItem(NOTIFICATION_COUNT);
    const [readNotificationCount, setReadNotificationCount] = useState(notificationCount);

    const inAppNotificationContext = useContext(InAppNotificationContext);
    localStorage.setItem(NOTIFICATION_COUNT, inAppNotificationContext?.data?.length || 0);

    const handleSettingsClick = () => {
        //redirect to settings page
        InternalNavigation.navigation('/account/settings/notifications');
    };

    const handleClick = ({ target }) => {
        const triggerElem = popupTriggerRef?.current;
        const wrapperElem = wrapperRef?.current;

        if (!show && triggerElem.contains(target)) {
            setShow(true);
        } else if ((show && wrapperElem && !wrapperElem.contains(target))) {
            setShow(false);
        }
    };

    const handleOpenNotificationClick = useCallback(() => {
        if (inAppNotificationContext?.data?.length && inAppNotificationContext?.data?.length !== readNotificationCount) {
            setReadNotificationCount(inAppNotificationContext?.data?.length);
        }
    }, [inAppNotificationContext]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return function cleanup() {
            document.removeEventListener('mousedown', handleClick);
        };
    });

    const toastNotificationDom = useMemo(() => {
        if (!inAppNotificationContext?.data) {
            let keyCounter = 0;
            return Array(3).fill('').map(() => <Row key={keyCounter++} className='contentLoader'> <ContentLoader height='45' width='100%' viewBox={'0 0 300 45'}>
                <rect x='3%' y='3' rx='4' ry='4' width={`${30 + 30 * Math.random()}%`} height='12' />
                <rect x='3%' y='18' rx='4' ry='4' width={`${60 + 37 * Math.random()}%`} height='12' />
                <rect x='3%' y='33' rx='4' ry='4' width={`${60 + 37 * Math.random()}%`} height='12' />
            </ContentLoader>
            </Row>);
        }

        if (!inAppNotificationContext.data.length) {
            return <Row>
                <span className='noNotifications'>{t('No notifications yet')}</span>
            </Row>;
        }

        const getDurationText = (duration, item) => {
            if (duration < 60) {
                return `${duration} minutes ago`;
            }

            return duration / 60 < 24 ? `${Math.round(duration / 60)} hours ago` : dayjs(item.timestamp).format('YYYY-MM-DD hh:mm:ss');
        };

        return inAppNotificationContext.data.sort((a, b) => Math.round(dayjs.duration(dayjs(b.timestamp).diff(a.timestamp)).asMinutes())).map((item) => {
            const now = dayjs();
            const duration = Math.round(dayjs.duration(dayjs(now).diff(item.timestamp)).asMinutes());
            const durationInHours = getDurationText(duration, item);
            return <Row key={item?.id}>
                <Toast>
                    <Toast.Header closeButton={false}>
                        <strong className='me-auto'>{item?.title?.replace('Whitelist', 'Allowlist')}</strong>
                        <small>{durationInHours}</small>
                    </Toast.Header>
                    <Toast.Body>{item?.message}</Toast.Body>
                </Toast>
            </Row>;
        });
    }, [inAppNotificationContext]);

    if (!ready) {
        return null;
    }

    return (<span className='popup'>
        <IconButton iconClass='icon-bell' ref={popupTriggerRef} onClick={handleOpenNotificationClick}>
            {readNotificationCount < inAppNotificationContext?.data?.length && <i className='icon-plus'></i>}
        </IconButton>
        <Collapse in={show}>
            <div className='popupWrapper' ref={wrapperRef}>
                <Row>
                    <IconButton iconClass='icon-settings' onClick={handleSettingsClick} />
                    <h2>{t('Notifications')}</h2>
                </Row>
                <Col lg={12}>
                    {toastNotificationDom}
                </Col>
            </div>
        </Collapse>
    </span>
    );
};

export default NotificationPopup;
