import PropTypes from 'prop-types';
import { dayjs } from '@mdc/services';

const epochToDateTimeGmt = (input) => {
    let d = new Date(0);
    d.setUTCSeconds(input);
    return dayjs.utc(d.toISOString()).local().format('YYYY-MM-DD HH:mm:ss') + ' GMT';
};

epochToDateTimeGmt.propTypes = {
    input: PropTypes.number
};

export default epochToDateTimeGmt;
