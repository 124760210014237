import PropTypes from 'prop-types';
import { dayjs } from '@mdc/services';

const DEFAULT_LANGUAGE = 'en';

const getDateTimeString = (input, lang) => {
    if (typeof (input) !== 'undefined' && input !== '') {
        dayjs.locale(lang || DEFAULT_LANGUAGE);
        return dayjs.utc(input).local().format('YYYY-MM-DD HH:mm:ss');
    }
    return '-';
};

getDateTimeString.propTypes = {
    input: PropTypes.any,
    lang: PropTypes.string
};

const getDateHourString = (input, lang) => {
    if (typeof (input) !== 'undefined' && input !== '') {
        dayjs.locale(lang || DEFAULT_LANGUAGE);
        return dayjs.utc(input.toString()).utcOffset(7).local().format('HH:mm:ss');
    }
    return '-';
};

getDateHourString.propTypes = {
    input: PropTypes.any,
    lang: PropTypes.string
};

const getDateString = (input, lang) => {
    if (typeof (input) !== 'undefined' && input !== '') {
        dayjs.locale(lang || DEFAULT_LANGUAGE);
        return dayjs.utc(input).local().format('YYYY-MM-DD');
    }
    return '-';
};

getDateString.propTypes = {
    input: PropTypes.any,
    lang: PropTypes.string
};

const dateHumanized = (input, lang) => {
    if (typeof (input) !== 'undefined' && input !== '') {
        const date = dayjs(input);
        const difference = dayjs.utc().diff(date);
        dayjs.locale(lang || DEFAULT_LANGUAGE);
        return dayjs.duration(difference).humanize();
    }
    return '-';
};

dateHumanized.propTypes = {
    input: PropTypes.any,
    lang: PropTypes.string
};

const getDurationHumanized = (input, lang) => {
    if (typeof input === 'number') {
        dayjs.locale(lang || DEFAULT_LANGUAGE);
        return dayjs.duration(input).humanize();
    }
    return '-';
};

getDurationHumanized.propTypes = {
    input: PropTypes.number,
    lang: PropTypes.string
};

export default { getDateTimeString, dateHumanized, getDurationHumanized, getDateString, getDateHourString };
