import GenericPubSub from '../generic-pub-sub/GenericPubSub';
/* eslint-disable security/detect-object-injection */
export const UPDATE_REASON = {
    PAGE_CHANGE: 'page change',
    FILTER: 'filter',
    NEW_DATA: 'new data',
    REQUEST_NEW_DATA: 'requesting new data',
    NO_MORE_DATA: 'no more data'
};

const TOPICS = {
    DATA_UPDATE: 'data-update',
    STATE_UPDATE: 'state-update'
};

class TableDataManager extends GenericPubSub {
    constructor({ rowData, rowsPerPage, getNewDataHandler, isInfiniteScrolling, initialPage }) {
        super(TOPICS);

        this.isInfiniteScrolling = isInfiniteScrolling || false;
        this.initialData = rowData || [];
        this.modifiedData = null;
        this.rowsPerPage = rowsPerPage;
        this.currentPage = initialPage || 1;
        this.getNewDataHandler = getNewDataHandler;
        this.modifiers = {};
    }

    setPage(newPage, reason) {
        this.currentPage = newPage;

        if (this.isInfiniteScrolling) {
            return;
        }

        const updatedRowData = this.getPaginatedData(newPage);
        this._publish(TOPICS.DATA_UPDATE, { data: updatedRowData, reason: reason || UPDATE_REASON.PAGE_CHANGE });
    }

    getCurrentPage() {
        return this.currentPage;
    }

    getTotalRows() {
        const modifiedData = this.modifiedData;
        return Array.isArray(modifiedData) && modifiedData.length || this.initialData.length;
    }

    getTotalPages() {
        const rowsPerPage = this.rowsPerPage;
        const totalRows = this.getTotalRows();
        if (!rowsPerPage) {
            return null;
        }
        return Math.ceil(totalRows/rowsPerPage);
    }

    getPaginatedData (pageNumber) {
        const initialData = this.initialData;
        const rowsPerPage = this.rowsPerPage;
        const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * rowsPerPage;
        const endIndex = ((pageNumber - 1) * rowsPerPage) + rowsPerPage;
        const modifiedData = this.modifiedData;

        if (modifiedData) {
            if (rowsPerPage) {
                return modifiedData.slice(startIndex, endIndex);
            }
            return modifiedData;
        }

        if (rowsPerPage) {
            return initialData.slice(startIndex, endIndex);
        }
        return initialData;
    }

    getState () {
        const modifiers = this.modifiers;
        const currentPage = this.currentPage;
        const totalRows = this.initialData.length;

        return { modifiers, currentPage, totalRows };
    }
    getInitialData() {
        return this.initialData;
    }

    clearModifiedData() {
        this.modifiedData = null;
    }

    updateFilter(filterObject) {
        const modifiers = this.modifiers;

        modifiers.filters = filterObject;
        this._filterData();
    }

    _filterData(preventUpdatePublish) {
        const initialData = this.initialData;
        const filterObject = this.modifiers.filters;

        if (!filterObject) {
            return;
        }

        const filterAccessors = Object.keys(filterObject).filter((filterAccessor) => {
            return filterObject[filterAccessor].length;
        });

        if (!filterAccessors.length) {
            this.clearModifiedData();
        } else {
            this.modifiedData = initialData.filter((rowData) => {
                let passedConditions = 0;
                filterAccessors.forEach((accessor) => {
                    const filteredValuesArr = filterObject[accessor];
                    if (filteredValuesArr.includes(rowData[accessor])) {
                        passedConditions += 1;
                    }
                });
                return filterAccessors.length === passedConditions;
            });
        }

        // Return to page 1 when filter updated
        if (!preventUpdatePublish) {
            this.setPage(1, UPDATE_REASON.FILTER);
        }
    }

    updateData (data) {
        const currentInitialData = this.initialData;

        if (!data || !data.length) {
            return;
        }

        this.initialData = [...currentInitialData, ...data];
        const preventUpdatePublishing = true;
        this._filterData(preventUpdatePublishing);
        const paginatedData = this.getPaginatedData(this.getCurrentPage());
        this._publish(TOPICS.DATA_UPDATE, { data: paginatedData, reason: UPDATE_REASON.NEW_DATA });
    }

    async requestNewData() {
        const newDataHandler = this.getNewDataHandler;

        if (newDataHandler) {
            this._publish(TOPICS.STATE_UPDATE, { reason: UPDATE_REASON.REQUEST_NEW_DATA });
            let newData;
            const managerState = this.getState();
            try {
                newData = await newDataHandler(managerState);
            } catch (rejectionReason) {
                if (rejectionReason instanceof Error) {
                    throw new Error(rejectionReason);
                } else if (Array.isArray(rejectionReason) && !rejectionReason.length) {
                    this._publish(TOPICS.STATE_UPDATE, { reason: UPDATE_REASON.NO_MORE_DATA });
                }
            }
            this.updateData(newData);
        }
    }
}

export default TableDataManager;
