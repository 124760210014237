import { FileInfoService, FileUploadService } from '..';
import { SCAN_STATES, ERRORS } from '@mdc/constants';

class FileScanService {
    constructor(file, maxFileSizeUpload) {
        this.maxFileSizeUpload = maxFileSizeUpload;
        this.file = new FileInfoService(file, maxFileSizeUpload);
        this.onProgressCallback = function () { return; };
        this.onErrorCallback = function () { return; };
    }

    onProgress(callback) {
        this.onProgressCallback = callback;
    }

    _updateProgress(status, data) {
        const dataObj = Object.assign({
            percent: null,
            hash: null,
            data_id: null
        }, data);
        this.onProgressCallback(status, dataObj);
    }

    onError(callback) {
        this.onErrorCallback = callback;
    }

    _sendError(message, headers) {
        this.onErrorCallback(message, headers);
    }

    async scan(isUserLogin) {
        const file = this.file;
        const fileUploadService = new FileUploadService(file, this.maxFileSizeUpload);

        if (!file.isValid()) {
            this._sendError(ERRORS.FILE_SIZE_EXCEEDED);
            return;
        }

        if (file.isEmpty()) {
            this._sendError(ERRORS.FILE_EMPTY);
            return;
        }

        fileUploadService.onError((error, headers) => {
            this._sendError(error, headers);
        });

        fileUploadService.onProgress((percent) => {
            if (percent < 100) {
                this._updateProgress(SCAN_STATES.UPLOAD, { percent });
            }
        });

        fileUploadService.onSuccess(({ data_id }) => {
            this._updateProgress(SCAN_STATES.UPLOAD_COMPLETE, { data_id });
        });

        fileUploadService.upload(isUserLogin);

    }

}

export default FileScanService;
