import { useMemo } from 'react';

export const PDF_HEADER_VALUES_ARCHIVE = (t) => [[
    { content: t('File Path'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
    { content: t('Result'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
    { content: t('Results'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
]];

const mapResultsArchive = (extractedFiles) => {
    switch (extractedFiles) {
        case 0:
            return 'Undetected';
        case 15:
            return 'Password Protected Document';
        case 12:
            return 'Encrypted Archive';
        case 20:
            return 'Sensitive Data Found';
        case 1:
            return 'Detected';
        default:
            return 'Unsupported file type';
    }
};

export const usePdfArchiveData = (extractedFiles, results) => {
    return useMemo(() => {

        if (!extractedFiles) {
            return;
        }
        const headRowsArchive = extractedFiles.map((file) => (file.display_name));

        const bodyRowsArchive = Object.entries(extractedFiles, results).map((value, index) => {
            return {
                id: headRowsArchive[index],
                'Result': mapResultsArchive(value[1]?.scan_result_i),
                'Results': value[1].detected_by + '/' + results.scan_results.total_avs
            };
        }).flat(1);
        return { headRowsArchive: headRowsArchive, bodyRowsArchive: bodyRowsArchive };
    }, [extractedFiles]);
};

export const archivePDFData = (pdfDataArchive) => {
    const bodyArchive = pdfDataArchive?.headRowsArchive.map((header) => {
        const headRowEntriesLength = pdfDataArchive?.bodyRowsArchive.filter((bodyRowTemp) => bodyRowTemp.id === header).length;
        const pdfBodyRowsArchive = pdfDataArchive?.bodyRowsArchive.reduce((acc, item) => {
            if (item.id === header) {
                // eslint-disable-next-line no-unused-vars
                const { id, ...itemProcessed } = item;
                acc.push(Object.values(itemProcessed));
            }

            return acc;
        }, []);

        if (pdfBodyRowsArchive.length === headRowEntriesLength) {
            const unshiftObject = {
                rowSpan: headRowEntriesLength,
                content: header,
                styles: {
                    valign: 'middle',
                }
            };

            if (headRowEntriesLength !== 0) {
                pdfBodyRowsArchive[0].unshift(unshiftObject);
            } else {
                pdfBodyRowsArchive.unshift([unshiftObject]);
            }
        }

        return pdfBodyRowsArchive;
    }).flat(1);
    return bodyArchive;
};
