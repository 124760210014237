import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FilterPopup from '../filter-popup/FilterPopup';
import SortButton, { SORT_STATES } from '../sort-button/SortButton';

import './TableHeader.scss';

const TableHeader = (props) => {
    const { useSorting } = props;
    const tableColumns = props.columns;
    const [activeFitlers, setActiveFilters] = useState({});
    const [sort, setSort] = useState({ accessor: null, direction: null });


    const onFilter = (accessor, activeFitlersArr) => {
        const filter = { [accessor]: activeFitlersArr };
        const newActiveFilters = Object.assign({}, activeFitlers, filter);
        props.onFilter && props.onFilter(newActiveFilters);
        setActiveFilters(newActiveFilters);
    };

    const onSort = (accessor, state) => {
        if (sort.accessor === accessor && sort.state === state) {
            state = SORT_STATES.INACTIVE;
        }
        props.onSort && props.onSort({ accessor, state });
        setSort({ accessor, state });
    };

    const getSortDirection = (state) => {
        let sortDirection;
        switch (state) {
            case SORT_STATES.UP:
                sortDirection = SORT_STATES.UP;
                break;
            case SORT_STATES.DOWN:
                sortDirection = SORT_STATES.DOWN;
                break;
            default:
                sortDirection = SORT_STATES.INACTIVE;
        }
        return sortDirection;
    };

    const header = useMemo(() => {
        return tableColumns.map((data) => {
            const cellClassNames = classnames('tableDataCell', data.classes);
            const hasFilter = data.filter && Object.keys(data.filter).length;
            const isSorted = sort.accessor === data.accessor;
            let sortDirection = getSortDirection(sort.state);
            const sortButtonState = isSorted ? sortDirection : SORT_STATES.INACTIVE;
            const SortButtonComponent = (useSorting ?
                <SortButton
                    state={sortButtonState}
                    onSort={onSort.bind(null, data.accessor)} /> : null);
            const FilterComponent = (hasFilter ?
                <FilterPopup
                    onFilter={onFilter.bind(null, data.accessor)}
                    filterData={data.filter} /> : null);
            return (
                <th scope="col" key={data.accessor} className={cellClassNames}>
                    <div className="tableDataCellTitleWrapper">
                        <div className="tableDataCellTitle">
                            {data.Header}
                            {FilterComponent}
                        </div>
                        {SortButtonComponent}
                    </div>
                </th>
            );
        });

    }, [tableColumns, sort]);

    const mobileCellHeader = useMemo(() => {
        if (!header.length) {
            return null;
        }
        const originalHeader = header[0];

        return (
            <th colSpan={header.length} {...originalHeader.props} key={`mobile_header_${originalHeader.key}`}>
                {originalHeader.props.children}
            </th>
        );

    }, [header]);
    return (
        <thead className="theadDark tableHeader">
            <tr className="mobileHeader">
                {mobileCellHeader}
            </tr>
            <tr>
                {header}
            </tr>
        </thead>
    );
};
TableHeader.propTypes = {
    columns: PropTypes.array.isRequired,
    onFilter: PropTypes.func,
    onSort: PropTypes.func,
    useSorting: PropTypes.bool
};

export default TableHeader;
