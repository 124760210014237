import { useEffect, useCallback, useState, useRef } from 'react';

export default (eventsObj) => {
    const { onDrop, onDragEnter, onDragLeave } = eventsObj;

    const dragCount = useRef(0);
    const [isDragging, setDragging] = useState(false);

    const onDragEnterCallback = useCallback(
        (event) => {
            event.preventDefault();
            dragCount.current++;
            if (dragCount.current > 1) {
                return;
            }
            onDragEnter(event);
            setDragging(true);
        },
        [onDragEnter, dragCount, setDragging]
    );

    const onDragLeaveCallback = useCallback(
        (event) => {
            event.preventDefault();

            setTimeout(() => {
                dragCount.current--;
                if (dragCount.current > 0) {
                    return;
                }
                onDragLeave(event);
                setDragging(false);
            }, 100);
        },
        [onDragLeave, dragCount, setDragging]
    );

    const onDropCallback = useCallback(
        (event) => {
            event.preventDefault();
            setDragging(false);
            dragCount.current = 0;
            onDrop(event);
        },
        [onDrop, dragCount, setDragging]
    );

    const onDragoverCallback = useCallback((event) => {
        event.preventDefault();
    }, []);

    useEffect(() => {
        window.addEventListener('drop', onDropCallback, false);
        window.addEventListener('dragover', onDragoverCallback, false);
        window.addEventListener('dragenter', onDragEnterCallback, false);
        window.addEventListener('dragleave', onDragLeaveCallback, false);
        return () => {
            window.removeEventListener('drop', onDropCallback);
            window.removeEventListener('dragover', onDragoverCallback);
            window.removeEventListener('dragenter', onDragEnterCallback);
            window.removeEventListener('dragleave', onDragLeaveCallback);
        };
    }, [
        onDropCallback,
        onDragoverCallback,
        onDragEnterCallback,
        onDragLeaveCallback
    ]);

    return { isDragging };
};
