import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

import './PlainTablePlaceholder.scss';

const getRandomInt = (maxValue) => {
    if (typeof window !== 'undefined' && window.crypto) {
        const randomBuffer = new Uint32Array(1);
        window.crypto.getRandomValues(randomBuffer);
        const randomNumber = randomBuffer[0] / (0xFFFFFFFF + 1);
        return Math.floor(randomNumber * Math.floor(maxValue)) + Math.floor(maxValue / 3);
    }
    return Math.floor(Math.random() * maxValue);

};
const PlainTableColPlaceholder = (props) => {
    const random = getRandomInt(250);
    return <td>
        <ContentLoader
            height={60}
            width={400}
            speed={1}
            primaryColor="#EDEEF3"
            secondaryColor="#f3f3f3"
            {...props}
        >
            <rect x="0" y="20" rx="10" ry="10" width={random} height="30" />
        </ContentLoader>
    </td>;
};

const PlainTablePlaceholder = ({ numberOfColumns, numberOfRows }) => {

    let keyCounter = 0;
    return (
        <table className='plainTablePlaceholder'>
            <tbody>
                {
                    Array(numberOfRows).fill('').map(() => {
                        return <tr key={keyCounter++}>
                            {
                                Array(numberOfColumns).fill('').map(() => {
                                    return <PlainTableColPlaceholder key={keyCounter++} />;
                                })
                            }
                        </tr>;
                    })
                }
            </tbody>
        </table>
    );
};

PlainTablePlaceholder.propTypes = {
    numberOfColumns: PropTypes.number,
    numberOfRows: PropTypes.number
};

PlainTablePlaceholder.defaultProps = {
    numberOfColumns: 3,
    numberOfRows: 20
};

export default PlainTablePlaceholder;
