import { Component } from 'react';
import PropTypes from 'prop-types';
import TextGraphics from './text-graphics/TextGraphics';
import { requestAnimationFrame, cancelAnimationFrame } from 'request-animation-frame-shim';

class TextCarousel extends Component {
    constructor(props) {
        super(props);
        this.tick = this.tick.bind(this);
        this.nextTickID = null;
        this.state = {
            y: 0,
            textList: [],
        };
    }

    tick() {
        if (!this._isMounted) {
            return;
        }

        this.setState((prevState) => {
            let y = prevState.y + 2;
            const wait = (y % 25) === 0;

            this.nextTickID = setTimeout(() => {
                requestAnimationFrame(this.tick);
            }, (wait) ? 3000 : 1000 / 60); // 3 sec wait or ~60 fps draw

            return { y };
        });
    }

    drawTextCarousel() {
        const textList = [];
        let pos = 0;
        // init list with doubled content
        [0, 1].forEach(() => {
            /* eslint-disable no-unused-vars */
            for (const text of this.props.items) {
                textList.push({
                    name: text,
                });

                // 2nd row is always the same as the third
                if (pos === 2) {
                    textList.push({
                        name: text
                    });
                }

                pos += 1;
            }
        });

        let level = 0;
        /* eslint-disable no-unused-vars */
        for (const item of textList) {
            item.y = level * 50;
            level++;
        }
        /* eslint-enable no-unused-vars */
        this.setState({
            y: 0,
            textList: textList
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.items !== prevProps.items) {
            this.setState({
                y: 0,
                textList: []
            });
            this.drawTextCarousel();
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.drawTextCarousel();
        requestAnimationFrame(this.tick);
    }

    componentWillUnmount() {
        const nextTickID = this.nextTickID;
        this._isMounted = false;
        if (nextTickID) {
            clearTimeout(nextTickID);
            cancelAnimationFrame(this.tick);
        }
    }

    render() {
        return (
            <TextGraphics
                height={365}
                textList={this.state.textList}
                width={250}
                y={this.state.y}
            />
        );
    }
}

TextCarousel.propTypes = {
    items: PropTypes.array.isRequired,
};

export default TextCarousel;
