import { useMemo } from 'react';
import { ShortNumber } from '@mdc/services';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const Chart = loadable(() => import('react-apexcharts'));

import './StackedBar.scss';

const StackedBar = ({ categories, series, colors }) => {

    const options = useMemo(() => {
        return {
            chart: {
                type: 'bar',
                height: '100%',
                width: '100%',
                foreColor: '#909090',
                stacked: true,
                toolbar: {
                    show: false
                }
            },
            colors,
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '20%'
                },
            },
            grid: {
                show: false
            },
            stroke: {
                width: 0
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories,
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                }
            },
            yaxis: {
                labels: {
                    show: true,
                    align: 'left',
                    style: {
                        colors: ['#A2B2D2'],
                        fontSize: '14px',
                        fontWeight: 500,
                        fontFamily: 'SimplonNorm'
                    },
                    offsetY: 3,
                }
            },
            tooltip: {
                marker: {
                    show: false
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                fontSize: '14px',
                fontFamily: 'SimplonNorm',
                fontWeight: 400,
                offsetY: 10,
                labels: {
                    colors: ['#A2B2D2'],
                },
                markers: {
                    offsetY: -2
                },
                itemMargin: {
                    horizontal: 10
                },
                onItemClick: {
                    toggleDataSeries: false
                }
            }
        };
    }, [categories, series, colors]);

    if (!series) {
        return <ContentLoader height='100%' width='100%'>
            <rect x='10%' y='22%' rx='4' ry='4' width={`${60 + 30 * Math.random()}%`} height='12'/>
            <rect x='10%' y='44%' rx='4' ry='4' width={`${60 + 30 * Math.random()}%`} height='12'/>
            <rect x='10%' y='66%' rx='4' ry='4' width={`${60 + 30 * Math.random()}%`} height='12'/>
        </ContentLoader>;
    }

    return <div className='stackedBar w-100 d-flex flex-row'>
        <div className='chartBar'>
            <Chart options={options} series={series} type='bar' height={'230'} width={'100%'}/>
        </div>
        <div className='totalColumn d-flex flex-column'>
            <div className='totalValue d-flex flex-column'>
                {categories.map((category, index) => {
                    const total = series.reduce((entire, self) => entire + (self.data[index] || 0), 0);
                    const text = ShortNumber.short(total);
                    let keyCounter = 0;
                    return <p key={keyCounter++} title={total}>{text}</p>;
                })}
            </div>
        </div>
    </div>;
};

StackedBar.propTypes = {
    categories: PropTypes.array,
    series: PropTypes.array,
    colors: PropTypes.array
};

export default StackedBar;


