import { useState, useEffect } from 'react';

let listeners = [];
let state = false;

const setState = (isVisible) => {
    state = isVisible;
    listeners.forEach((listener) => {
        listener(state);
    });
};

const useAnnouncementBannerState = () => {
    const [, newListener] = useState();

    useEffect(() => {
        listeners.push(newListener);

        return () => {
            listeners = listeners.filter((listener) => listener !== newListener);
        };
    }, []);

    return [state, setState];
};

export default useAnnouncementBannerState;
