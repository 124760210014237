import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLocalisedOpswatLink } from '@mdc/services';
import { Row, Col } from 'react-bootstrap';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import './index.scss';

const Copyright = ({ onLink }) => {
    const { t, ready } = useTranslation();

    const [copyrightLinks, setCopyrightLinks] = useState();
    const [copyrightText, setCopyrightText] = useState();

    const currentYear = (new Date()).getFullYear();

    const onClick = (event) => {
        if (!onLink) {
            return;
        }

        event.preventDefault();
        onLink(event.target.href);
    };

    useEffect(() => {
        setCopyrightLinks(
            <>
                <OutboundLink
                    href={getLocalisedOpswatLink('https://www.opswat.com/legal-notices')}
                    onClick={onClick}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('Legal Notices')}
                </OutboundLink>
                <OutboundLink
                    href="https://www.opswat.com/legal/privacy-policy"
                    onClick={onClick}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('Privacy Policy')}
                </OutboundLink>
                <OutboundLink
                    href={getLocalisedOpswatLink('https://www.opswat.com/connect')}
                    onClick={onClick}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('Connect')}
                </OutboundLink>
                <OutboundLink
                    href={getLocalisedOpswatLink('https://www.opswat.com/company/patents')}
                    onClick={onClick}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('OPSWAT Patent Information')}
                </OutboundLink>
            </>
        );
    }, [t]);

    useEffect(() => setCopyrightText(<span dangerouslySetInnerHTML={{ __html: t('OPSWAT, Inc. All rights reserved. OPSWAT&reg;, MetaDefender&reg;, MetaAccess, Trust No File, Trust No Device and the OPSWAT logo are trademarks of OPSWAT, Inc') }}/>), [t]);

    if (!ready) {
        return null;
    }

    return (
        <Row
            className="copyright"
            id="copyright"
        >
            <Col xs={12} className='copyrightLinks'>
                { copyrightLinks }
            </Col>

            <Col
                lg={10}
                xs={12}
            >
                &copy; {`${currentYear} `}
                {copyrightText}
            </Col>

        </Row>
    );
};

Copyright.propTypes = {
    onLink: PropTypes.func,
};

export default Copyright;


