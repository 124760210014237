import { useCallback, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { LanguageDetectorService } from '@mdc/services';
import { useTranslation } from 'react-i18next';
import { i18nConfig } from '@mdc/constants';
import PropTypes from 'prop-types';

import './LanguageSelector.scss';

const AVAILABLE_LANGUAGE = [
    { code: 'en-US', name: 'English' },
    { code: 'ja-JP', name: '日本語' },
];

const LanguageSelector = ({ selectorClassNames }) => {
    const { ready, i18n } = useTranslation();
    const [activeLanguage, setActiveLanguage] = useState(AVAILABLE_LANGUAGE[0]);

    useEffect(() => {
        const currentLang = LanguageDetectorService.lookup(i18nConfig.detection);
        const activeLang = AVAILABLE_LANGUAGE.find((l) => l.code === currentLang);
        setActiveLanguage(activeLang || AVAILABLE_LANGUAGE[0]);
    });

    const changeLanguage = useCallback((langCode) => {
        if (langCode !== activeLanguage.code) {
            (async () => {
                await i18n.changeLanguage(langCode);
            })();
        }
    }, [i18n, activeLanguage]);

    const dropdownItems = useMemo(() => AVAILABLE_LANGUAGE.map((lang) => {
        let activeClasses = classNames({
            activeLanguage: (activeLanguage.code === lang.code),
        });
        return (<Dropdown.Item
            className={activeClasses}
            key={lang.code}
            onClick={() => changeLanguage(lang.code)}
        >
            {lang.name}
        </Dropdown.Item>);
    }), [activeLanguage, changeLanguage]);

    if (!ready) {
        return null;
    }

    return (
        <Dropdown
            className={selectorClassNames}
            size='sm'
            alignRight
        >
            <Dropdown.Toggle id='language-dropdown'>
                {activeLanguage.name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {dropdownItems}
            </Dropdown.Menu>
        </Dropdown>
    );
};

LanguageSelector.defaultProps = {
    selectorClassNames: 'language-selector'
};

LanguageSelector.propTypes = {
    selectorClassNames: PropTypes.any
};

export default LanguageSelector;
