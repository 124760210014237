import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import './ChartBar.scss';


const ChartBar = ({ value }) => {

    const { now, power, color } = value;
    const chartBarClassName = `chartBar ${color} power-${power}`;

    return (
        <div className={chartBarClassName}>
            <ProgressBar now={now} />
        </div>
    );
};

ChartBar.propTypes = {
    value: PropTypes.object.isRequired
};

export default ChartBar;
