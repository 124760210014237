import { createContext } from 'react';
import PropTypes from 'prop-types';

const SandboxContext = createContext(null);

export const SandboxProvider = ({ children, value }) => {
    return <SandboxContext.Provider value={value}>
        {children}
    </SandboxContext.Provider>;
};

SandboxProvider.propTypes = {
    children: PropTypes.node,
    value: PropTypes.object.isRequired
};

export default SandboxContext;
