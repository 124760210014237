import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../common/icon-button/IconButton';
import classNames from 'classnames';
import { dayjs } from '@mdc/services';
import useAnnouncementBannerState from './useAnnouncementBannerState';


import './AnnouncementBanner.scss';

/**
 * When using the banner component the parent should have
 * a `withAnnouncementBanner` class when the component is visible.
 * Example:
 * ```
 *  const [isAnnouncementBannerVisible] = useAnnouncementBannerState();
 *  const layoutClassnames = classnames({
 *      withAnnouncementBanner: isAnnouncementBannerVisible,
 *  });
 *  <Container className={layoutClassnames}>
 *      <AnnouncementBanner {...} />
 *  </Container>
 * }}
 * ```
 *
 * @param {*} { content: 'Jsx component' }
 */
const AnnouncementBanner = ({ content, className, shouldExpireOn }) => {
    const [isClient, setIsClient] = useState(false);
    const [isVisible, setIsVisible] = useAnnouncementBannerState();

    useEffect(() => {
        setIsClient(true);
        const shouldBeDisplayed = shouldExpireOn ? dayjs(shouldExpireOn).isAfter() : true;

        if (shouldBeDisplayed) {
            setIsVisible(true);
        }
    }, []);

    const announcementBannerClassName = classNames('announcementBanner', className);

    if (!isClient || !content || !isVisible) {
        return null;
    }

    const onClose = () => {
        setIsVisible(false);
    };

    return (
        <div className={announcementBannerClassName}>
            <div className="announcementBannerContent">
                {content}
            </div>
            <IconButton iconClass="icon-close" onClick={onClose} />
        </div>
    );
};

export default AnnouncementBanner;

AnnouncementBanner.propTypes = {
    content: PropTypes.any.isRequired,
    className: PropTypes.string,
    shouldExpireOn: PropTypes.any
};
