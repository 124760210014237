import PropTypes from 'prop-types';

const SIZES = ['B', 'KB', 'MB', 'GB', 'TB'];

const fileSizeConvert = (size) => {
    if (typeof (size) === 'number') {
        const digit = parseInt(Math.floor(Math.log(size) / Math.log(1024)));
        if (typeof (digit) === 'number' && typeof (SIZES[digit]) !== 'undefined') {
            return Math.round(size / Math.pow(1024, digit)) + ' ' + SIZES[digit];
        }
        return '-';
    }
    return '-';
};

fileSizeConvert.propTypes = {
    size: PropTypes.number
};

export default fileSizeConvert;
