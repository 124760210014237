import { Handle, Position } from 'react-flow-renderer';
import PropTypes from 'prop-types';

const CustomNodeComponent = ({ data }) => {
    const {
        icon,
        additionalInformation,
        metaData,
        ...reducedData
    } = {
        ...data,
        ...data?.additionalInformation
    };
    const validData = { ...reducedData, metaData };

    return (
        <div
            data-tooltip-id='customNode'
            className='tooltip-data'
            data-item={JSON.stringify(validData)}
        >
            <Handle type='target' position={Position.Left} className='handler' />

            <i className={icon}></i>
            <div className='data'>{additionalInformation?.Program || additionalInformation?.File || additionalInformation?.URI || '-'}</div>
            <div className='label'>{data.actionLabel}</div>

            <Handle type='source' position={Position.Right} className='handler' id={data.action} />
        </div>
    );
};

CustomNodeComponent.propTypes = {
    data: PropTypes.object.isRequired
};

export default CustomNodeComponent;
