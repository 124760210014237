import PropTypes from 'prop-types';

import './UnderConstruction.scss';

const UnderConstruction = ({ wipImg, description, title }) => {
    const underConstructionDom = <>
        <img src={wipImg} alt='Under Construction' title='Under Construction'/>
        <h1 className='title'>{title}</h1>
        <p className='description' dangerouslySetInnerHTML={{ __html: description }}/>
    </>;

    return <section className='underConstruction'>
        {underConstructionDom}
    </section>;
};

UnderConstruction.propTypes = {
    wipImg: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default UnderConstruction;
