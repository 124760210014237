import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import PricingPlans from './PricingPlans';

import './Product.scss';

const Product = ({ className, shortName, currencySymbol, description, disabled, initialPlan, onSelectPlan, onRemovePlan, plans, planType }) => {
    const { t, ready } = useTranslation();

    const [selectedPlan, setSelectedPlan] = useState();

    const productClasses = classNames('product', className);

    const selectPlan = (plan) => {
        if (selectedPlan === plan) {
            setSelectedPlan();
        }
        else {
            setSelectedPlan(plan);
            onSelectPlan(plan);
        }
        onRemovePlan(selectedPlan);
    };

    useEffect(() => {
        selectPlan(initialPlan);
    }, [initialPlan]);

    if (!ready) {
        return null;
    }

    return (
        <Col xs={12} sm={6} md={6} xl={3}>
            <div className={productClasses}>
                <h2 className="productHeader h3Mask">
                    {t(shortName)}
                </h2>
                <div className="productBody">
                    <div
                        className="productDescription"
                        dangerouslySetInnerHTML={{
                            __html: t(description)
                        }}
                    ></div>
                    <PricingPlans
                        currencySymbol={currencySymbol}
                        planName={shortName}
                        plans={plans}
                        onPlanSelected={selectPlan}
                        disabled={disabled}
                        selectedPlan={selectedPlan}
                        planType={planType}
                    />
                </div>
            </div>
        </Col>
    );
};

Product.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    description: PropTypes.any,
    currencySymbol: PropTypes.string,
    plans: PropTypes.array,
    initialPlan: PropTypes.any,
    onSelectPlan: PropTypes.func,
    onRemovePlan: PropTypes.func,
    planType: PropTypes.string,
    shortName: PropTypes.string
};

export default Product;
