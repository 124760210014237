import BackendService from '../backend-service/BackendService';
import { logService } from '@mdc/services';

/**
 * Get the location information based on IP.
 *
 * @returns {*} location information
 */
const getLocation = async () => {
    try {
        return await BackendService.getGeoLocation();
    } catch (error) {
        logService.error(error); // eslint-disable-line no-console
    }
    return {};
};

/**
 * Provides geographic location information
 */
class LocationService {
    constructor() {
        this._locationData = null;
    }

    async _getLocationData() {
        if (!this._locationData) {
            this._locationData = await getLocation();
        }
        return this._locationData;
    }

    async getLocation() {
        try {
            const {
                countryName,
                city
            } = await this._getLocationData();

            return {
                countryName,
                city
            };
        } catch (error) {
            logService.error(error); // eslint-disable-line no-console
        }

        return {};
    }
}

export default new LocationService();
