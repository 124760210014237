import PropTypes from 'prop-types';
import classnames from 'classnames';

import './FileStatus.scss';

const FileStatus = (props) => {
    const value = props.value.toLowerCase();

    const classNames = classnames({
        'fileStatus': true,
        'icon': true,
        [value]: true,
        'icon-ok': value === 'clean',
        'icon-minus': value === 'infected',
        'icon-block': value === 'aborted',
        'icon-attention-alt': value === 'failed'
    });

    return (<i className={classNames}></i>);
};

FileStatus.propTypes = {
    value: PropTypes.string.isRequired
};

export default FileStatus;
