
import { STATUS_PAGE } from '@mdc/constants';
import GenericPubSub from '../generic-pub-sub/GenericPubSub';
import { localStorage, BackendService } from '@mdc/services';

const INCIDENT_ID = 'incidentId';

export const SERVICE_ERRORS = {
    ERROR: 'Error loading statuspage',
};

class StatusPageService extends GenericPubSub {
    constructor() {
        super(SERVICE_ERRORS);
    }

    getIncident = async () => {
        const env = process.env.ENVIRONMENT === 'prod' ? process.env.ENVIRONMENT : 'qa';
        try {
            const results = await BackendService.getStatusPageIncident();
            const lsIncidentID = localStorage.getItem(INCIDENT_ID);

            if (results?.incidents?.length > 0) {
                const components = results.incidents[0]?.components;
                components?.sort((componentA, componentB) => {
                    return new Date(componentA.created_at).getTime() -
                        new Date(componentB.created_at).getTime();
                }).reverse();

                const latestIncident = components[0];
                if (latestIncident.group_id === STATUS_PAGE[env].cloudGroupId
                    && (!lsIncidentID || (lsIncidentID && lsIncidentID !== `${latestIncident.id}-dismissed`))) {
                    localStorage.setItem(INCIDENT_ID, latestIncident.id);
                    return latestIncident.id;
                }
            } else {
                localStorage.removeItem(INCIDENT_ID);
                return 'No incidents';
            }
        } catch (error) {
            this._publish(this.topics.ERROR, error);
        }
    };
}

export default new StatusPageService();
