import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import './DatesDropdown.scss';

const DatesDropdown = ( { queryDate, availableDates, updateDate } ) => {
    const getSelectedDate = queryDate !== undefined && queryDate.toLowerCase() !== 'latest' ? queryDate : 'Latest';
    const [selectedDate, setSelectedDate] = useState(getSelectedDate);

    useEffect(() => {
        setSelectedDate(getSelectedDate);
    }, [queryDate]);

    const updateSelected = (eventKey) => {
        const updatedEventKey = eventKey.includes('outbreak-report/') ? eventKey.replace(/outbreak-report\//, '') : eventKey;
        setSelectedDate(updatedEventKey);
        updateDate(eventKey.toLowerCase().trim());
    };

    const generateDropdownItems = () => {
        let monthsArray = ['Latest', ...availableDates];
        return monthsArray.map((key) => {
            const updatedKey = key.includes('outbreak-report/') ? key.replace(/outbreak-report\//, '') : key;
            return <Dropdown.Item key={updatedKey} eventKey={key} onSelect={updateSelected}>
                { updatedKey }
            </Dropdown.Item>;
        });
    };

    const dates = generateDropdownItems();

    return (
        <div className="datesDropdown">
            <Dropdown>
                <Dropdown.Toggle >
                    <span className="dateText">
                        {selectedDate}
                        <i className = "icon-angle-down"> </i>
                    </span>

                </Dropdown.Toggle>

                <Dropdown.Menu alignRight>
                    { dates }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );

};

DatesDropdown.propTypes = {
    availableDates: PropTypes.array.isRequired,
    updateDate: PropTypes.func.isRequired,
    queryDate: PropTypes.string
};

export default DatesDropdown;
