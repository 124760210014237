import BaseService from '../BaseService';

class DomainService {
    constructor(instance) {
        this.instance = instance;
        this.base = new BaseService(this.instance);
    }

    getResults = (params, headers) => {
        return this.base.get(`/domain/${params.address}`, headers);
    }
}

export default DomainService;
