import ContentLoader from 'react-content-loader';

const GraphicsPlaceholder = () => (
    <ContentLoader
        height={88}
        width={420}
        speed={1}
        primaryColor="#EDEEF3"
        secondaryColor="#f3f3f3"
    >
        <rect x="80" y="21" rx="4" ry="4" width="6" height="47" />
        <rect x="90" y="51" rx="4" ry="4" width="6" height="17" />
        <rect x="100" y="21" rx="4" ry="4" width="6" height="47" />
        <rect x="110" y="41" rx="4" ry="4" width="6" height="27" />
        <rect x="120" y="11" rx="4" ry="4" width="6" height="57" />
        <rect x="130" y="51" rx="4" ry="4" width="6" height="17" />
        <rect x="140" y="41" rx="4" ry="4" width="6" height="27" />
        <rect x="150" y="11" rx="4" ry="4" width="6" height="57" />
        <rect x="160" y="51" rx="4" ry="4" width="6" height="17" />
        <rect x="170" y="31" rx="4" ry="4" width="6" height="37" />
        <rect x="70" y="20" rx="4" ry="4" width="115" height="1" />
        <rect x="70" y="62" rx="4" ry="4" width="10" height="1" />
        <rect x="300" y="21" rx="4" ry="4" width="6" height="47" />
        <rect x="310" y="51" rx="4" ry="4" width="6" height="17" />
        <rect x="320" y="21" rx="4" ry="4" width="6" height="47" />
        <rect x="330" y="41" rx="4" ry="4" width="6" height="27" />
        <rect x="340" y="11" rx="4" ry="4" width="6" height="57" />
        <rect x="350" y="51" rx="4" ry="4" width="6" height="17" />
        <rect x="360" y="41" rx="4" ry="4" width="6" height="27" />
        <rect x="370" y="11" rx="4" ry="4" width="6" height="57" />
        <rect x="380" y="51" rx="4" ry="4" width="6" height="17" />
        <rect x="390" y="31" rx="4" ry="4" width="6" height="37" />
        <rect x="290" y="40" rx="4" ry="4" width="115" height="1" />
        <rect x="290" y="62" rx="4" ry="4" width="10" height="1" />

        <circle cx="10" cy="25" r="6" />
        <rect x="24" y="22" rx="2" ry="2" width="24" height="5" />
        <rect x="24" y="51" rx="2" ry="2" width="38" height="4" />
        <rect x="24" y="61" rx="2" ry="2" width="38" height="4" />

        <circle cx="220" cy="25" r="6" />
        <rect x="234" y="22" rx="2" ry="2" width="24" height="5" />
        <rect x="234" y="51" rx="2" ry="2" width="38" height="4" />
        <rect x="234" y="61" rx="2" ry="2" width="38" height="4" />
    </ContentLoader>
);

export default GraphicsPlaceholder;
