/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { getLocalisedOpswatLink } from '@mdc/services';

import './Plan.scss';

const Plan = ({ title, className, licensingPlan, planButtons }) => {
    const { t, ready } = useTranslation();
    const planClasses = classNames({}, className);
    const spanClasses = classNames({}, 'belowInfo');
    const buttonClasses = classNames({}, 'button text-center');
    const [callToAction, setCallToAction] = useState();
    const [supportLink, setSupportLink] = useState('https://www.opswat.com/support#support-levels');

    useEffect(() => {
        setCallToAction(className === 'details' ? (
            <p className={spanClasses}>
                <span><sup>1 </sup> {t('As a recommendation, for requests which are bigger than 1GB, you can include a signed URL of the file instead of the file itself')}</span>
                <span
                    dangerouslySetInnerHTML={{
                        __html:
                            '<sup>2 </sup>' +
                            t(
                                'Applies to file uploads and bulk requests. <a href=\"https://docs.opswat.com/mdcloud/integrations/throttling\" rel="noopener noreferrer" target="_blank">See documentation</a>'
                            )
                    }}
                ></span>
                <span id="footnote"><sup>3 </sup> {t('Excluding archive file or recursive file processing that involve multiple file analysis even though single file is submitted for analysis. Dynamic analysis is also excluded. p50: median value of all the requests in the given month. p90: 90 percentile of all the requests in the given month')}</span>
                <span><sup>4 </sup>{t('When the rate limit is exceeded, how much can the user go above before the API starts blocking requests')}</span>
                <span><sup>5 </sup> {t('Please note, MetaDefender Cloud is licensed by number of API calls. If you wish to do multiscanning and CDR on every file uploaded that will count as 2 calls per file. Therefore you will need to purchase a daily API call limit twice that of your daily file traffic')}</span>
                <span><sup>6 </sup> {t('Encrypt private resources such as sanitized versions of files using customer provided public keys')}</span>

            </p>
        ) : (
            <div className={buttonClasses}>
                {planButtons[className]}
            </div>
        ));
        setSupportLink(getLocalisedOpswatLink(supportLink));
    }, [className, planButtons, t]);

    const domLicensingPlans = useMemo(() => {
        let key = 0;
        return licensingPlan.map((plan) => {
            if (plan.includes(t('Response time SLA ³')) || plan.length < 1) {
                return <li key={key++} className="slaAvailability">{plan || '-'}</li>;
            }

            if (Array.isArray(plan)) {
                return <li key={key++} className="slaList">
                    <ul>
                        {plan.map((planItem) => {
                            return <li key={key++}>{planItem.text}</li>;
                        })}
                    </ul>
                </li>;
            }

            if (plan.includes(t('Up to, Platinum Support'))) {
                const linkName = plan.split(',');

                return <li key={key++}>{linkName[0]}
                    <a href={supportLink} rel="noopener noreferrer" target="_blank">{linkName[1]}</a>
                </li>;
            }

            if (plan.includes('further increase on demand')) {
                return <li key={key++} className="onDemand">{plan}</li>;
            }

            if (plan === 'Yes') {
                return <li key={key++}>
                    <i className='icon icon-checkmark-symbol' />
                </li>;
            }

            return <li key={key++}>{plan}</li>;
        });
    }, [licensingPlan, supportLink]);


    if (!ready) {
        return null;
    }

    return (
        <div className={planClasses}>
            <div className="planHeader">
                {title ? <p className='h5Mask'>{t(title)}</p> : ''}
            </div>
            <div className="planBody">
                <ul className="licensingPlan">{domLicensingPlans}</ul>
                {callToAction}
            </div>
        </div>
    );
};

Plan.propTypes = {
    title: PropTypes.string,
    licensingPlan: PropTypes.array,
    className: PropTypes.string,
    planButtons: PropTypes.object
};

Plan.defaultProps = {
    title: '',
    licensingPlan: [],
    className: '',
    planButtons: {
        'standard': <a href='/store'>{'Buy now'}</a>,
        'professional': <OutboundLink href={getLocalisedOpswatLink('https://www.opswat.com/contact')} rel="noopener noreferrer" target="_blank">{'Request a quote'}</OutboundLink>,
        'enterprise': <OutboundLink href={getLocalisedOpswatLink('https://www.opswat.com/contact')} rel="noopener noreferrer" target="_blank">{'Request a quote'}</OutboundLink>
    }
};

export default Plan;
