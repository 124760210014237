import ContentLoader from 'react-content-loader';

const TextPlaceholder = () => {
    const random = Math.random() * (1 - 0.7) + 0.7;

    return (
        <ContentLoader
            height={70}
            width={400}
            speed={1}
            primaryColor="#EDEEF3"
            secondaryColor="#f3f3f3"
        >
            <rect x="0" y="0" rx="4" ry="4" width={400 * random} height="6" />
            <rect x="0" y="12" rx="4" ry="4" width={300 * random} height="6" />

            <rect x="0" y="37" rx="4" ry="4" width={350 * random} height="6" />
            <rect x="0" y="49" rx="4" ry="4" width={280 * random} height="6" />
            <rect x="0" y="61" rx="4" ry="4" width={170 * random} height="6" />
        </ContentLoader>
    );
};

export default TextPlaceholder;
