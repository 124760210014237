import IconButton from '../../icon-button/IconButton';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './SortButton.scss';

export const SORT_STATES = {
    INACTIVE: 'innactive',
    DOWN: 'down',
    UP: 'up'
};

const SortButton = ({ state, onSort }) => {
    const iconsClassNames = classnames({
        inactive: state === SORT_STATES.INACTIVE
    }, 'sortButtonWrapper');

    const onSortClick = (currentState) => {
        onSort(currentState);
    };
    const SortUpButton = state === SORT_STATES.INACTIVE || state === SORT_STATES.UP ? <IconButton iconClass="icon-up-dir" onClick={onSortClick.bind(null, SORT_STATES.UP)}/> : null;
    const SortDownButton = state === SORT_STATES.INACTIVE || state === SORT_STATES.DOWN ? <IconButton iconClass="icon-down-dir" onClick={onSortClick.bind(null, SORT_STATES.DOWN)}/> : null;

    return (
        <div className={iconsClassNames}>
            {SortUpButton}
            {SortDownButton}
        </div>
    );
};

SortButton.defaults = {
    state: SORT_STATES.INACTIVE
};

SortButton.propTypes = {
    state: PropTypes.string.isRequired,
    onSort: PropTypes.func.isRequired
};

export default SortButton;
