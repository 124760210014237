import { useMemo } from 'react';
import TechCard from './tech-card/TechCard';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import { getLocalisedOpswatLink } from '@mdc/services';

import './SecurityTechnologies.scss';

const SecurityTechnologies = () => {
    const { t, ready } = useTranslation();

    const securityTechnologies = useMemo(() => {
        return <Col>
            <Row className="justify-content-center">
                <Col>
                    <TechCard
                        href={getLocalisedOpswatLink('https://www.opswat.com/technologies/proactive-data-loss-prevention')}
                        iconClass="icon-proactive-dlp"
                        text={t('Proactive DLP')}
                    />
                </Col>
                <Col>
                    <TechCard
                        href={getLocalisedOpswatLink('https://www.opswat.com/technologies/data-sanitization')}
                        iconClass="icon-proactive-dlp"
                        text={t('Deep CDR')}
                    />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col>
                    <TechCard
                        href={getLocalisedOpswatLink('https://www.opswat.com/technologies/vulnerability-assessment')}
                        iconClass="icon-vulnerabilities"
                        smallText="U.S. 9749349 B1"
                        text={t('Vulnerabilities')}
                    />
                </Col>
                <Col>
                    <TechCard
                        href='https://docs.opswat.com/filescan'
                        iconClass="icon-sandbox"
                        text={t('Sandbox')}
                    />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col>
                    <TechCard
                        href={getLocalisedOpswatLink('https://www.opswat.com/technologies/multiscanning')}
                        iconClass="icon-multiscan"
                        text={t('Multiscanning')}
                    />
                </Col>
                <Col>
                    <TechCard
                        href='https://docs.opswat.com/mdcloud/metadefender-cloud-api-v4/ref#tag-threat-intelligence-feed'
                        iconClass="icon-threat-intelligence"
                        text={t('Threat Intelligence')}
                    />
                </Col>
            </Row>
        </Col>;
    }, [t]);

    if (!ready) {
        return null;
    }

    return (
        <Container className="security-technologies">
            <p className='h4Mask'>
                {t('Security Technologies')}
            </p>
            {securityTechnologies}
        </Container>
    );
};

export default SecurityTechnologies;
