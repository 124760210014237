/* eslint-disable no-useless-escape */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './FileScanState.scss';

const FileScanState = ({ isFileTypeSupported, scanOption, invalidFileImg, availableFileImg, sanitizedResult }) => {
    const { t, ready } = useTranslation();

    const imgDOM = useMemo(() => {
        return isFileTypeSupported && sanitizedResult?.result !== 'Error' ? availableFileImg : invalidFileImg;
    }, [isFileTypeSupported, sanitizedResult]);

    const textDOM = useMemo(() => {
        if (isFileTypeSupported && sanitizedResult?.result === 'Error') {
            return <>
                <p className='noSupport'>{t('Filetype not supported')}</p>
            </>;
        }

        if (isFileTypeSupported) {
            return <>
                <p className='infoText'>{t('Information available')}</p>
                <p className='detailInfo' dangerouslySetInnerHTML={{ __html: t('To access this information, please process this file again with the <span class=\"highlight\">\"{{scanOption}}\"</span> option checked', { scanOption: t(scanOption) }) }}/>
            </>;
        }

        if (!isFileTypeSupported && sanitizedResult?.reason ===  'Password Protected Document') {
            return <>
                <p className='infoText'>{t('Filetype not supported. Document needs a password to unlock')}</p>
            </>;
        }

        return <>
            <p className='noSupport'>{t('Filetype not supported')}</p>
        </>;
    }, [isFileTypeSupported, scanOption, sanitizedResult, t]);

    if (!ready) {
        return null;
    }

    return <div className='fileScanState'>
        <div className='iconContainer'>
            {imgDOM}
        </div>
        <div className='textContainer'>
            {textDOM}
        </div>
    </div>;
};

FileScanState.propTypes = {
    isFileTypeSupported: PropTypes.bool.isRequired,
    scanOption: (props, propName) => {
        const data = props[propName];
        const isSupported = props['isFileTypeSupported'];

        if (isSupported && !data) {
            return new Error(`Proptype ${propName} should be defined when 'isFileTypeSupported' is true`);
        }

        if (!data || typeof data !== 'string') {
            return new Error(`The '${propName}' is required to be of type String`);
        }
    },
    invalidFileImg: PropTypes.object,
    availableFileImg: PropTypes.object,
    sanitizedResult: PropTypes.object
};

export default FileScanState;
