import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { dayjs } from '@mdc/services';

const MARGIN = { top: 30, right: 50, bottom: 0, left: 5 };
const HEIGHT = 40;
const MARGIN_DAYS = dayjs?.duration(5, 'days').asMilliseconds();

const HistorySelection = ({ scanHistory, selectUpdate, width }) => {
    const selectRef = useRef(null);

    const [dateRange, setDateRange] = useState();
    const [lastCompWidth, setLastCompWidth] = useState(0);

    const compWidth = useMemo(() => width - MARGIN.left - MARGIN.right, [width]);
    const compHeight = HEIGHT - MARGIN.top - MARGIN.bottom;

    const x = useMemo(() => {
        if (scanHistory?.length >= 2) {
            return d3?.scaleTime()
                .domain([scanHistory[0].date, scanHistory[scanHistory.length - 1].date])
                .range([0, compWidth]);
        }

        if (scanHistory?.length === 1) {
            const endDay = new Date(scanHistory[0].date.getTime() + MARGIN_DAYS);
            return d3?.scaleTime()
                .domain([new Date(scanHistory[0].date.getTime() - MARGIN_DAYS), endDay.getTime() < new Date().getTime() ? endDay : new Date()])
                .range([0, compWidth]);
        }

        return undefined;
    }, [scanHistory, compWidth]);

    const brushModify = useMemo(() => () => {
        if (selectRef.current) {
            const d3brush = d3.select(selectRef.current);
            const selectionX = parseFloat(d3brush.select('.selection').attr('x')) + 12;
            let selectionWidth = parseFloat(d3brush.select('.selection').attr('width')) - 12 * 2 + 1;
            selectionWidth = selectionWidth >= 0 ? selectionWidth : 0;
            d3brush
                .selectAll('.selection--inner')
                .data([0])
                .join(
                    (enter) => enter.append('rect')
                        .classed('selection--inner', true)
                        .attr('stroke', '#D3D9E6')
                        .attr('fill', '#ffffff')
                        .attr('stroke-width', 2)
                        .attr('rx', 2)
                        .attr('width', selectionWidth)
                        .attr('height', 6)
                        .attr('shape-rendering', 'geometricPrecision')
                        .attr('x', selectionX)
                        .attr('y', compHeight / 2 - 3)
                    ,
                    (update) => update
                        .attr('x', selectionX)
                        .attr('width', selectionWidth),
                    (exit) => exit.remove()
                );

            d3brush.select('.selection')
                .attr('fill-opacity', 0)
                .attr('stroke', null)
                .attr('height', 6)
                .attr('y', compHeight / 2 - 3)
                .raise();

            d3brush.selectAll('.handle')
                .attr('stroke', '#D3D9E6')
                .attr('stroke-width', 2)
                .attr('rx', 2)
                .attr('width', 8)
                .attr('height', 24)
                .attr('y', compHeight / 2 - 12)
                .raise();

            d3brush.select('.handle--e')
                .attr('fill', '#FFFFFF');

            const startCursorX = parseFloat(d3brush.select('.handle--w').attr('x')) + 1;
            const startCursorY = parseFloat(d3brush.select('.handle--w').attr('y')) + 1;
            const startCursorH = parseFloat(d3brush.select('.handle--w').attr('height')) - 2;
            d3brush
                .selectAll('.handle--w--inner')
                .data([0])
                .join(
                    (enter) => enter.append('rect')
                        .classed('handle--w--inner', true)
                        .attr('stroke', '#FFFFFF')
                        .attr('stroke-width', 3)
                        .attr('fill', '#D3D9E6')
                        .attr('rx', 2)
                        .attr('width', 6)
                        .attr('height', startCursorH)
                        .attr('x', startCursorX)
                        .attr('y', startCursorY)
                    ,
                    (update) => update
                        .attr('x', startCursorX),
                    (exit) => exit.remove()
                );
        }
    }, [compHeight, selectRef.current]);

    const selection = useMemo(
        () => d3.brushX()
            .extent([[0, 0], [compWidth, compHeight]])
            .on('brush', () => {
                if (d3.event.selection && x) {
                    const range = d3.event.selection.map(x.invert, x);
                    selectUpdate(range);
                    setDateRange(range);
                }
                brushModify();
            })
            .on('end', () => {
                if (!d3.event.selection && selectRef.current) {
                    d3.select(selectRef.current).call(selection.move, [0, compWidth]);
                }
                brushModify();
            })
            .on('start', brushModify)
        , [compWidth, compHeight, x, selectUpdate, brushModify]);

    useEffect(() => {
        if (selectRef.current) {
            const d3brush = d3.select(selectRef.current);
            d3brush.call(selection)
                .call(selection.move, [0, compWidth]);
        }
    }, [scanHistory, selection, selectRef.current]);

    useEffect(() => {
        if (compWidth !== lastCompWidth && x) {
            if (dateRange) {
                d3.select(selectRef.current).call(selection.move, [x(dateRange[0]), x(dateRange[1])]);
            }
        }
        setLastCompWidth(compWidth);
    }, [compWidth]);

    return (
        <div className='historySelection'>
            <svg
                width={width}
                height={HEIGHT + MARGIN.top + MARGIN.bottom}
            >
                <g transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
                    <path stroke='#D3D9E6' d={`M0,${compHeight / 2}H${compWidth},${compHeight / 2}`} strokeWidth='2'></path>
                </g>
                <g transform={`translate(${MARGIN.left}, ${MARGIN.top})`} ref={selectRef}>
                </g>
            </svg>
        </div>
    );
};

HistorySelection.defaultProps = {
    width: 700,
};

HistorySelection.propTypes = {
    scanHistory: PropTypes.array.isRequired,
    selectUpdate: PropTypes.func.isRequired,
    width: PropTypes.number,
};

export default HistorySelection;
