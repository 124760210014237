import { dayjs } from '@mdc/services';

const TimeFromNow = ({ value }) => {
    if (!value) {
        return null;
    }
    const timeFromNow = dayjs(value).fromNow();
    return (
        <span className="timeFromNow">
            {timeFromNow}
        </span>
    );
};

TimeFromNow.propTypes = {
    value: (props, propName, componentName) => {
        // eslint-disable-next-line security/detect-object-injection
        const timestamp = props[propName];
        const errorMessage = `Invalid timestamp sent to ${componentName}. Expected a valid timestamp, got ${timestamp}`;
        if (!timestamp) {
            return new Error(errorMessage);
        }

        const date = dayjs(timestamp);
        if (!date.isValid()) {
            return new Error(errorMessage);
        }
    }
};

export default TimeFromNow;
