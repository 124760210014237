/* eslint-disable no-useless-escape */
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_TYPES, STRIPE } from '@mdc/constants';
import classNames from 'classnames';
import Switch from 'react-switch';
import FaqV2 from '../common/faq-v2/FaqV2';
import { logService, dayjs, TranslationFix, GtmService } from '@mdc/services';
import CardPlaceholder from '../common/content-placeholder/CardPlaceholder';

import Product from './Product';
import Cart from './Cart';

import './Store.scss';

const Store = ({
    currency,
    currencySymbol,
    notify,
    products,
    redirect,
    StoreService,
    paymentPending,
    paymentCompleted,
    hasSubscriptions,
    subscriptions,
    expirationDate,
    user,
    defaultPlanType,
    emailValidator,
    countryValidator,
    isOrganization,
    faq
}) => {
    const { t, ready, i18n } = useTranslation();

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isPaymentPending, setIsPaymentPending] = useState(paymentPending);
    const [isPaymentCompleted, setIsPaymentCompleted] = useState(paymentCompleted);
    const [isProcessing, setIsProcessing] = useState(false);

    const [selectedPlanType, setSelectedPlanType] = useState(defaultPlanType);
    const [checkedSwitch, setCheckedSwitch] = useState(() => defaultPlanType !== STRIPE.PLAN_TYPE.MONTHLY);

    const monthlyPlanTypeClass = classNames({
        planTypeChecked: !checkedSwitch
    }, 'planType');

    const annualPlanTypeClass = classNames({
        planTypeChecked: checkedSwitch
    }, 'planType');

    useEffect(() => {
        setIsPaymentPending(paymentPending);
    }, [paymentPending]);

    useEffect(() => {
        setIsPaymentCompleted(paymentCompleted);
    }, [paymentCompleted]);

    useEffect(() => {
        if (defaultPlanType === STRIPE.PLAN_TYPE.MONTHLY) {
            setCheckedSwitch(false);
        } else {
            setCheckedSwitch(true);
        }
        setSelectedPlanType(defaultPlanType);
    }, [defaultPlanType]);

    const handlePlanTypeChange = (checked) => {
        if (selectedPlanType === STRIPE.PLAN_TYPE.MONTHLY) {
            setSelectedPlanType(STRIPE.PLAN_TYPE.ANNUAL);
        } else {
            setSelectedPlanType(STRIPE.PLAN_TYPE.MONTHLY);
        }

        setCheckedSwitch(checked);
    };

    const onSelectProduct = (newProduct) => {
        setSelectedProducts((chosenProducts) => {
            if (chosenProducts?.every((product) => product?.id !== newProduct?.id)) {
                return [...chosenProducts, newProduct];
            }
            return chosenProducts;
        });
    };

    const onRemoveProduct = (oldProduct) => {
        if (!oldProduct) {
            return;
        }
        setSelectedProducts((chosenProducts) => {
            return chosenProducts.filter(
                (product) => product && product.id !== oldProduct.id
            );
        });
    };

    const redirectToCheckout = async (planType) => {
        if (hasSubscriptions) {
            notify({
                message: `${t('You already have an active license associated with this account! Please go to your <a href="/account">account page</a> to view the new limits')}`,
                type: NOTIFICATION_TYPES.CRITICAL
            });
            return;
        }

        if (!selectedProducts.length) {
            return;
        }

        const planIdKey = `${planType}Id`;
        const selectedPlans = selectedProducts.map(
            (product) => product[planIdKey]
        );

        const selectedPlansPrices = selectedProducts.map(
            (product) => product['annualId'] === product[planIdKey] ? product['annualPrice'] : product['monthlyPrice']
        );

        setIsProcessing(true);
        if (window) {
            const selectedProductsProcessed = selectedProducts?.reduce((items, product) => {
                const productProcessed = {
                    // name should be consistent with the subscription's tier (`Plan Name` `Plan Limit`)
                    name: `${product.id.split('_')[0]} ${product.id.split('_')[1]}`,
                    price: product['monthlyPrice'],
                    quantity: 1,
                    variant: product.limit,
                };

                return items.concat(productProcessed);
            }, []);
            const currencyCodeProcessed = currency?.toString()?.toUpperCase();

            const dataLayerObj = {
                'event': 'checkout',
                'ecommerce': {
                    'currencyCode': currencyCodeProcessed,
                    'checkout': {
                        'actionField': {
                            'step': 1,
                            'option': planType
                        },
                        'products': selectedProductsProcessed
                    }
                }
            };

            GtmService.initialize({
                event: 'checkout',
                ecommerce: dataLayerObj.ecommerce
            });

            logService.log('Sending to GTM Checkout: ' + JSON.stringify(dataLayerObj));
        }

        try {
            await StoreService.createSubscription(
                selectedPlans,
                currency,
                selectedPlansPrices
            );
            setIsPaymentPending(true);
        } catch (error) {
            logService.error(error); // eslint-disable-line no-console
            const errorDetails = error.message && t(error.message);
            notify({
                message: `${t('Failed to create Checkout Session!')} ${errorDetails}`,
                type: NOTIFICATION_TYPES.CRITICAL
            });
        }
        setIsProcessing(false);
    };

    const subscriptionDOM = useMemo(() => {
        if (hasSubscriptions) {
            dayjs.locale(i18n?.language?.split('-')[0] || 'en');
            const subscriptionExpirationDate = dayjs(expirationDate).format('MMMM YYYY');
            return <>
                <Badge className='hasSubscriptionParagraph' variant='warning'>
                    {t('You already have an active subscription that will expire on {{subscriptionExpirationDate}}', { subscriptionExpirationDate })}
                </Badge>
                <TranslationFix
                    message={t('If you want to change your MetaDefender Cloud subscription type, please <a href=\"https://www.opswat.com/contact\" rel=\"noopener noreferrer\" target=\"_blank\">contact our support team</a>')}
                    type='p'
                    className='hasSubscriptionParagraph'
                />
            </>;
        }
    }, [hasSubscriptions, expirationDate, t, i18n]);

    const paymentPendingDOM = (isPaymentPending && !hasSubscriptions) && (
        <Row>
            <Col>
                <Alert variant="info">
                    {t('Your apikey will be updated after the payment process is completed')}
                    <TranslationFix
                        message={t('Please go to your <a href=\"/account\">account page</a> to view the new limits')}
                        type='span'
                    />
                </Alert>
            </Col>
        </Row>
    );

    const invalidEmailSectionDOM = !emailValidator && user && (
        <Row>
            <Col>
                <Alert variant="warning">
                    <TranslationFix
                        message={t('Your email domain is not eligible for buying a license. Please consult our <a href="https://www.opswat.com/legal/free-tools-terms-of-use">terms and conditions</a> or <a href=\"https://www.opswat.com/contact\">contact us</a>')}
                        type='div'
                    />
                </Alert>
            </Col>
        </Row>
    );

    const invalidCountryDOM = !countryValidator && (
        <Row>
            <Col>
                <Alert variant="warning">
                    <TranslationFix
                        message={t('Your country is not eligible for buying a license. Please consult our <a href=\"https://www.opswat.com/legal/free-tools-terms-of-use\">terms and conditions</a> or <a href=\"https://www.opswat.com/contact\">contact us</a>')}
                        type='div'
                    />
                </Alert>
            </Col>
        </Row>
    );

    const organizationDOM = isOrganization && (
        <Row>
            <Col>
                <Badge variant="warning" className="hasSubscriptionParagraph">
                    <TranslationFix
                        message={t('You cannot purchase an individual license while you are part of an organization')}
                        type='div'
                    />
                </Badge>
            </Col>
        </Row>
    );

    const disable = useMemo(() => !!subscriptions || hasSubscriptions || isProcessing || isOrganization || isPaymentCompleted,
        [subscriptions, hasSubscriptions, isProcessing, isPaymentCompleted, isOrganization]);

    const productsDOM = useMemo(() => {
        if (!products) {
            return <>
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
                <CardPlaceholder />
            </>;
        }

        return products?.map((product) => {
            const initialPlan = subscriptions
                ? product.plans.filter((plan) => {
                    return (
                        subscriptions.some((sub) => sub.annualId === plan.annualId) ||
                        subscriptions.some((sub) => sub.monthlyId === plan.monthlyId)
                    );
                })[0]
                : undefined;

            return (
                <Product
                    key={product?.id}
                    {...product}
                    disabled={disable}
                    currencySymbol={currencySymbol}
                    onSelectPlan={onSelectProduct}
                    onRemovePlan={onRemoveProduct}
                    initialPlan={initialPlan}
                    planType={selectedPlanType}
                />
            );
        });
    }, [products, selectedPlanType, subscriptions, currencySymbol, disable]);

    const customLimitsMessage = <TranslationFix
        message={t('For custom limits, please <a href=\"https://www.opswat.com/contact\" rel=\"noopener noreferrer\" target=\"_blank\">Contact Sales</a>. For more information about licensing limits, please check our <a href=\"/licensing\" rel=\"noopener noreferrer\" target=\"_blank\">Licensing</a> page')}
        type='span'
    />;

    const annualPlanTypeHandler = () => {
        if (subscriptions || isPaymentPending) {
            return;
        }

        setCheckedSwitch(true);
        setSelectedPlanType(STRIPE.PLAN_TYPE.ANNUAL);
    };

    const monthlyPlanHandler = () => {
        if (subscriptions || isPaymentPending) {
            return;
        }

        setCheckedSwitch(false);
        setSelectedPlanType(STRIPE.PLAN_TYPE.MONTHLY);
    };

    const planTypeSwitchDom = <section className="storeProducts">
        <Row className="justify-content-center planTypeSwitch" >
            <label className={monthlyPlanTypeClass} onClick={monthlyPlanHandler} id="plan_type_switch">
                {t('monthly')}
                <input name='monthly' type="checkbox" hidden />
            </label>
            <Switch
                onChange={handlePlanTypeChange}
                checked={checkedSwitch}
                uncheckedIcon={false}
                checkedIcon={false}
                offColor='#2672FB'
                onColor='#2672FB'
                height={20}
                width={35}
                id="planTypeSwitch"
                aria-checked="true"
                aria-labelledby="plan_type_switch"
                disabled={disable}
            />
            <label className={annualPlanTypeClass} onClick={annualPlanTypeHandler}>
                {t('annual')}
                <input name='annual' type="checkbox" hidden />
            </label>

            <div>
                <Badge variant='warning' className='annualPlanSave'>{t('2 months free')}</Badge>
            </div>
        </Row>
    </section>;

    if (!ready) {
        return null;
    }


    return (
        <>
            <section className="storeHeader">
                <Row>
                    <Col xs={8}>
                        <h1>{t('Online store')}</h1>
                    </Col>
                    <Col xs={4}>
                        <span className="apiSymbol">
                            <i className="icon-cart"></i>
                        </span>
                    </Col>
                    <Col xs={12} className="description">
                        <div>
                            {t('We offer 4 different MetaDefender Cloud APIs that each enable different security flows')}
                        </div>
                    </Col>
                    <Col>
                        {subscriptionDOM}
                    </Col>
                </Row>
                {paymentPendingDOM}
                {invalidEmailSectionDOM}
                {invalidCountryDOM}
                {organizationDOM}
            </section>

            {products && planTypeSwitchDom}


            <section>
                <Row>
                    {productsDOM}
                    {products && <Cart
                        user={user}
                        currency={currency}
                        currencySymbol={currencySymbol}
                        onBuyCb={redirectToCheckout}
                        redirect={redirect}
                        selectedProducts={selectedProducts}
                        planType={selectedPlanType}
                        emailValidator={emailValidator}
                        countryValidator={countryValidator}
                        isOrganization={isOrganization}
                        isProcessing={isProcessing}
                        paymentCompleted={isPaymentCompleted}
                        hasSubscriptions={hasSubscriptions}
                    />}
                </Row>
                <Row>
                    <Col>
                        {customLimitsMessage}
                    </Col>
                </Row>
            </section>
            <FaqV2 faqTitle={t('Questions about our plans and pricing?')} faqSubTitle={t('Here are the most frequently asked questions')} faqs={faq} />
        </>
    );
};

Store.propTypes = {
    StoreService: PropTypes.any,
    countryValidator: PropTypes.bool,
    currency: PropTypes.string,
    currencySymbol: PropTypes.string,
    defaultPlanType: PropTypes.any,
    emailValidator: PropTypes.bool,
    isOrganization: PropTypes.bool,
    faq: PropTypes.array,
    notify: PropTypes.func,
    paymentPending: PropTypes.bool.isRequired,
    paymentCompleted: PropTypes.bool.isRequired,
    hasSubscriptions: PropTypes.bool.isRequired,
    subscriptions: PropTypes.any,
    expirationDate: PropTypes.number,
    products: PropTypes.any,
    redirect: PropTypes.func,
    user: PropTypes.any
};

Store.defaultProps = {
    defaultPlanType: STRIPE.PLAN_TYPE.MONTHLY
};


export default Store;
