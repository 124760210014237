export default {
    results: {
        href: '/results',
        file: '/file',
        ip: '/ip',
        domain: '/domain',
        url: '/url',
        cif: '/cif',
        oldHref: '/results#!'
    },
    vulnerabilities: '/vulnerabilities'
};
