import { createContext, Children, cloneElement, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import LoadingComponent from './loading/Loading';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

export const LoadingSpinnerSharedContext = createContext();


const loadingSpinnerClasses = classNames({
    'svgSpinnerWrapper': true,
});

const LoadingSpinnerProvider = ({ children, onError, useTheme, size }) => {

    const [visible, setVisible] = useState(false);
    const [promisesQueue, setPromisesQueue] = useState([]);
    const [targetElem, setTargetElem] = useState(null);
    let promisesQueueLength = promisesQueue.length;

    useEffect(() => {
        setTargetElem(document.body);
    }, []);

    const displayLoadingScreen = (display) => {
        setVisible(display);
    };

    const sendError = (message) => {
        if (onError) {
            onError(message);
        }
        return message;
    };

    const addPromise = (newPromise) => {
        setPromisesQueue((promisesStream) => {
            promisesQueueLength = promisesStream.length + 1;
            return [...promisesStream, newPromise.catch(sendError)];
        });
        setVisible(true);
    };

    useEffect(() => {
        if (promisesQueue.length > 0) {
            Promise.all(promisesQueue).then(() => {
                if (promisesQueue.length === promisesQueueLength) {
                    setVisible(false);
                    setPromisesQueue([]);
                }
            });
        }
    }, [promisesQueue]);

    const activeLoadingSpinner = visible === true;

    const LoadingSpinner = activeLoadingSpinner ? <LoadingComponent useTheme={useTheme} size={size}/>  : null;

    const LoadingSpinnerWrapper = (
        <CSSTransition
            in={visible}
            timeout={500}
        >
            <div className={loadingSpinnerClasses}>
                {LoadingSpinner}
            </div>
        </CSSTransition>
    );

    const LoadingSpinnerWrapperComponent = targetElem ? (createPortal(
        (LoadingSpinnerWrapper)
        , targetElem)) : LoadingSpinnerWrapper;

    return (
        <LoadingSpinnerSharedContext.Provider
            value={{
                showLoadingSpinner: displayLoadingScreen,
                waitFor: addPromise
            }}>
            {LoadingSpinnerWrapperComponent}
            {children}
        </LoadingSpinnerSharedContext.Provider>
    );
};

export default LoadingSpinnerProvider;

export const LoadingSpinnerConsumer = (props) => {
    const extraProps = {};
    /* eslint-disable no-unused-vars */
    for (let key in props) {
        if (key !== 'children') {
            extraProps[key] = props[key];
        }
    }
    /* eslint-enable no-unused-vars */

    return (
        <LoadingSpinnerSharedContext.Consumer>
            {({ showLoadingSpinner, waitFor }) => {
                const children = props.children;
                return Children.map(children, (child) => {
                    return cloneElement(child, Object.assign(extraProps, { showLoadingSpinner, waitFor }));
                });
            }}
        </LoadingSpinnerSharedContext.Consumer>
    );
};

LoadingSpinnerProvider.propTypes = {
    children: PropTypes.node,
    onError: PropTypes.func,
    useTheme: PropTypes.string,
    size: PropTypes.string
};

LoadingSpinnerConsumer.propTypes = {
    children: PropTypes.any
};
