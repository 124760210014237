import { hasWindow } from '../helpers/helpers';

/**
 * Extracts URL query params in an Object.
 *
 * @param {*} window The browser global window object
 */
const QueryParams = () => {
    return hasWindow && window.location && window.location.search && window.location.search.slice(1).split('&').reduce((queryParams, keyVal) => {
        const [key, val] = keyVal.split('=');
        if (val !== '' || !isNaN(val) || Number(val) >= 0) {
            queryParams[key] = val;
        }
        else {
            queryParams[key] = '';
        }
        return queryParams;
    }, {}) || {};
};

/**
 * Create query string from object by encoding and concatenating the key, value pair
 * @param {object} paramsObject Key value pair will be transformed to key=value
 * @returns {string}
 */
export const objectToURLParamsStr = (paramsObject) => {
    return Object.entries(paramsObject).map(([key, val]) => {
        const encodedKey = encodeURIComponent(key);
        const encodedVal = encodeURIComponent(val);
        return `${encodedKey}=${encodedVal}`;
    }).join('&');
};

export default QueryParams;
