import PropTypes from 'prop-types';
import HubspotForm from 'react-hubspot-form';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../loading-spinner/loading/Loading';

import './HubspotModal.scss';

const HubspotModal = ({ portalId, formId, show, title, handleClose, handleSubmit }) => {
    const [t, ready] = useTranslation();

    if (!ready) {
        return;
    }

    const LoadingComponent = (
        <div className="loaderWrapper">
            <LoadingSpinner/>
        </div>
    );

    return <Modal show={show} onHide={handleClose} animation={false} centered={true}>
        <Modal.Header closeButton>
            <Modal.Title>{t(title)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="modalHubspotWrapper">
                <HubspotForm
                    portalId={portalId}
                    formId={formId}
                    onSubmit={handleSubmit}
                    onClose = {handleClose}
                    loading={LoadingComponent}
                    submitButtonClass='btn modalHubspotButton'
                    css=''
                    cssClass='hubspotFormWrapper'
                />
            </div>
        </Modal.Body>
    </Modal>;
};

export default HubspotModal;

HubspotModal.defaultProps = {
    iconClass: '',
    href: '',
    onClick: null
};

HubspotModal.propTypes = {
    portalId: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
    show: PropTypes.bool,
    title: PropTypes.string,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func
};
