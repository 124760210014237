import { createRef, Component } from 'react';
import PropTypes from 'prop-types';

class TextGraphics extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = createRef();
        this.paint = this.paint.bind(this);
        this.state = {
            textList: [],
            context: null,
        };
    }

    componentDidMount() {
        const context = this.canvasRef.current.getContext('2d');
        context.font = 'normal normal 400 40px SimplonNorm';

        const gradientAlpha = context.createLinearGradient(0, 0, 0, this.props.height);
        gradientAlpha.addColorStop(0, 'rgba(255, 255, 255, 0)');
        gradientAlpha.addColorStop(0.03, 'rgba(255, 255, 255, 0.5)');
        gradientAlpha.addColorStop(0.12, 'rgba(255, 255, 255, 0.5)');
        gradientAlpha.addColorStop(0.15, 'rgba(255, 255, 255, 0)');
        gradientAlpha.addColorStop(0.28, 'rgba(255, 255, 255, 0)');
        gradientAlpha.addColorStop(0.305, 'rgba(255, 255, 255, 0.7)');
        gradientAlpha.addColorStop(0.37, 'rgba(255, 255, 255, 0.7)');
        gradientAlpha.addColorStop(0.47, 'rgba(255, 255, 255, 0.3)');
        gradientAlpha.addColorStop(1, 'rgba(255, 255, 255, 0)');

        context.fillStyle = gradientAlpha;

        this.setState({ context });
    }

    shouldComponentUpdate(nextProps) {
        return this.props.y !== nextProps.y || this.props.textList !== nextProps.textList;
    }

    componentDidUpdate(prevProps) {
        if ((this.state.textList.length < 1 && this.props.textList.length > 0) || (this.props.textList !== prevProps.textList)) {
            this.setState({
                textList: [...this.props.textList],
            });
        }
        this.paint();
    }

    paint() {
        const { textList, context } = this.state;

        if (!context || textList.length < 1) {
            return;
        }

        // 2nd element name always eq to the 3rd one
        if ((textList.length > 0) && (this.props.y % 50) === 0) {
            textList[2] = Object.assign({}, textList[3]);
            textList[2].y = textList[2].y - 50;
        }

        // clear drawings
        context.clearRect(0, 0, this.props.width, this.props.height);

        // remove the faded top element and push back to the end
        if (textList[0].y <= 0) {
            const removedItem = textList.shift();
            removedItem.y = textList[textList.length - 1].y + 50;
            textList.push(removedItem);
        }

        // move and paint
        /* eslint-disable no-unused-vars */
        for (const item of textList) {
            item.y = item.y - 2;
            this.paintText(item, context);
        }
        /* eslint-enable no-unused-vars */
    }

    paintText(item) {
        const { context } = this.state;

        // draw only visible texts
        if (item.y <= (this.props.height + 50) && item.y > -50) {
            context.font = 'normal normal 400 40px SimplonNorm';
            context.fillText(item.name, 0, item.y - 9);
        }
    }

    render() {
        return (<canvas
            height={this.props.height}
            ref={this.canvasRef}
            width={this.props.width}
        />);
    }
}

TextGraphics.propTypes = {
    height: PropTypes.number,
    textList: PropTypes.array.isRequired,
    width: PropTypes.number,
    y: PropTypes.number,
};

export default TextGraphics;
