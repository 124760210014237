import BaseService from '../BaseService';

class StoreService {
    constructor(instance) {
        this.instance = instance;
        this.base = new BaseService(this.instance);
    }

    getSubscription = (headers) => {
        return this.base.get('/store/subscription/', headers);
    }

    getStoreData = (headers) => {
        return this.base.get('/store/products/', headers);
    }
}

export default StoreService;
