import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import classNames from 'classnames';

import './Alert.scss';

const ALERT_TYPES = ['primary', 'info', 'warning', 'success', 'danger'];

const Alert = ({ message, type }) => {
    const alertType = ALERT_TYPES.includes(type) ? type.toLowerCase() : ALERT_TYPES[0].toLowerCase();
    const alertClassNames = classNames({}, `${alertType}Alert alertBox`);

    const messageDOM = useMemo(() => {
        if (typeof message === 'string') {
            return <span dangerouslySetInnerHTML={{ __html: message }}/>;
        }

        return message;
    }, [message]);

    return (
        <BootstrapAlert className={alertClassNames} variant={alertType}>
            {messageDOM}
        </BootstrapAlert>
    );
};

Alert.propTypes = {
    message: PropTypes.any.isRequired,
    type: PropTypes.string
};

Alert.defaultProps = {
    message: '',
    type: 'primary'
};

export default Alert;
