export default [
    'accdb',
    'csv',
    'dbf',
    'dbx',
    'doc',
    'docx',
    'eml',
    'htm',
    'html',
    'ics',
    'key',
    'mbx',
    'mdb',
    'mif',
    'msg',
    'numbers',
    'odf',
    'odg',
    'odp',
    'ods',
    'odt',
    'one',
    'ost',
    'otg',
    'otp',
    'ots',
    'ott',
    'pages',
    'pdf',
    'ppt',
    'pptx',
    'pst',
    'rtf',
    'stc',
    'sti',
    'stm',
    'stw',
    'sxc',
    'sxd',
    'sxg',
    'sxi',
    'sxw',
    'tiff',
    'txt',
    'vdx',
    'wks',
    'wpd',
    'wpf',
    'ws',
    'xls',
    'xlsx',
    'xml'
];
