export default [
    'exe',
    'apk',
    'asf',
    'bat',
    'cpl',
    'dll',
    'doc',
    'docm',
    'docx',
    'dot',
    'dotm',
    'dotx',
    'elf',
    'eml',
    'hta',
    'html',
    'hwp',
    'java',
    'jscript',
    'jse',
    'json',
    'lnk',
    'mbox',
    'ole',
    'pdf',
    'pe',
    'pot',
    'potm',
    'potx',
    'powershell',
    'ppam',
    'ppsx',
    'ppt',
    'pptm',
    'pptx',
    'pub',
    'rfc822',
    'rtf',
    'sct',
    'svg',
    'vbscript',
    'wsf',
    'xls',
    'xlsm',
    'xlsx',
    'xltm',
    'xltx',
    '7z',
    'ace',
    'bzip2',
    'cab',
    'gtar',
    'gzip',
    'lzip',
    'iso',
    'rar',
    'tar',
    'vhd',
    'zip'
];
