/* eslint-disable security/detect-object-injection */
import { logService } from '@mdc/services';

/**
 * @name: GenericPubSub
 * @description: Publish/Subscribe component used to handle events for services
 * @param {Object}  topicsObj   Constant defining available topics
 * @example:
 *  can be extended: class MyService extends GenericPubSub {...}
 *  can be initialized: this.events = new GenericPubSub();
 */
class GenericPubSub {
    constructor(topicsObj) {
        this.cache = {};
        this.topics = topicsObj;
        this.queue = [];
    }

    /**
     * Used to publish the data object to the selected topic
     * @param   {string}    topic   Name used to select the topic on which to publish
     * @param   {Object}    data    Object that is passed to all the subscribed handlers
     */
    _publish(topic, data) {
        const topicHandlers = this.cache[topic];

        if (!Array.isArray(topicHandlers)) {
            // eslint-disable-next-line no-console
            logService.warn(`Topic "${topic}" not found. The current subscription topics are`, this.cache);
            this.queue.push({ topic, data });
            return;
        }

        topicHandlers.forEach((handler) => {
            handler(data);
        });
    }

    _checkQueue = (topic) => {
        this.queue = this.queue.filter((obj) => {
            if (obj.topic === topic) {
                this._publish(obj.topic, obj.data);
                return false;
            }
            return true;
        });
    }

    /**
     * Subscribe to topic
     * @param   {string}    topic   Topic name
     * @param   {function}   handler Callback function that is called when data is published on the topic
     */
    on(topic, handler) {
        if (!this.cache[topic]){
            this.cache[topic] = [];
            this.cache[topic].push(handler);
            this._checkQueue(topic);
            return;
        }
        this.cache[topic].push(handler);
    }

    /**
     * Unsubscribe from topic
     * @param {string} topic    Topic name to unsubscribe from
     * @param {function} handler Callback reference passed during subscription with `.on`
     */
    off(topic, handler) {
        const topicCache = this.cache && this.cache[topic] || [];
        const handlerIndex = topicCache.indexOf(handler);

        if (handlerIndex > -1) {
            topicCache.splice(handlerIndex, 1);
        }
    }
}

export default GenericPubSub;
